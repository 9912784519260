import axios from "axios";
import config from "../../config";

const urlcommss = `${config?.baseURLHR}`;

export const get_commissions = async () => {
    const url = `${urlcommss}/od/commissions`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const post_commissions = async (data) => {
    const url = `${urlcommss}/od/commissions`;
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const put_commissions = async (id, data) => {
    const url = `${urlcommss}/od/commissions/${id}`;
    try {
      const response = await axios.put(url, data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  export const delete_commissions = async (id) => {
    const url = `${urlcommss}/od/commissions/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };