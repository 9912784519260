//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
//COMPONENTES
import {get_products_tax} from "../../../services/inventory/inventory"
//ESTILOS
//IMAGENES/ICONOS
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../../main_components/Table/SubTableContainer";


const TAX = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    try{
      var rf_token= await refresh_token();
      if(rf_token===true){
        setTimeout(async () => {
          try {
            const dt= await get_products_tax(sessionStorage.getItem("id_product_tax"));
            console.log(dt);
            setData(dt.data);
            setOpen(false);
        } catch (err) {
            setData([]);
            setMenssage("");
        }
        }, 1000); 
      }else{}
    }catch(err){
      console.log(err);
    } 
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
     
      {
        Header: "Impuesto",
        accessor: "tax"
      },
      {
        Header: "Código",
        accessor: "code",
      },
       
      {
        Header: "Configuración",
        accessor: (d) => {
          return d.configured === true ? "Si" : "NO";
        },
      },
     
    ],
    []
  );

  return (
    <div className="">
      <div>
        
      </div>

      <div>
        <h1>CONFIGURACIÓN DE IMPUESTOS</h1>
      </div>
      <br></br>

      <div className="TableTaxes">
        <div className="">
          <SubTableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            
          />
        </div>
      </div>
    </div>
  );
};

export default TAX;
