import { Component } from "react";
import TableSectors from "../../../componentSociosNegocios/componentSectors/TableSectors";
export class SetSectors extends Component {
  render() {
    return (
      <div className="tc">
        <TableSectors />
      </div>
    );
  }
}

export default SetSectors;
