import { 
  useEffect, 
  useRef, 
  useState 
} from 'react';
import {
  Avatar,
  Button,
  FormControl,
  ListItemText,  
  TextField,
  Typography,  
} from '@material-ui/core';
import { 
  CallMade, 
  CallReceived, 
  ChatBubble, 
  Close, 
  Edit, 
  ModeComment, 
  Reply, 
  Send
} from '@material-ui/icons';
import * as h from './helpers'
import {CustomDialog, useDialog} from "react-st-modal";

import './comments.css';
import { 
  createComment, 
  getComments, 
  getimgticket, 
  updateComment,
  updateSupport, 
} from '../../services/supports/supports';
import { 
  Box, 
  Divider, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemButton, 
  Skeleton,
  Stack
} from '@mui/material';

import { Item } from 'semantic-ui-react';
import { 
  KeyboardDoubleArrowDown, 
  KeyboardDoubleArrowUp, 
} from '@mui/icons-material';
import { ConfirmUpdateTicket } from './ConfirmTicket';
import { isArray, isNumber } from 'lodash';
import { DetallesAcordion } from '../consultCases/Acordion';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { TELEGRAM } from '../telegram/telegram';


const SupportTicketModal = ({
  images,
  alertas,
  close_date,
  company_id,
  company_rfc,
  date,
  description,
  extra1,
  extra2,
  extra3,
  handleLoad,
  mail_support,
  module,
  folio,
  severity,
  status = "",
  subject,
  submodule,
  ticket,
  times_reopened,
  type,
  user_email,
  user_name = "",
  user_rate = 0,
  user_rate_comment = "",
  user_roles = ""}) => {
  const dialog = useDialog();
  const [showResponses, setShowResponses] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(status);  
  const commentsEndRef = useRef(null);
  const commentRef = useRef(null);
  const [comentario, setComentario] = useState('');
  const [datacomment, setDatacomment] = useState({});
  const [dataupdate, setDataupdate] = useState({});
  const [isresponding, setIsResponding] = useState(false);
  const [isupdating, setIsupdating] = useState(false);
  const [comments, setComments] = useState([]);
  const [showdetails, setShowdetails] = useState(false);;
  const [commentresponse, setcommentresponse] = useState(-1);
  const [currentresponse, setCurrentresponse ] = useState([]);
  const [isloading, setIsloading ] = useState(true);
  const current_user = localStorage.getItem("user_name") === null ?  "" : localStorage.getItem("user_name")
  const [vecesreabierto, setvecesabierto] = useState(times_reopened)
  const letter = user_name?.toString().charAt(0);
  let response = <>{h.getName(currentresponse[0]?.user_name, currentresponse[0]?.support_staff)}:  
  {currentresponse[0] === undefined ? "" : currentresponse[0].comment}</>;
  
  var permisosUsuario = sessionStorage.getItem("roles_user");
  var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
  var permisos = permisoUsuarioReplace?.split(",");
  var permisosuport = permisos?.filter((x) => x === "ROLE_SUPPORT");

 

  const handleShow = () =>{
    setShowdetails(!showdetails);
  }


  
  const create_comment = async (id = "", body ) => {
    try {
      let data = await createComment(id, body);
        if(data?.status === 200 || data?.status === 201){
          await TELEGRAM(folio, body, subject, "", "coment");
          alertas("Comentario agregado", true);
        }
        else {
          alertas("No pudimos crear tu comentario, por favor inicia sesión nuevamente", false);
        }
    } catch (err) {
      console.log("Ocurrió un error al crear")
    }   
  };

  
  const handleprocess=async()=>{
    var data = {
      status: "PROCESS"
    }
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await updateSupport(ticket,data);
            alertas("Actualizado el estado del ticket", true);
            await TELEGRAM(folio, "", "", "",  "process");
            handleLoad();
            dialog.close();
            console.log(d);
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }


  const get_comments = async (id = "") => {
    
     setComments([]);
     setIsloading(true)
       try {          
          let data = await getComments(id);
          setComments(data.data);          
        } catch (err) {
        } finally{
          setIsloading(false)
        }  
  };


  const update_comment = async (id = "", body ) => {
    try {
      let data = await updateComment(id, body);
        if(data?.status === 200 || data?.status === 201){
          alertas("Comentario Actualizado", true);
        }
        else {
          alertas("No pudimos actualizar tu comentario, por favor inicia sesión nuevamente", false);
        }
    } catch (err) {
      console.log("Ocurrió un error al crear")
    }   
  };

  const handleCommentSubmit = async () => {
    setIsloading(true)   
    if (comentario.trim() !== '') {  
      
      if(!isupdating) {         
            try{         
              const comment_created = await create_comment(ticket, datacomment);   
              const comments = await get_comments(ticket); 
            }
            catch(err){ } finally { }    
      }
       else {
            try{ 
              console.log("Data para actualizar",dataupdate);        
              const comment_updated = await update_comment(ticket, dataupdate);          
              
              const comments = await get_comments(ticket); 
              console.log("Comentarios obtenidos", comments)
            }
            catch(err){  } finally {}             
       }
      }
    setIsloading(false)    
    setcommentresponse(-1)
    setComentario('') 
    setIsupdating(false)
    setIsResponding(false)
  };
  
  const[dataimg, setdataimg]=useState([]);

  const detailsimgticket = async()=>{

    const promises = images?.map(async (item) => {
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          return new Promise((resolve) => {
            setTimeout(async () => {
              try {
                const d = await getimgticket(ticket, item);
                resolve(d?.data?.file);
              } catch (err) {
                resolve(null);
              }
            }, 1000);
          });
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
        return null;
      }});
      const results = await Promise.all(promises);
      setdataimg(results.filter((result) => result !== null));
  }
  

const handleEditComment = ( param = 0, text = "" ) => {
  setcommentresponse( prevComment => prevComment === param ? -1 : param ) 
  setIsupdating( true )
  setComentario(prevState => prevState === text ? "" : text )
}


  const handleResponseComment = ( param = 0 ) => {    
    setcommentresponse( prevComment => prevComment === param ? -1 : param )     
    setIsupdating( false )  
    setIsResponding(commentresponse !== param ? true : false )
  }  


  const handleShowResponsesClick = (id) => {
    if (showResponses === id) {
        setShowResponses(null);
    } else {
        setShowResponses(id);
    }
};

const scrollToBottom = () => {
  commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
}

const handleStatusChange = async( ) => {
      try{
        const confirm = await CustomDialog(             
          <ConfirmUpdateTicket
            alertas={alertas}
            currentStatus={status}
            ticket={ticket} 
            user_rate={user_rate}
            user_rate_comment={user_rate_comment}
          />);
            
            if(confirm){
              const this_ticket = confirm?.data?.filter((valor) => valor.ticket === ticket)
              
              setTicketStatus(this_ticket[0]?.status);
              if(this_ticket[0]?.status === 'CLOSE'){
                dialog.close(confirm?.data)
              }
              if(isNumber(this_ticket[0]?.times_reopened)){
                setvecesabierto(this_ticket[0]?.times_reopened)
              }
              
             
            }
        }
      catch(error){
        console.log(error)
      }
      finally{

      }
}



useEffect(() => {
  if (commentresponse !== -1) {
    const flattenedComments = comments.flatMap((comment) => [comment, ...comment.responses]);
    const filteredComments = flattenedComments.filter((comment) => comment.id_comment === commentresponse);
   
    setCurrentresponse(filteredComments);
    commentRef.current?.focus();
  } else {
    setCurrentresponse([]);
  }
  
}, [commentresponse, comments])


  useEffect(() => {
    const commentData = {
      comment: comentario.trim !== "" ? comentario.trim() : null,      
      edited: commentresponse !== -1,
    };

    setDataupdate({...commentData, date: currentresponse[0]?.date , edited: true})
    setDatacomment({...commentData, reply: commentresponse === -1 ? null : commentresponse,});
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comentario, commentresponse]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await get_comments(ticket);
    scrollToBottom();
    if(images===undefined){
      console.log("No hay imagenes");
    }else{
      detailsimgticket();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const formatTime = (param) => {
    const dateObj = new Date(param);
    const formattedDate = 
      dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2) + " <span style='font-weight: bold;'>a las</span> " + ('0' + dateObj.getHours()).slice(-2) + ':' + ('0' + dateObj.getMinutes()).slice(-2) + " <span style='font-weight: bold;'>horas</span>";
    return formattedDate;
  }
 
  return (
    <div className="support-ticket-modal">  

      <Item className='icon-details'>
        <>
         { 
          !showdetails 
          ? <span onClick={ handleShow } className='details-coment-tour' id="details-coment-tour"> <CallMade fontSize='medium' /> Ver detalles  </span> 
          : <span onClick={ handleShow }><CallReceived fontSize='medium' />Ocultar detalles </span>
        }
        </>
        <span style={{display:  vecesreabierto > 0 ? "block" : "none"}}> <strong>Veces reabierto: </strong>{vecesreabierto}</span>
       {
        permisosuport.length===0?<></>:
        ticketStatus === "OPEN"?
        <Button 
          style={{
              backgroundColor: "#D1D1D1", 
              display: showdetails && times_reopened < 3 ? "block" : "none"
            }}
           onClick={() => handleprocess()}
          > 
          {"EN PROCESO"} 
        </Button>:<></>
       }
        <Button 
          className='button-close-tour'
          id='button-close-tour'
          style={{
              backgroundColor: "#D1D1D1", 
              display: showdetails && times_reopened < 3 ? "block" : "none"
            }}
          onClick={() => {
            handleStatusChange(status === "OPEN" || status=== "PROCESS" ? "CLOSE" : "OPEN")}}
          > 
          {ticketStatus === "OPEN" || ticketStatus=== "PROCESS"  ? "CERRAR CASO" : "REABRIR CASO" } 
        </Button>
      </Item>
            
      <div style={{ display: showdetails ? "flex" : "none", marginBottom: "5px"}} className="ticket-details" id='ticket-details'>
        <div className='ticket-items'>
          <div className='detail-left'>
            <strong>Asunto: &nbsp; </strong> {subject}
          </div>
       
          <div className='detail-right'>
                <strong> Estado: &nbsp; </strong>  
                {
                  ticketStatus === "OPEN"  ? 
                  "Abierto" : 
                  ticketStatus === "PROCESS" ?
                  "En Revisión" :
                  "Cerrado"
                }
            </div>
            </div>

        <div className='ticket-items'>

          <div className='detail-left'>
          <strong>Fecha: &nbsp; </strong> <span dangerouslySetInnerHTML={{ __html: formatTime(date) }}/>
          </div> 
          <div className='detail-right'>
            <strong> Severidad:&nbsp; </strong> {severity}
          </div>

        </div>

        <div className='ticket-items'>
          <div className='detail-left'>
              <strong>Creado por: &nbsp; </strong> {user_name}
          </div>
          <div className='detail-right'>
          <strong>Tipo:&nbsp; </strong> {type}
          </div>                
        </div>  

         <div className='ticket-items'>
            <div className='detail-left'>
              <strong>M&oacute;dulo: &nbsp; </strong> {module}
            </div>
            <div className='detail-right'>
                <strong>Subm&oacute;dulo: &nbsp; </strong> {submodule}
            </div>         
          </div>
      
        <div className='ticket-items'>
          <div className='detail-left'>
            <strong>RFC: &nbsp;</strong> {company_rfc}
          </div>
          <div className='detail-right'>
              <strong>Contacto:&nbsp; </strong> { user_email}
          </div>          
        </div>      
        <div className='ticket-items'>
            <DetallesAcordion title='Descripción' content={description} tipo={"text"}/>
        </div> 
        <div className='ticket-items'>
            <DetallesAcordion title='Imagenes' content={dataimg} tipo={"img"}/>
        </div> 

          <span className={`ticket-items-rate ${ticketStatus === 'CLOSE' ? '' : 'hide'}`}
                 style={{fontStyle: "italic", opacity: 0.5}}> 
                 Gracias por sus comentarios 
          </span>
      </div>



      <Divider />  
      <div 
          style={{  backgroundColor: comments.length > 0 ? "white" : "#f2f2f2", 
            opacity: ticketStatus === "CLOSE" ? 0.3 : 1.0
          }} 
          className="comments-section">

        <Box sx={{opacity:0.2}} style={{  display: comments.length > 0 ? "none" : "block", }}> 
              <div className='icon-wrapper'> 
                <ChatBubble style={h.message1} className='icon-1'/> 
                <ModeComment style={h.message2} className='icon-2'/> 
              </div>
              <Typography style={{...h.messagetext, textAlign:"center"}}> A&uacute;n no hay comentarios en este ticket  </Typography>
              
        </Box>
                
         {!isloading ? comments.map(( value, index) => {
                const initial = value.user_name.charAt(0).toUpperCase();
                const uniqueKey = `comment${value?.id_comment}`
             return (
             <div key={uniqueKey} >       
               <Divider style={{display: index !== 0 ? "block" : "none", margin: "30px -10px 0 -10px"}}/>                  
                
                <ListItem 
                    style={{opacity: times_reopened > 0
                      &&  close_date > value?.date ? 0.5 : 1.0}}
                    className='comment-individual' 
                    alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar style={{  background: h.getColorFromUserName(value?.user_name, h.colors, value?.support_staff), color: "white"}}> { h.getInitial( value?.support_staff, value?.user_name)   } </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                  className='comment-content'
                  style={{
                    backgroundColor: letter !== initial ? "#F0F0F0" : "#F8F8FF",
                    boxShadow: 
                            commentresponse === value.id_comment 
                            ? "2px 2px 25px 10px rgba(0, 0, 0, 0.5)" 
                            : "0 2px 4px 2px rgba(0, 0, 0, 0.5)" 
                  }}
                    primary={ 
                      <div  className='comment-details'>
                      <div  style={{ fontWeight: "bold"}}>
                        { h.getName(value?.user_name, value?.support_staff)}
                      </div>

                      <p className='editado' style={{display: value?.edited ? "" : "none",}}>Editado</p>
                        
                        <span style={h.stringDate} dangerouslySetInnerHTML={{ __html: formatTime(value.date) }}/> 
                      </div>
                  }
                    secondary={
                      <div >
                        <Typography
                          style={{paddingLeft: "10px"}}
                          component="span"
                          variant="body2"
                        >
                          { value.comment }
                        </Typography>
                        <Divider style={{marginTop: "10px"}}/>
                        <div className='reply'                        
                            style={h.replyContainer}>
                            <span 
                                onClick={()=> handleResponseComment( value.id_comment )}
                                style={{
                                  cursor: 'pointer',
                                  fontSize: "16px",                               
                                }}>
                                <Reply fontSize="small" /> 
                                  responder
                                </span> 

                            <span
                                onClick={()=> handleEditComment( value?.id_comment, value?.comment )}
                                style={{
                                  cursor: 'pointer',
                                  fontSize: "16px",
                                  color: "#525252", 
                                  display: value.user_name === current_user ? "block" : "none"                                
                                }} >
                                <Edit fontSize="small" /> 
                                  editar comentario
                                </span> 
                        </div>                   
                      </div>
                    }
                  />
            
                </ListItem>
               
                <ListItemButton key={value.id_comment}  onClick={() => handleShowResponsesClick(value.id_comment)} style={{display: value.responses.length > 0 ? "block" : "none"}} className='show-comments'>
                  { value.id_comment !== showResponses 
                  ?  <> <KeyboardDoubleArrowDown /> <span style={{textDecoration: "underline"}} >  {` Mostrar respuestas (${value.responses.length})`}</span> </>
                : <> <KeyboardDoubleArrowUp />  <span style={{textDecoration: "underline"}} >  Ocultar respuestas </span> </> }
                </ListItemButton>
              
                <List className='reply' disablePadding>                       
                  {  
                  
                    showResponses === value.id_comment && value.responses.map(( reply, iterador ) =>
                  
                    value.responses.length > 0 && (
                    <ListItem
                      key={iterador}
                      alignItems="flex-start"
                      disableGutters
                      className="comment-response"
                    >
                      <ListItemAvatar style={h.avatarStyle}>
                         <Avatar 
                              style={{ background: h.getColorFromUserName( reply.user_name, h.colors, reply?.support_staff), 
                                color: "white"}}>
                                   { reply?.support_staff ? "S" :  reply?.user_name?.charAt(0).toUpperCase()  } </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className='response-content'                       
                        primary={
                          <div className='comment-details'>
                            <div style={{ fontWeight: "bold",}}>
                              { h.getName( reply?.user_name, reply?.support_staff ) }
                            </div>
                            <p className='editado' style={{display: reply?.edited ? "" : "none",}}>Editado</p>

                          <div
                             style={h.stringDate} 
                             dangerouslySetInnerHTML={{ __html: formatTime(reply.date) }}/> 
                          </div>
                          
                        }
                        secondary={
                          <div>
                            <Typography component="span" variant="body2">
                              {reply.comment} 
                            </Typography> 

                            <Divider style={{marginTop: "10px"}}/>

                            <div className='reply' style={h.replyContainer}>
                                <span onClick={()=> handleResponseComment( value.id_comment )} style={h.styleActions} >
                                    <Reply fontSize="small" /> 
                                      responder
                                </span> 

                              <span 
                              onClick={()=> handleEditComment( reply?.id_comment, reply?.comment )}
                                style={{...h.styleActions, display: reply?.user_name === current_user ? "block" : "none"}} >
                                <Edit fontSize="small" /> 
                                  editar comentario
                                </span>                            
                          </div>            
                       </div>
                        }
                      />
                    </ListItem>

                  ))
                }
                </List>

              </div>
            )}) 
            :
           <Stack style={{ position: "absolute", width: "100%", backgroundColor: "#EEEEEE"}}>
              <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
              <Skeleton variant="circular" width={45} height={45} />
              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="text" sx={{ fontSize: '0.875rem' }} />
              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
         </Stack>
            }
            <div ref={commentsEndRef} />
      </div>

    

      <div className="modal-buttons">
        <FormControl style={{marginRight: "10px"}} fullWidth>
          <ListItemText
           className={`response-container ${ !isresponding ? 'hide' : '' }`} 
            primary={
              <div className='response-actions'> 
                <Close onClick={() => { 
                  setIsResponding(false)
                  setcommentresponse(-1)}}  style={{cursor: 'pointer'}} fontSize='small'/>
              </div>
            }
            secondary={ 
                <span className='response-to'> Respondiendo a  {response} </span>  
            }
            /> 
          <TextField
            label="Agrega un comentario"
            variant="outlined"
            size="medium"
            value={comentario}
            fullWidth
            inputRef={commentRef}
            disabled={ticketStatus === 'OPEN' || ticketStatus === "PROCESS" ? isloading ? true : false : true  }
            className="input-comment input-coment-tour"
            id='input-coment-tour'
            onChange={(e) => setComentario(e.target.value)}
            minRows={1}
            maxRows={5}
            multiline
            inputProps={{ maxLength: 500 }}
            InputProps={{                      
              style: {backgroundColor: "white", border: "none", margin: "5px 5px 10px", width: "100%", height:"auto"},            
            }}
          />
   
        </FormControl>
        <Button
          disabled={ticketStatus === 'OPEN' || ticketStatus === "PROCESS" ? isloading ? true : false : true  }
          variant="contained"
          color="primary"
          startIcon={<Send/>}
          className="send-button"
          onClick={ handleCommentSubmit }
        >
          Enviar
        </Button>
       
      </div>      
    </div>
   
  );
};

export default SupportTicketModal;
