//Paqueterias
import React, { useState} from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDialog} from "react-st-modal";
//Componentes
import { catchErrorModul } from "../../../../../main_components/catchErr/catchError";
import { refresh_token } from "../../../../../main_components/tokens/tokenrefresh";
import boleanDate from "../../../../../dataComponets/true-false.json";
import { put_sale_cashclosing } from "../../../../../services/cashclosing/cashclosing";
//Estilos
import "../../../cashclosing.css";


const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const PUT_CASHCLOSING = ({downloadable,special_folio_number,no_invoice,never_invoice,paper,id_sale}) => {
  const dialog = useDialog();
  const [ddownloadable, setdownloadable] = useState(downloadable);
  const [dspecial_folio_number, setspecial_folio_number] =useState(special_folio_number);
  const [dno_invoice, setno_invoice] = useState(no_invoice);
  const [dnever_invoice, setnever_invoice] = useState(never_invoice);
  const [dpaper, setpaper] = useState(paper);

  const handleDownloadable = (event) => {
    setdownloadable(event?.target?.value === "true" ? true : false);
    if (event?.target?.value === true) {
      setnever_invoice(false);
      setno_invoice(false);
    }
  };
  const handlePeper = (event) => {setpaper(event?.target?.value);};
  const handleSpecial_fol = (event) => {setspecial_folio_number(event?.target?.value);};
  const handleNo_inv = (event) => {
    setno_invoice(event?.target?.value === "true" ? true : false);
    if (event?.target?.value === "true") {
      setdownloadable(false);
      setnever_invoice(false);
    }
  };
  const handleNever_inv = (event) => {
    setnever_invoice(event?.target?.value === "true" ? true : false);
    if (event?.target?.value === "true") {
      setdownloadable(false);
      setno_invoice(false);
    }
  };

  const handleSubmit =async () => {
    var data = {
      special_folio_number: dspecial_folio_number,
      no_invoice: dno_invoice,
      never_invoice: dnever_invoice,
      downloadable: ddownloadable,
      paper: dpaper,
    };
    try {await refresh_token();} catch (err) {}
    try {
      await put_sale_cashclosing(id_sale,data);
      dialog.close(true);
    } catch (err) {
      catchErrorModul("Corte de Caja => Actualizar Venta",JSON.stringify(data),JSON.stringify( err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
    }
  };

  return (
    <div>
      <div></div>
      <div>
        <div className="generarCutboxConfig">
          <div className="expen-5n">
            <ValidationTextField
              label="Folio Especial"
              variant="outlined"
              placeholder="Descargable"
              sx={{ m: 2, width: "100%" }}
              value={dspecial_folio_number}
              InputLabelProps={{ shrink: true }}
              onChange={handleSpecial_fol}
            />
          </div>

          <div className="expen-6">
            <ValidationTextField
              label="Paper"
              variant="outlined"
              placeholder="Paper"
              sx={{ m: 2, width: "100%" }}
              value={dpaper}
              InputLabelProps={{ shrink: true }}
              onChange={handlePeper}
            />
          </div>
          <div className="expen-7">
            <TextField
              select
              child
              label="No facturar"
              value={dno_invoice}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleNo_inv}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {boleanDate?.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="expen-8">
            <TextField
              select
              child
              label="Nunca facturar"
              value={dnever_invoice}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleNever_inv}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {boleanDate?.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className="expen-9">
            {/* <TextField
                select
                child
                label="Downloadable"
                value={ddownloadable}
                sx={{ m: 1.5, width: "100%" }}
                onChange={handleDownloadable}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {boleanDate?.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField> */}
          </div>

          <div className="expen-10">
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "100%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              ACTUALIZAR
            </Button>
          </div>

          <div className="expen-11">
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "100%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close(false);
              }}
            >
              cancelar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PUT_CASHCLOSING;
