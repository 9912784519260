import axios from "axios";
import config from "../../config";

const urlreport = `${config?.baseURLREPORT}`;


export const get_dashboard1_bonus = async (fi, ff, selectRutas, productBonus, poductNote) => {
    const url = `${urlreport}/prod/dashboard1/bonus`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: fi,
          datef: ff,
          route: selectRutas,
          filtro:productBonus,
          note:poductNote,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const get_dashboard1_bonus2 = async (id_detail, datei, datef, route, filtro, note) => {
    const url = `${urlreport}/prod/dashboard1/bonus/${id_detail}`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: datei,
          datef: datef,
          route: route,
          filtro: filtro,
          note: note,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
 
  export const get_dashboard1_bonus3 = async (id_detail, id_id_detail, datei, datef, route, filtro, note) => {
    const url = `${urlreport}/prod/dashboard1/bonus/${id_detail}/${id_id_detail}`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: datei,
          datef: datef,
          route: route,
          filtro: filtro,
          note: note,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
    
  export const get_dashboard1 = async (fi, ff, selectRutas) => {
    const url = `${urlreport}/prod/dashboard1`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: fi,
          datef: ff,
          route: selectRutas,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const no_sales_clasificacion = async (clasificacion, datei, datef, route) => {
    const url = `${urlreport}/prod/no-sales/${clasificacion}`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: datei,
          datef: datef,
          route: route,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const no_sales = async (fi, ff, selectRutas) => {
    const url = `${urlreport}/prod/no-sales`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: fi,
          datef: ff,
          route: selectRutas,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const no_sales_details = async (clasificacion, id_detail, idTicket, datei, datef, route) => {
    const url = `${urlreport}/prod/no-sales/${clasificacion}/${id_detail}/${idTicket}`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: datei,
          datef: datef,
          route: route,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const no_sales_clasification_frezz = async (clasificacion, id_detail) => {
    const url = `${urlreport}/prod/no-sales/${clasificacion}/${id_detail}/freezers`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const no_sales_clasification_details = async (clasificacion, id_detail, datei, datef, route) => {
    const url = `${urlreport}/prod/no-sales/${clasificacion}/${id_detail}`;
    try {
      const response = await axios.get(url, {
        params:{
            datei: datei,
            datef: datef,
            route: route,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const dashboard1_products = async (fi, ff, selectRutas) => {
    const url = `${urlreport}/prod/dashboard1/products`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: fi,
          datef: ff,
          route: selectRutas,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  
  export const dashboard1_products_details = async (id_detail, datei, datef, route) => {
    const url = `${urlreport}/prod/dashboard1/products/${id_detail}`;
    try {
      const response = await axios.get(url, {
        params:{
          datei: datei,
          datef: datef,
          route: route,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

//sales
 
export const dashboard1 = async (clasificacion, datei, datef, route) => {
  const url = `${urlreport}/prod/dashboard1/${clasificacion}`;
  try {
    const response = await axios.get(url, {
      params:{
        datei: datei,
        datef: datef,
        route: route
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const dashboard1_clasificacion_ticket = async (clasificacion, id_detail, idTicket, datei, datef, route) => {
  const url = `${urlreport}/prod/dashboard1/${clasificacion}/${id_detail}/${idTicket}`;
  try {
    const response = await axios.get(url, {
      params:{
        datei: datei,
        datef: datef,
        route: route,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const dashboard1_clasificacion_frezz = async (clasificacion, id_detail) => {
  const url = `${urlreport}/prod/dashboard1/${clasificacion}/${id_detail}/freezers`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const dashboard1_clasificacion_details = async (clasificacion, id_detail, datei, datef, route) => {
  const url = `${urlreport}/prod/dashboard1/${clasificacion}/${id_detail}`;
  try {
    const response = await axios.get(url, {
      params: {
          datei: datei,
          datef: datef,
          route: route,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};