//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import AlertResponse from "../../main_components/alerts/alertResponse";
import EditSector from "./tools/op_sector";
import { detele_sector, get_sectors } from "../../services/businesspartners/businesspartners";
import { SelectColumnFilter } from "../../components_cfdi/filters";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./bonuses.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import Details from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const TableSectors = () => {

  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const notificationSystemRef = useRef();  
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(()=>{
    handleSubmit();
  },[]);

  function alertas(msj,status){

    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }
  const reconsultar=(n)=>{
    if(n===true){
      setmodalT(true);  
    }else{
      setmodalT(false);
    } 
  }
  

//proceso de consultar todos los sectores
const handleSubmit = async() => {

  if(entrada===0){
    setmenssage("Consultando Sectores...")
   entrada=entrada+1;
   setmodalGeneral(true);
   setmodalT(true);
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_sectors();
          setData(d === null ? [] : d.data.sectors);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        }catch(err){
          setData([]);
          setopen(false)
          setmodalGeneral(false);
          setmodalT(false);
        }         
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err); 
  }
};
const columns = useMemo(
  () => [    
    {
      Header:()=>(
        <div>
          <img
            id="crearsector"
            src={CREATE}
            onClick={() => { CustomDialog(               
             <div>
               <EditSector operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}/>
             </div>,
           {
             className: "modalTItle",
             title:"Crear nuevo Sector",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Sector"
            alt=""
            className="butonhov"
          ></img>
        </div>
      ),     
      
      id: "Acciones",
      Cell: ({ row }) => {
      
      const DeleteSector = async () =>{  
     
      setmodalT(true);  
      setData([]);
      setopen(true);

      try {
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const dt = await detele_sector(row.original.id);
              console.log(dt);
              alertas("Sector Eliminado", true);
              handleSubmit();      
            }catch(err){
              console.log("error", err);
              setopen(false);
              setmodalT(false);
              if(err.response.status===409){
                alertas("Aún hay puntos de venta relacionados al Sector", false);
              }else{
                alertas("Error, no se pudo eliminar el sector", false);    
              }
              handleSubmit();
            }
          }, 1000);
        }else{}
      } catch (err) {
        console.log(err);
      }
    }
      function Detalles(){
        sessionStorage.setItem("id_sector",row.original.id);
        sessionStorage.setItem("sectorname",row.original.name_sector)
        sessionStorage.setItem("id_ruta", row.original.id_route_fk)
      
        window.location = "/consult_point_sale_sector";
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el sector?','Eliminar Sector','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="butonhov"
              title="Eliminar Sector"
              alt=""
            ></img>
             <img
              src={Details}
              onClick={Detalles}
              className="butonhov"
              title="Puntos de venta del sector"
              alt=""
            ></img>
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                   <EditSector operation={"EDIT"} handleSubmit={handleSubmit} data={row} reconsultar={reconsultar} alertas={alertas}></EditSector>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Sector",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           );
         }
        }
              className="butonhov"
              title="Editar Sector"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Número",
      accessor: "number_sector",
    },
    {
      Header: "Nombre",
      accessor: "name_sector",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Ruta",
      accessor: "route",
    }
    ],
    []
);
  return (
    <>
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

<MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
  ></MODAL_TABLE>
    <BREADCRUMBS niveles={
        [
          {label:"CONSULTAR SECTORES", path: null}, 
        ]
      }
    ></BREADCRUMBS>


      <div className="TableSectors">      
          <div className="Tablesect">
       <>
          <StickyTable>
            <TableContainer
              paginado={"Sectores"}
              consultas={"Sectores Consultados"}
              nametable={"Sectores"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>           
          </div>
        </div>
    </>
  );
}

export default TableSectors;
