//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./estilostable.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { SelectColumnFilter } from "../../components_cfdi/filters";
import Select from "react-select";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TableContainer from "../../main_components/Table/TableContainer";
import { get_bonification_packages_sales, get_without_bon_pack, post_bonification_packages_sales } from "../../services/bonifications/bonifications";
import Checkbox from "@mui/material/Checkbox";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";

export const ADD_SALES_BONIFICACIONES = () => {

    const notificationSystemRef = useRef();
    const [data, setData] = useState([]);
    const [originalData, setoriginalData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [allchecks, setallchecks]=useState(false);
    
    const [open, setopen]=useState(false);
    const [modalT, setmodalT] = useState(false);
    const [menssage, setmenssage] = useState("");
    const [modalGeneral, setmodalGeneral] = useState(false);


    var date = new Date();
    var dia = `${date.getDate()}`.padStart(2, "0");
    var mes = `${date.getMonth() + 1}`.padStart(2, "0");
    var año = date.getFullYear();
    var añoantes = date.getFullYear();
    var mesantes = Number(mes) - 1;
  
    if (mesantes == 0) {
      mesantes = `${12}`.padStart(2, "0");
      añoantes = añoantes - 1;
    } else {
      mesantes = `${mesantes}`.padStart(2, "0");
    } 
    const recuperarPPD = `${año}-${mes}-${dia}`;
    const [selectedOptions, setSelectedOptions] = useState([]);
  
    const handleChange = (selected) => {
      setSelectedOptions(selected);
      console.log(selected);
      filterArray(selected);
    };
  
    function filterArray(f) {
      if (f?.length > 0) {
        const filterArr = originalData.filter((elem) => {
          console.log(elem);
          return f?.some((term) =>
            elem?.name?.trim().includes(term.value.trim())
          );
        });
        console.log(filterArr);
        setData(filterArr);
      } else {
        setData(originalData);
      }
    }
    const [ff, setff] = useState(recuperarPPD);
    const [fi, setfi] = useState("");
    const [cont, setcont] = useState(0);
    const [contpackage, setcontpackage] = useState(0);

    function alertas(msj,status){
        if(status===true){
          notificationSystemRef.current.addNotification({
            message:<AlertResponse msj={msj} view={true}></AlertResponse>,
            level: 'success',
            position: 'br', 
            autoDismiss: 10, 
          });  
        }else{
          notificationSystemRef.current.addNotification({
            message:<AlertResponse msj={msj} view={false}></AlertResponse>,
            level: 'error',
            position: 'br', 
            autoDismiss: 60, 
          });
        }  
      }

    useEffect(() => {
      numeros();
    }, []);

    useEffect(()=>{
        setcont(selectedRows.length);
    }, [selectedRows])


    const handleSubmit = async () => {
      const [y, m, d] = fi.split("-");
      const [y2, m2, d2] = ff.split("-");
      if (y.length == 4) {
        const rr = [d, m, y].join("/");
        setfi(rr);
        console.log("if del lengh=4");
        console.log(fi);
      }
      if (y2.length == 4) {
        const rrr = [d2, m2, y2].join("/");
        setff(rrr);
        console.log("if del leng=4");
        console.log(ff);
      }
      var fechainicial = fi;
      var fechafinal = ff;
      const [year, month, day] = fi.split("-");
      const [year2, month2, day2] = ff.split("-");
  
      if (year.length == 10) {
        console.log("entra 1");
        const [dd, mm, yy] = fi.split("/");
        fechainicial = [yy, mm, dd].join("-");
      }
      if (year2.length == 10) {
        console.log("entra 2");
        const [dd2, mm2, yy2] = ff.split("/");
        fechafinal = [yy2, mm2, dd2].join("-");
      }
      if (fi == "") {
        alert("Falta fecha inicial");
      } else {

        setData([]);
        setopen(true);
        setmenssage("Buscando Congeladores");
        setmodalT(true);

        try{
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async () => {
                    try{
                        const d = await get_without_bon_pack(fechainicial, fechafinal);
                        const data = d===null?[]:d.data.sales;
                        setData(data);
                        setmodalGeneral(false);
                        setmodalT(false);
                        setopen(false);
                      
                    }catch(err){
                        console.log("error", err);
                        setData([]);
                        setmodalGeneral(false);
                        setmodalT(false);
                        setopen(false);
                    }
                }, 1000);
            }else{}
        }catch(err){
            console.log(err);
        }
      }
    };
    const numeros = async () => {
        try{
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                    try{
                        const d = await get_bonification_packages_sales(sessionStorage.getItem("idpack"));
                        setcontpackage(d === null ? 0 : d.data.sales.length);
                    }catch(err){
                        console.log("error", err);
                    }
                }, 1000);
            }else{}
        }catch(err){
            console.log(err);
      }
    }
    function reiniciar() {
      setSelectedRows([]);
      handleSubmit();
      numeros();
    }
    function Terminar() {
      window.location = "/consult_report_bonuses";
    }
    
    const agregarventas= async (arreglo)=> {
  
      if (arreglo.length === 0) {
        alertas("Datos vacios", false);
      }
      if (sessionStorage.getItem("idpack") == 0) {
        alertas("No se puede generar el paquete", false);
      } else {
        var data = {
          sales: arreglo,
        };

        setopen(true);
        setmodalT(true);
        try{

            var rf_token = await refresh_token();
                if(rf_token===true){
                    setTimeout(async() => {
                        try{
                            const d = await post_bonification_packages_sales(sessionStorage.getItem("idpack"), data);
                            console.log(d);
                            setopen(false);
                            alertas("Ventas agregadas con éxito", true);
                            setmodalT(false);
                            reiniciar();
                        }catch(err){
                            console.log("error post", err);
                            setopen(false);
                            alertas("No se pudo agregar Ventas al paquete, vuelva a intentarlo", false);
                            setmodalT(false);
                        }
                    }, 1000);
                }else{}
        }catch(err){
            console.log(err);
        }
    }
}

  //agregar seleccionar mukltuple socios y punto de venta

    const columns = useMemo(
      () => [
        {
          Header: "Fecha",
          accessor: "date",
        },
        {
          Filter: () => {       
            return (
              <div>
                 <Select
                  styles={{color:"black"}}
                  value={selectedOptions}
                  onChange={handleChange}
                  options={searchData}
                  isMulti
                  placeholder=""
                /> 
              </div>
            );
          },
          Header: "Razón Social",
          accessor: "name",
        },
        {
          Filter: SelectColumnFilter,
          Header: "Socio de Negocios",
          accessor: "name_business_partner",
        },
        {
          Header: "Código Externo",
          accessor: "outer_code",
        },
        {
          Header: "Número Tienda",
          accessor: "number_oxxo_store",
        },
        {
          Header: "Ticket",
          accessor: "ticket",
        },
        {
          Header: "Folio Especial",
          accessor: "special_folio_number",
        },
        {
          Header: "Total",
          accessor: (d) => {
            return d.total
              ? Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(d.total)
              : Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(d.total);
          },
        },
        {
            Header:"Comentarios",
            id:"comentarios",
            Cell: ({ row }) => { 
                const [paper, setpaper]=useState(row.original.paper);
                const isChecked = selectedRows.includes(row.original);
              
                function cambio_paper(e){
                    setpaper(e.target.value);
                    row.original.papernuevo=e.target.value;
                }

                return(
                    <div className="input-paper">
                      <input
                        type="text"
                        disabled={!isChecked}
                        value={isChecked===true?row.original.papernuevo===null?paper:row.original.papernuevo:row.original.paper}
                        onChange={(e) => cambio_paper(e)}
                      />
                    </div>
                )
            }
        },
        {
            Header:({row, page})=>{

                function crear(){
                    page.map(item => {
                        item.original.papernuevo=item.original.paper;
                    })
                    handleRowSelectAll(page)
                }


                    return (
                    <div className="checktableconge">
                        <Checkbox
                        onChange={()=>crear()}
                        checked={allchecks}
                        />
                    </div>
                    );
                },
            id:"lote",
            Cell:({ row }) => {
                
                const isChecked = selectedRows.includes(row.original);
                
                function modificar_paper(row){
                    handleRowSelect(row.original)
                    row.original.papernuevo = row.original.paper
                }
                return (
                <Checkbox
                    checked={isChecked}
                    onChange={()=>modificar_paper(row)}
                />
                );
            },
        },
      ],
      [data, selectedRows]
    );

    const handleRowSelect = (row) => {
        const isSelected = selectedRows.find((r) => r.id === row.id) !== undefined;
        if (isSelected) {
          setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
        } else {
          setSelectedRows([...selectedRows, row]);
        }
      };
      const handleRowSelectAll = (page)=>{
        const currentPageData = page.map((row) => row.original);
        
        if (selectedRows.length === currentPageData.length) {
             setallchecks(false);
             setSelectedRows([]);
           } else {
             setallchecks(true);
             setSelectedRows(currentPageData);
           }
      };

    const handlepapers=()=>{
        const selectedValues = selectedRows.map((item) => ({
            id: item.id,
            paper: item.papernuevo,
        }));
        var error = false;
        selectedValues.map(item => {
            if(item.paper===null||item.paper===""||item.paper===" "){
                error = true;
            }
        })

        if(error===true){
            alertas("Falta agregar paper en ventas", false);
        }else{
            console.log(selectedValues);
            console.log("entra a enviar ventas");
            agregarventas(selectedValues);
        }
    }

  return (
    <>
    <BREADCRUMBS niveles={
        [
          {label: "CONSULTAR REPORTES DE BONIFICACIONES", path: "/consult_report_bonuses"},
          {label: "AGREGAR VENTAS", path:null}
        ]
        }
      ></BREADCRUMBS>

        <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
        ></MODAL_TABLE>

      <div className="TableAddSale">
        <div className="">
         
            <StickyTable>
              <div className="datospackage">
                <div className="package_info">
                  <p>Nombre Paquete: {sessionStorage.getItem("name_pack")}</p>
                  <p>Número paquete: {sessionStorage.getItem("num_pack")}</p>
                </div>
              </div>

              <div className="contanerFIltersTableSelectBonus">
                <div className="filter-1 color-1">
                  <input
                    type="text"
                    className="fi"
                    value={fi}
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha inicial"
                    onChange={(event) => setfi(event.target.value)}
                  ></input>
                </div>
                <div className="filter-2 color-1">
                  <input
                    type="text"
                    value={ff}
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha Final"
                    onChange={(event) => setff(event.target.value)}
                  ></input>
                </div>

                <div className="filter-3 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    onClick={handleSubmit}
                    type="button"
                  >
                    Buscar
                  </button>
                </div>

                <div className="filter-4 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    style={{ width: "140px" }}
                    //onClick={filtrar}
                    onClick={()=>handlepapers()}
                  >
                    Agregar Ventas
                  </button>
                </div>

                <div className="filter-5 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    style={{ width: "150px" }}
                    onClick={Terminar}
                  >
                    Terminar Paquete
                  </button>
                </div>

                <div className="filter-7 color-1">
                      <p>Ventas en Paquete:&nbsp;{contpackage}</p>
                      <p>Ventas seleccionadas:&nbsp;{cont}</p>
                </div>
              </div>

              <div className="contentbotons">&nbsp;&nbsp;&nbsp;&nbsp;</div>
             <div className="tableaddsalesboni">
             <TableContainer
                paginado={"Ventas"}
                consultas={"Ventas Consultadas"}
                exportar={false}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
              />
             </div> 
            </StickyTable>
        
        </div>
      </div>
    
    </>
  )
}
