import axios from "axios";
import config from "../../config";

const urlbon = `${config?.baseURLBon}`;


export const get_without_bon_pack= async (fi, ff) => {
    const url = `${urlbon}/od/sales/without_bon_pack`;
    try {
      const response = await axios.get(url, {
        params:{
          startDate: fi,
          finalDate: ff
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const get_bonification_packages_sales= async (id) => {
    const url = `${urlbon}/od/bonification_packages/${id}/sales`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const post_bonification_packages_sales= async (id, data) => {
    const url = `${urlbon}/od/bonification_packages/${id}`;
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const get_bonification_packages= async (fi, ff) => {
    const url = `${urlbon}/od/bonification_packages`;
    try {
      const response = await axios.get(url, {
        params:{
          startDate: fi,
          finalDate: ff
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const post_bonification_packages= async (data) => {
    const url = `${urlbon}/od/bonification_packages`;
    try {
      const response = await axios.post(url,data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
     
  export const post_bonification_packages_report = async (id, dato) => {
    const url = `${urlbon}/od/bonification_packages/${id}/report`;
    try {
      const response = await axios.post(url,dato, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

       
  export const get_bonification_packages_report = async (id) => {
    const url = `${urlbon}/od/bonification_packages/${id}/report`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const put_bonification_packages_sales = async (id, data) => {
    const url = `${urlbon}/od/bonification_packages/${id}/sales`;
    try {
      const response = await axios.put(url, data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const delete_bonification_packages_sales = async (id, id2) => {
    const url = `${urlbon}/od/bonification_packages/${id}/sales/${id2}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };