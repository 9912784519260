import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useState } from 'react';
import { IMGDATA } from './imgdata';
import { CustomDialog } from 'react-st-modal';


export const DetallesAcordion = ({title = "", content = "", tipo}) => {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
    {
      tipo==="text"?
      <div style={{border: "none", margin: "0px", width: "100%"}}>
         <Accordion style={{border: "none"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            <Typography sx={{ width: '33%', flexShrink: 0}} style={{fontSize: "14px", fontWeight: "bold", paddingLeft: "10px", color: "#464C4A"}}>
            {title}
          </Typography>
          <Typography sx={{ color: 'text.secondary', }} style={{ display: expanded !== 'panel1' ? " " : "none", marginLeft: "30px", fontSize: "12px"}}>
          {`${content?.substring(0,70)}...`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>:
      content.length===0?<></>:
      <div style={{border: "none", margin: "0px", width: "100%"}}>
    <Accordion style={{border: "none"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
          <Typography sx={{ width: '33%', flexShrink: 0}} style={{fontSize: "14px", fontWeight: "bold", paddingLeft: "10px", color: "#464C4A"}}>
          Imagenes {content.length}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        <div className='carrusel-container'>
              <div className='carrusel-img'
               onClick={ async () => {
                await CustomDialog(
                  <IMGDATA content={content}/>,
                   {
                     title: "Imágenes",
                     className: 'modal-create-case',
                     showCloseIcon: true,
                     isCanClose: false,
                   } 
                 ); 
               }}
              >
                {content.map((imageData, index) => (
                  <div key={index} className='img-support'>
                    <img
                      src={`data:image/png;base64,${imageData}`}
                      alt={`Image ${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
    </div>
    }
    
    </>
    
  )
}
