//Paqueteria
import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../../imgComponents/S.gif";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Autocomplete from "@mui/material/Autocomplete";
import { useDialog } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from '@mui/material/CircularProgress';
//Componentes
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";
import { get_units, get_prod_serv, post_concepts, get_category_sat, get_section_sat, get_section_codes_sat, get_unit_codes_sat } from "../../../../services/invoices/invoice"
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2, height: '45px' },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const ObjetoImp = [
  { value: "01", label: "No objeto de impuesto" },
  { value: "02", label: "Sí objeto de impuesto" },
  { value: "03", label: "Sí objeto del impuesto y no obligado al desglose" },
];

const type_code = [
  { value: "product", label: "Producto" },
  { value: "service", label: "Servicio" },
];

const SET_CONCEPT = () => {
  const [open, setOpen] = useState(false);
  const [Concepto, setConcepto] = useState("");
  const [Clave_prod_serv, setClave_prod_serv] = useState("01010101");
  const [Clave_unidad, setClave_unidad] = useState("ACT");
  const [Descripcion, setDescripcion] = useState("");
  const [No_identificacion, setNo_identificacion] = useState("");
  const [Objeto_imp, setObjeto_imp] = useState("01");
  const [Peso_en_kg, setPeso_en_kg] = useState("1");
  const [Unidad, setUnidad] = useState("ACTIVIDAD");
  const [Valor_unitario, setValor_unitario] = useState("");
  const [unidadData, setunidadData] = useState([]);
  const [pro_servData, setpro_servData] = useState([]);
  const [Clave_prod_serv_input, setClave_prod_serv_input] = useState(true);
  const [Clave_unidad_input, setClave_unidad_input] = useState(true);
  const [category_data, setcategory_data] = useState([]);
  const [category_data_filter, setcategory_data_filter] = useState([]);
  const [section_data, setsection_data] = useState([]);
  const [select_section_data, setselect_section_data] = useState([]);
  const [select_category_data, setselect_category_data] = useState("N/A");
  const [select_type_data, setselect_type_data] = useState("product");
  const [codes_data, setcodes_data] = useState([]);
  const [sat_units_codes, setsat_units_codes] = useState([]);
  const [flag_section, setflag_section] = useState(false);
  const [flag_section_code, setflag_section_code] = useState(false);



  const handle_concept = (event) => { setConcepto(event?.target?.value); };
  const handelClave_prod_serv = (event) => {
    setClave_prod_serv(event?.target?.value);


    if (event?.target?.value === "OTRO") {
      setClave_prod_serv_input(false);
      setClave_prod_serv("N/A");

    }
  };
  const handelClave_unidad = (event) => {
    setClave_unidad(event?.target?.value);
    var dt = sat_units_codes.filter((d) => d.code_unit === event.target.value)
    console.log(dt[0]?.description);
    setUnidad(dt[0]?.description);
    if (event?.target?.value === "OTRO") {
      setClave_unidad_input(false);
      setUnidad("");
    }
  };
  const handelDescripcion = (event) => { setDescripcion(event?.target?.value); };
  const handelNo_identificacion = (event) => { setNo_identificacion(event?.target?.value); };
  const handelObjeto_imp = (event) => { setObjeto_imp(event?.target?.value); };
  const handelPeso_en_kg = (event) => {
    setPeso_en_kg(event?.target?.value);
    if (Number(event?.target?.value) > 0) {
    } else {
      alert("El peso debe ser mayor a 0");
    }
  };
  const handelUnidad = (event) => { setUnidad(event?.target?.value); };
  const handelValor_unitario = (event) => { setValor_unitario(event?.target?.value); };
  const handelOnValor_unitario = () => { setValor_unitario(Number(Valor_unitario).toFixed(2)); };
  const dialog = useDialog();

  var data = {
    concept: Concepto,
    clave_prod_serv: Clave_prod_serv,
    clave_unidad: Clave_unidad,
    descripcion: Descripcion,
    no_identificacion: No_identificacion,
    objeto_imp: Objeto_imp,
    peso_en_kg: Number(Peso_en_kg),
    unidad: Unidad,
    valor_unitario: Number(Valor_unitario),
  };
  console.log(data);
  const handleSubmit = async (event) => {
    event?.preventDefault();
    var data = {
      concept: Concepto,
      clave_prod_serv: Clave_prod_serv,
      clave_unidad: Clave_unidad,
      descripcion: Descripcion,
      no_identificacion: No_identificacion,
      objeto_imp: Objeto_imp,
      peso_en_kg: Number(Peso_en_kg),
      unidad: Unidad,
      valor_unitario: Number(Valor_unitario),
    };
    console.log(data);
    setOpen(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await post_concepts(data);
            setOpen(false);
            alert("Se genero correctamente el concepto");
            dialog.close(true);
          } catch (err) {
            setOpen(false);
            catchErrorModul("Conceptos", JSON.stringify(data), JSON.stringify(err?.response));
            alert("Error.," + err.response.data.errorMessage);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }

  };

  const handleUnidades = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_units();
            setunidadData(dt.data);
          } catch (err) {
            setunidadData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleprod_serv = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_prod_serv();
            setpro_servData(dt.data);
          } catch (err) {
            setpro_servData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };



  const handle_category = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_category_sat();
            const data = d === null ? [] : d.data;
            // Los dos nuevos objetos que deseas agregar
            const newObjects = [
              {
                "category": "N/A",
                "description": "Seleccione un servicio",
                "type": "service"
              },
              {
                "category": "N/A",
                "description": "Seleccione un producto",
                "type": "product"
              }
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setcategory_data(newData);
            setcategory_data_filter(newData.filter((d) => d.type === "product"));

            console.log(data)

          } catch (err) {
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };


  const handle_unit_codes_sat = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_unit_codes_sat();
            const data = d === null ? [] : d.data;
            // Los dos nuevos objetos que deseas agregar
            const newObjects = [
              {
                "code_unit": "N/A",
                "description": "Seleccione un producto",
              }
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setsat_units_codes(newData)
            console.log(newData)

          } catch (err) {
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };


  const handle_section = async (e) => {
    try {
      setflag_section(true);
      setsection_data([]);
      setcodes_data([]);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_section_sat(e);
            const data = d === null ? [] : d.data;
            const newObjects = [
              {
                "description_section": "Selecciona una opcion",
                "section": "N/A",
                "id": "N/A"
              },
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setsection_data(newData);


            console.log(data)

          } catch (err) {
          } finally {
            setflag_section(false)
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  const handle_section_codes = async (e) => {
    try {
      setflag_section_code(true);
      setcodes_data([]);

      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_section_codes_sat(e);
            const data = d === null ? [] : d.data;
            const newObjects = [
              {
                "description": "Selecciona una opcion",
                "code": "N/A",
              },
            ];

            // Concatena los nuevos objetos con el arreglo original
            const newData = newObjects.concat(data);
            setcodes_data(newData)
            console.log(data)

          } catch (err) {
          } finally {
            setflag_section_code(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleprod_serv();
    handleUnidades();
    handle_category();
    handle_unit_codes_sat();
  }, []);

  return (
    <div>
      <MODAL_TABLE
        open={true}
        message={"GENERANDO CONCEPTO..."}
        modalGeneral={open}
        modalT={open}
      ></MODAL_TABLE>
      <form style={{ width: "90%", margin: "0 auto" }}>
        <Box>
          <TextareaAutosize
            label="Concepto"
            variant="outlined"
            aria-label="empty textarea"
            value={Concepto}
            placeholder="Concepto"
            className="TexAreaAutosize_data"
            onChange={handle_concept}
          />
        </Box>

        <div
          style={{ display: Clave_prod_serv_input === true ? "" : "none" }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Clave producto/servicio"
              value={Clave_prod_serv}
              sx={{ width: '69%' }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {pro_servData.map((option) => (
                <option
                  key={option.clave_prod_serv}
                  value={option.clave_prod_serv}
                  selected={option.clave_prod_serv}
                >
                  {option.clave_prod_serv +
                    " " +
                    "(" +
                    option.descripcion +
                    ")"}
                </option>
              ))}
            </TextField>

            <ValidationTextField
              label="N. Identificación"
              variant="outlined"
              placeholder="N. Identificación"
              sx={{ width: '29%' }}
              onChange={handelNo_identificacion}
              value={No_identificacion}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

        </div>

        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Tipo"
              value={select_type_data}
              sx={{ width: '49%' }}
              onChange={(e) => {
                setselect_type_data(e.target.value);
                console.log(e.target.value)
                setcategory_data_filter(category_data.filter((d) => d.type === e.target.value));
                setsection_data([]);
              }}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {type_code.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>


            <ValidationTextField
              label="N. Identificación"
              variant="outlined"
              placeholder="N. Identificación"
              sx={{ width: '49%' }}
              onChange={handelNo_identificacion}
              value={No_identificacion}
              InputLabelProps={{ shrink: true }}
            />

          </Box>

        </div>


        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>


            <TextField
              select
              label="Categoría"
              value={select_category_data}
              sx={{ width: '100%' }}
              onChange={(e) => {
                setselect_category_data(e.target.value);
                handle_section(e.target.value)

              }}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {category_data_filter.map((option) => (
                <option
                  key={option.category}
                  value={option.category}
                  selected={option.category}
                >
                  {option.description}
                </option>
              ))}
            </TextField>

          </Box>

        </div>

        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>
            <Box sx={{ position: 'fixed', display: flag_section === true ? "" : "none" }}>
              <CircularProgress />
            </Box>
            <TextField
              select
              label="Sección"
              value={select_section_data}
              sx={{ width: '100%' }}
              onChange={(e) => {
                handle_section_codes(e.target.value)
                setselect_section_data(e.target.value)
                console.log(e.target.value)
              }}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {section_data.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  selected={option.id}
                >
                  {option.description_section}
                </option>
              ))}
            </TextField>
          </Box>

        </div>

        <div style={{ display: Clave_prod_serv_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>
            <Box sx={{ position: 'fixed', display: flag_section_code === true ? "" : "none" }}>
              <CircularProgress />
            </Box>
            <TextField
              select
              label="Concepto"
              value={Clave_prod_serv}
              sx={{ width: '100%' }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {codes_data.map((option) => (
                <option
                  key={option.code}
                  value={option.code}
                  selected={option.code}
                >
                  {option.code + " - " + option.description}
                </option>
              ))}
            </TextField>


          </Box>

        </div>



        <div style={{ display: Clave_unidad_input === true ? "none" : "" }}>

          <Box sx={{ display: 'flex', justifyContent: "flex-end", width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Clave Unidad"
              value={Clave_unidad}
              sx={{ width: '100%' }}
              onChange={handelClave_unidad}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {sat_units_codes.map((option) => (
                <option
                  key={option.code_unit}
                  value={option.code_unit}
                  selected={option.code_unit}
                >
                  {option.code_unit + " - " + option.description}
                </option>
              ))}
            </TextField>


          </Box>

        </div>

        <div
          style={{ display: Clave_unidad_input === true ? "" : "none" }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Clave Unidad"
              value={Clave_unidad}
              sx={{ width: "59%" }}
              onChange={handelClave_unidad}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {unidadData.map((option) => (
                <option
                  key={option.clave_unidad}
                  value={option.clave_unidad}
                  selected={option.clave_unidad}
                >
                  {option.clave_unidad + " " + "(" + option.unidad + ")"}
                </option>
              ))}
            </TextField>
            <ValidationTextField
              label="Unidad"
              variant="outlined"
              placeholder="Unidad"
              sx={{ width: "39%" }}
              value={Unidad}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

        </div>



        <Box sx={{ marginTop: '10px' }}>
          <TextareaAutosize
            label="Descripciónn"
            variant="outlined"
            aria-label="empty textarea"
            value={Descripcion}
            placeholder="Descripción"
            className="TexAreaAutosize_data"
            onChange={handelDescripcion}
          />
        </Box>


        <div
          style={{ display: Clave_unidad_input === true ? "" : "" }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <TextField
              select
              label="Objeto Impuesto"
              value={Objeto_imp}
              sx={{ width: "40%" }}
              onChange={handelObjeto_imp}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {ObjetoImp.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.label}
                >
                  {option.label}
                </option>
              ))}
            </TextField>

            <ValidationTextField
              label="Peso en KG"
              variant="outlined"
              placeholder="Peso en KG"
              sx={{ width: "28%" }}
              onChange={handelPeso_en_kg}
              type="number"
              value={Peso_en_kg}
              InputLabelProps={{ shrink: true }}
            />

            <ValidationTextField
              label="Valor Unitario"
              variant="outlined"
              type="number"
              placeholder="Valor Unitario"
              sx={{ width: "28%" }}
              onChange={handelValor_unitario}
              onBlur={handelOnValor_unitario}
              value={Valor_unitario}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

        </div>

        <div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
            <Button
              variant="contained"
              sx={{ width: "49%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Crear
            </Button>

            <Button
              variant="contained"
              sx={{ width: "49%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>

        </div>
        <br></br>
      </form>
    </div>
  );
};

export default SET_CONCEPT;
