//Paqueterias
import  { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {  useDialog } from "react-st-modal";
//Componentes
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_routes } from "../../../services/cashclosing/cashclosing";
//Estilos
import "../cashclosing.css";


const SET_CASHCLOSING = () => {
  const dialog = useDialog();
  const [ff, setff] = useState(true);
  const [ffi, setffi] = useState(true);
  const [routes, setroutes] = useState([]);
  const [selectRoute, setselectRoute] = useState("Selecciona una ruta");
  const [modalT] = useState(false);
  const [vendor, setvendor] = useState([]);
  const [selectVendro, setselectVendro] = useState(9099999);
  const [selectwarehouse, setselectwarehouse] = useState(9099999);


  const handelType = (event) => {
    setselectRoute(event?.target?.value);
    const arryVendor = routes?.filter((route) => route.route === event?.target?.value).map((user) => user.users);
    var dtAdd = {
      id: 9099999,
      id_warehouse_fk:9099999,
      fullusername: "Selecciona al vendedor",
    };﻿
    arryVendor[0].push(dtAdd);
    setvendor([]);
    setvendor(arryVendor);
    setselectVendro(9099999);
  };
  
  const handelTypeVendor = (event) => {
    setselectVendro(event?.target?.value);
    var dta= vendor[0]?.filter((e)=>e.id===Number(event?.target?.value));
    console.log(dta[0]?.id_warehouse_fk);
    setselectwarehouse(dta[0]?.id_warehouse_fk)

  };

  const handleSubmit = (event) => {
    sessionStorage.setItem("dateiSale",ffi);
    sessionStorage.setItem("datefSale",ff);
    sessionStorage.setItem("routeSale",selectRoute);
    sessionStorage.setItem("selectVendro",selectVendro);
    sessionStorage.setItem("selectwarehouse",selectwarehouse);
    sessionStorage.setItem("datawarehose","");
    console.log(selectVendro);
    if(selectVendro!==9099999){

    window.location="/Consult_cut_box_sale";
  }else{
    alert("Error: Debe seleccionar un vendedor.")
  }


  };

  const handle_router = async () => {
    try {
      const dt= await get_routes();
      const data=dt.data;
        var dtAdd = {
          route: "Selecciona una ruta",
          users: [
            {
              id: 37,
              fullusername: "Selecciona antes una ruta",
            },
          ],
        };
        data.push(dtAdd);
        console.log(data)
        setroutes(data);
    } catch (error) {
      setroutes([]);
    }
  };

  useEffect(() => {
    handle_router();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setvendor(vendor);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      </div>
      <div>
        <form>
          <div className="generarCutbox">
            <div className="expen-5n">
              <input
                type="text"
                className="fExpense2"
                onClick={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha Inicial"
                onChange={(event) => setffi(event.target.value)}
              ></input>
            </div>

            <div className="expen-6">
              <input
                type="text"
                className="fExpense2"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha Final"
                onChange={(event) => setff(event.target.value)}
              ></input>
            </div>
            <div className="expen-7">
              <TextField
                select
                label="Ruta"
                child
                value={selectRoute}
                sx={{ m: 1.5, width: "100%" }}
                onChange={handelType}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {routes?.map((option) => (
                  <option
                    key={option?.route}
                    value={option?.route}
                    selected={option?.route}
                  >
                    {option?.route}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="expen-8">
              <TextField
                select
                child
                label="Vendedor"
                value={selectVendro}
                sx={{ m: 1.5, width: "100%" }}
                onChange={handelTypeVendor}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {vendor[0]?.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    selected={option.id}
                  >
                    {option.fullusername}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "45%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              CONSULTAR
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "45%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close(false);
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default SET_CASHCLOSING;
