import axios from "axios";
import config from "../../config";
const urlcc = `${config?.baseURLClC}`;

//Consultar rutas 
export const get_debts = async (fi, ff) => {
  const url = `${urlcc}/od/debts/number`;
  try {
    const response = await axios.get(url, {
      params:{
        di: fi,
        df: ff
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar detalle de deuda 
export const get_debts_detail = async (id) => {
  const url = `${urlcc}/od/debts/${id}/payments`;
  try {
    const response = await axios.get(url, {
     
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//Consultar detalle de deuda 
export const delete_debts_detail = async (id,id_detail) => {
  const url = `${urlcc}/od/debts/${id}/payments/${id_detail}`;
  try {
    const response = await axios.delete(url, {
     
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Cancelar Venta
export const create_debts = async (id,dt) => {
  const url = `${urlcc}/od/debts/${id}/payments`;
  try {
    const response = await axios.post(
      url,dt,
      {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


