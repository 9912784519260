import EXPENSES from "../consult_expenses";

const EXPENSES_FOREING = () => {


  return (
    <>
    <EXPENSES foreing={1} expense={"EXTRANJERO"} origin={2} ></EXPENSES>
    </>
  );
};

export default EXPENSES_FOREING;
