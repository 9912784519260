import React from 'react';
import * as ReactDOM from 'react-dom';import './index.scss';
import App from './App';

import './index.css';
import Amplify from 'aws-amplify'
import aws_config from './aws-exports';
import { CssBaseline } from '@material-ui/core';

Amplify.configure(aws_config)

ReactDOM.render(
    <CssBaseline><App /></CssBaseline>
        ,
  document.getElementById('root')
);







