import axios from "axios";
import config from "../../config";
import { Auth } from "aws-amplify";

// const urlspt = `${config?.baseURLSUPPORT}`;

//Consultar Soportes
export const get_config_user = async () => {
    // const user = await Auth.currentAuthenticatedUser();
    // const url = `https://xu71v40qhi.execute-api.us-east-1.amazonaws.com/test/od_configuration_user`;
    // try {
    //   const response = await axios.get(url, {
    //     params:{
    //       rfc: user.attributes["custom:rfc_company"],
    //       id_user: user.attributes["custom:id_user"]
    //     },
    //     headers: {
    //       Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    //       "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   return Promise.resolve(response);
    // } catch (error) {
    //   return Promise.reject(error);
    // }
  };

  export const post_config_user = async (data) => {
    // const user = await Auth.currentAuthenticatedUser();
    // const url = `https://xu71v40qhi.execute-api.us-east-1.amazonaws.com/test/od_configuration_user`;
    // try {
    //   const response = await axios.post(url, data, {
    //     params:{
    //       rfc: user.attributes["custom:rfc_company"],
    //       id_user: user.attributes["custom:id_user"]
    //     },
    //     headers: {
    //       Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    //       "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   return Promise.resolve(response);
    // } catch (error) {
    //   return Promise.reject(error);
    // }
  };


  export const post_config_signin = async (data) => {
    // const user = await Auth.currentAuthenticatedUser();
    // const url = `https://pqroxijmve.execute-api.us-east-1.amazonaws.com/test/od_configuration_signin`;
    // try {
    //   const response = await axios.post(url, data, {
    //     params:{
    //       rfc: user.attributes["custom:rfc_company"],
    //       id_user: user.attributes["custom:id_user"]
    //     },
    //     headers: {
    //       Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    //       "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   return Promise.resolve(response);
    // } catch (error) {
    //   return Promise.reject(error);
    // }
  };

