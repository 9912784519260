import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import fondo from "../img-login/fondo-ht.jpg"
import fc from "../img-login/fondo.png";


export class ResetPassword extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       Username : '',
       Password : '',
       Code : '',
       RePassword : '', 
    }

    this.resetPassword = this.resetPassword.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  mostrarContrasena(){
    var tipo = document.getElementById("password-field");
    if(tipo.type === "password"){
        tipo.type = "text";
    }else{
        tipo.type = "password";
    }
  }
  
  mostrarContrasena2(){
    var tipo = document.getElementById("password-field2");
    if(tipo.type === "password"){
        tipo.type = "text";
    }else{
        tipo.type = "password";
    }
  }


  handleFormSubmission(evt) {
    if(this.state.Password === this.state.RePassword && this.state.Password !== ''){
      if(this.state.Code!==String){
      evt.preventDefault();
      this.resetPassword();
      }else{
      }
    } else{
      alert("Las contraseñas no son iguales")
    } 
  }
  async resetPassword() {
    const username = this.props.SignUpUsername.trim();
    const code = this.state.Code.trim();
    const password = this.state.Password.trim();

    try{
      await Auth.forgotPasswordSubmit(username, code, password)
      this.props.SetAuthState(AuthState.SignIn)
    } catch(err){
      console.log(err);
      var errorMen= err;
      const errorMenString = errorMen?.toString();

      const splitString = errorMenString?.split('.');
      console.log(splitString);
      const truncatedString = splitString[0];
      console.log(truncatedString);

      if(truncatedString==="CodeMismatchException: Invalid verification code provided, please try again"){
        alert("Error: se proporcionó un código de verificación no válido, inténtelo de nuevo.")
      }else if(truncatedString==="LimitExceededException: Attempt limit exceeded, please try after some time"){
        alert("Error: Se excedió el límite de intentos, intente después de un tiempo (1hr) o comuníquese con soporte soporte@herramientasinformaticas.com.");
      }
      
      else{
        alert(err)
      }

    }
  }
   
  render() {
    return (

      <div className='container_login'>
      <div className='grid-login'>
        <div className='formsesion'>
          <div className='camplogin'>
          < div className="div-lg5">
            <p className='bienvenido'>Nueva Contraseña</p>
          </div>
            
          <div className='grid-form-login reset-pass-l'>
          <div className='form-register-bloq'>
            <label className='label-usuario'>Nombre de usuario</label>
            <input 
              type={"text"}
              className="input-usuario"
              placeholder={this.props.SignUpUsername} disabled
            ></input>
          </div>
          <div className='form-reset-pass'>
            <label className='label-usuario'>Código de Verificación</label>
            <input 
              type={"text"}
              className="input-usuario"
              onChange={(e)=>
                this.setState({Code: e.target.value})
              }
              placeholder="Escribe el código de Verificación"
            ></input>
          </div>
          <div className='form-pass'>
              <div className='pass1grid'>
             
              <label className='label-contraseña'>Contraseña</label>
              <input id='password-field' className="text-center form-control" type="password" placeholder="Nueva Contraseña" onChange={(e) => this.setState({Password: e.target.value.trim()})}></input>
                    <span  className="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena}>visibility</span>

              </div> 
            </div>
            <div className='form-pass'>
              <div className='pass1grid'>
             
              <label className='label-contraseña'>Confirmar Contraseña</label>
              <input  id="password-field2" className="text-center form-control" type="password" placeholder="Confirmar Contraseña" onChange={(e) => this.setState({RePassword: e.target.value.trim()})}></input>
              <span  className="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena2}>visibility</span>

              </div> 
            </div>
            
            
            
            <div className=''>
                <button className='boton-login'  
                  onClick={this.handleFormSubmission}
                >
                  Cambiar Contraseña
                </button>
                <button className='boton-cancelar' 
                  onClick={()=>this.props.SetAuthState(AuthState.SignIn)}>
                  Cancelar
                </button> 
              </div>
          </div>
            
          </div>
        </div>
        <div className='imglogin'>
          <img src={fondo}></img>
        </div>
      </div>
    </div>

/*
      <div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration">
          </div>

          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2"> Nueva contraseña</h2>
                  <div className="form-group">
                    <input className="text-center form-control" type="text" placeholder={this.props.SignUpUsername} onChange={(e) => this.setState({Username: e.target.value})} disabled></input>
                  </div>

                  <div className="form-group">
                  <input className="text-center form-control" type="number" placeholder="Codigo de verificacion" onChange={(e) => this.setState({Code: e.target.value})}></input>
                  </div>

                  <div className="form-group">
                    <input id='password-field' className="text-center form-control" type="password" placeholder="Nueva Contraseña" onChange={(e) => this.setState({Password: e.target.value.trim()})}></input>
                    <span  className="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena}>visibility</span>

                  </div>

                  <div className="form-group">
                  <input  id="password-field2" className="text-center form-control" type="password" placeholder="Confirmar contraseña" onChange={(e) => this.setState({RePassword: e.target.value.trim()})}></input>
                  <span  className="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena2}>visibility</span>

                  </div>
                  
                  <div className="form-group">
                    <a className="form-control btn btn-primary" onClick={this.handleFormSubmission} >Cambiar contraseña</a>

                  </div>
              </div>
            </div>
          </div>
        </div>
    */
      
    )
  }
}

export default ResetPassword