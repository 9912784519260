import Cartaportev4 from '../../components_cfdi/invoice/cartaporte/cartaporte'

const SetCarataportev4 = () => {
  return (
    <div>
      <Cartaportev4></Cartaportev4> 
    </div>
  );
};

export default SetCarataportev4;
