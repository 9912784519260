//PAQUETERIAS
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//COMPONENTES

//ESTILOS
import "../../../../components_cfdi/estilo.css";
import "../../cashclosing.css";
import "../../../../components_cfdi/modal.css";
//IMAGENES/ICONOS

const FILTER_CASHCLOSING = (props) => {
  return (
    <div className="">
      <div
        className="contanerFIltersTableSelect"
        style={{ display: props.filtersColumsView === true ? "none" : "" }}
      > 
        <div className="filter-1 color-1">
          <h2>Filtros Personalizados</h2>
        </div>
        <div className="filter-2 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewTikect}
                onChange={(e) => {
                  props.setviewTikect(e.target.checked);
                }}
              />
            }
            label="Ticket"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-3 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewPV}
                onChange={(e) => {
                  props.setviewPV(e.target.checked);
                }}
              />
            }
            label="Puntos de venta"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-4 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewSN}
                onChange={(e) => {
                  props.setviewSN(e.target.checked);
                }}
              />
            }
            label="Socio de negocios"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-5 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewF}
                onChange={(e) => {
                  props.setviewF(e.target.checked);
                }}
              />
            }
            label="Fecha"
            labelPlacement="bottom"
          />
        </div>

        <div className="filter-6 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewTp}
                onChange={(e) => {
                  props.setviewTp(e.target.checked);
                }}
              />
            }
            label="Tipo"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-7 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewSt}
                onChange={(e) => {
                  props.setviewSt(e.target.checked);
                }}
              />
            }
            label="Sub Total"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-8 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewDesc}
                onChange={(e) => {
                  props.setviewDesc(e.target.checked);
                }}
              />
            }
            label="Descuentos"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-9 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewImp}
                onChange={(e) => {
                  props.setviewImp(e.target.checked);
                }}
              />
            }
            label="Impuestos"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-10 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewT}
                onChange={(e) => {
                  props.setviewT(e.target.checked);
                }}
              />
            }
            label="Total"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-11 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewAd}
                onChange={(e) => {
                  props.setviewAd(e.target.checked);
                }}
              />
            }
            label="Adeudo"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-12 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewPgd}
                onChange={(e) => {
                  props.setviewPgd(e.target.checked);
                }}
              />
            }
            label="Pagado"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-13 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewPg}
                onChange={(e) => {
                  props.setviewPg(e.target.checked);
                }}
              />
            }
            label="Pago"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-14 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewCd}
                onChange={(e) => {
                  props.setviewCd(e.target.checked);
                }}
              />
            }
            label="Crédito"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-15 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewVd}
                onChange={(e) => {
                  props.setviewVd(e.target.checked);
                }}
              />
            }
            label="Vendedor"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-16 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewCp}
                onChange={(e) => {
                  props.setviewCp(e.target.checked);
                }}
              />
            }
            label="Completa"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-17 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewfc}
                onChange={(e) => {
                  props.setviewfc(e.target.checked);
                }}
              />
            }
            label="Factura"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-18 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewGd}
                onChange={(e) => {
                  props.setviewGd(e.target.checked);
                }}
              />
            }
            label="Genera deuda"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-19 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewNf}
                onChange={(e) => {
                  props.setviewNf(e.target.checked);
                }}
              />
            }
            label="Nunca Facturar"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-20 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewVc}
                onChange={(e) => {
                  props.setviewVc(e.target.checked);
                }}
              />
            }
            label="Venta Cerrada"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-21 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewOp}
                onChange={(e) => {
                  props.setviewOp(e.target.checked);
                }}
              />
            }
            label="Venta Oportuno"
            labelPlacement="bottom"
          />
        </div>
        <div className="filter-22 color-1">
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                checked={props.viewrfc}
                onChange={(e) => {
                  props.setviewrfc(e.target.checked);
                }}
              />
            }
            label="RFC"
            labelPlacement="bottom"
          />
        </div>
      </div>
    </div>
  );
};

export default FILTER_CASHCLOSING;
