import React from 'react'
import { download_invoice } from '../../../services/invoices/invoice';
import { base64toBlob } from '../../../main_components/donwloadfuncion/download';

export const download_document = async(typedocumentexmlorpdf, id_invoce,fileName_document) => {

    try {
        const data = await download_invoice(
            typedocumentexmlorpdf,
            fileName_document,
            id_invoce
        );
        
        var dt = data.data.file;
        var blob;
        blob = base64toBlob(dt);
        
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        let arr = fileName_document.split("/");
        console.log(typedocumentexmlorpdf);
        downloadLink.download = arr[4] + `.${typedocumentexmlorpdf}`;
        downloadLink.click();
       
        } catch (err) {
            console.log(err);
        }
}

