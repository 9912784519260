//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import Checkbox from "@mui/material/Checkbox";
//COMPONENTES
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./estilostableedit.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TableContainer from "../../main_components/Table/TableContainer";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { delete_bonification_packages_sales, get_bonification_packages_sales, put_bonification_packages_sales } from "../../services/bonifications/bonifications";



const TableEditAddSales = () => {
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");
  var entrada= 0;

  
    const [selectedRows, setSelectedRows] = useState([]);

  useEffect(()=>{
    handleSubmit();
  },[]);

  function alertas(msj,status){
    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }

//proceso de consultar ventas del paquete de bonificaciones
const handleSubmit = async () => {

  if(entrada===0){
    setmodalGeneral(true);
    entrada = entrada + 1;
    setmenssage("Buscando ventas del paquete");
  }
    setmodalT(true); 
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_bonification_packages_sales(sessionStorage.getItem("id_pack_edit"))
            setData(d === null ? [] : d.data.sales);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            console.log("error", err);
            alert("el paquete aún no tiene ventas asignadas");
            window.location="/consult_report_bonuses";
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
};

const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    console.log("entra a updatemydata")
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
};

  const itemStyle = {
    padding: "2px",
    border: "1px solid silver",
    color: "Black",
    width: "120px",
    height: "25px",
  };

  const textStyle = {
    ...itemStyle,
    padding: "3px 2px",
    "font-size":"12px"
  };

const[cont, setcont]=useState(0);

useEffect(()=>{
  setcont(selectedRows.length);
}, [selectedRows])


//console.log(difT);
const actualizarpaper = async (arreglo) => {
  
  var data = {
    sales: arreglo
  }

  setmodalT(true);

  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await put_bonification_packages_sales(sessionStorage.getItem("id_pack_edit"), data);
          console.log(d);    
          alertas("Ventas actualizadas con exito", true);
          setTimeout(() => {
            window.location ="/consult_report_bonuses";
            setmodalT(false);
          }, 2000);
        }catch(err){
          console.log("error", err);
          alertas("Error al actualizar ventas", false);
          catchErrorModul("Bonificaciones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          setmodalT(false);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
}


const columns = useMemo(
  () => [
    {
      Header: "Acciones",
      id: "Acciones",

      Cell: ({ valor, row }) => {
        
      const DeleteSales = async () =>{    
       
        try{
          setmodalT(true);
          setData([]);
          var rf_token = await refresh_token();
          if(rf_token===true){
              setTimeout(async() => {
                try{
                  const d = await delete_bonification_packages_sales(sessionStorage.getItem("id_pack_edit"), row.original.id)
                if(d?.status === 201){
                  alertas("Paquete Eliminado por no contener ventas", true);
                  setTimeout(() => {
                    window.location = "/consult_report_bonuses";
                  }, 2000);      
                }else{
                  if(d?.status === 200){
                    alertas("Venta Eliminada", true);
                  }            
                handleSubmit();
                setmodalT(false);
              }
              }catch(err){
                console.log("error", err);
                alertas("Ups, algo salio mal", false);
                modalT(true);
                handleSubmit();
                
              }
            }, 1000);
          }else{}
        }catch(err){
          console.log(err);
        }        
      }

        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                const result = await Confirm('¿Esta usted seguro de eliminar la venta?','Eliminar venta','Si','No');
                if(result){
                  DeleteSales()
                  }
                }
              }
              className=""
              title="Eliminar venta"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Fecha",
      accessor: "date",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Razón Social",
      accessor: "name",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Socio de negocios",
      accessor: "name_business_partner",
    },
    {
      Header: "Código Externo",
      accessor: "outer_code",
    },
    {
      Header: "Número Tienda",
      accessor: "number_oxxo_store",
    },
    {
      Header: "Ticket",
      accessor: "ticket",
    },
    {
      Header: "Folio Especial",
      accessor: "special_folio_number",
    },
    {
      Header: "Total",
      accessor: (d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      },   
    },
    {
      Header:"Comentarios",
      id:"comentarios",
      Cell:({row}) => {
         const [paper, setpaper]=useState(row.original.paper);
                const isChecked = selectedRows.includes(row.original);
              
                function cambio_paper(e){
                    setpaper(e.target.value);
                    row.original.papernuevo=e.target.value;
                }

                return(
                    <div className="input-paper">
                      <input
                        type="text"
                        disabled={!isChecked}
                        value={isChecked===true?row.original.papernuevo===null?paper:row.original.papernuevo:row.original.paper}
                        onChange={(e) => cambio_paper(e)}
                      />
                    </div>
                )
      }
    },
    {
      Header: "Checkbox",
      id:"lote",
       Cell:({ row }) => {
                
                const isChecked = selectedRows.includes(row.original);
                
                function modificar_paper(row){
                    handleRowSelect(row.original)
                    row.original.papernuevo = row.original.paper
                }
                return (
                <Checkbox
                    checked={isChecked}
                    onChange={()=>modificar_paper(row)}
                />
                );
            },
    },
    ],
    [selectedRows]
);


    const handleRowSelect = (row) => {
        const isSelected = selectedRows.find((r) => r.id === row.id) !== undefined;
        if (isSelected) {
          setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
        } else {
          setSelectedRows([...selectedRows, row]);
        }
      };

      const handlepapers=()=>{
        const selectedValues = selectedRows.map((item) => ({
            id: item.id,
            paper: item.papernuevo,
        }));
        var error = false;
        selectedValues.map(item => {
            if(item.paper===null||item.paper===""||item.paper===" "){
                error = true;
            }
        })
        if(error===true){
            alertas("Falta agregar paper en ventas", false);
        }else{
            actualizarpaper(selectedValues);
        }
    }



  return (
    <>
        <BREADCRUMBS niveles={
        [
          {label: "CONSULTAR REPORTES DE BONIFICACIONES", path: "/consult_report_bonuses"},
          {label: "EDITAR PAQUETE", path: null}
        ]
        }
      ></BREADCRUMBS>
       <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
        ></MODAL_TABLE> 

    <div className="TableEditSales">

      <div className="">         
        <StickyTable>
          <div className="contenidoedit">          
            <div className="contentbotons">   
                  
              <div className="package_data"> 
                  <p>Nombre Paquete: {sessionStorage.getItem("name_pack_edit")}</p>
                  <p>Número paquete: {sessionStorage.getItem("num_pack_edit")}</p>
              <div/>
              
              <button
                  className="button"
                  id="buttonpackageb"
                  style={{width:"140px"}}
                  onClick={()=>handlepapers()}    
                  >
                    Guardar Cambios
                  </button> 
              </div>
            </div>   
            </div>

            <div id="countsaledit">      
                  <p>Ventas Editadas:&nbsp;{cont}</p>
            </div>

              <TableContainer
                paginado={"Ventas"}
                consultas={"Ventas Consultados"}
                exportar={false}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                updateMyData={updateMyData}
              />
            </StickyTable>
          </div>
        </div>
     
    </>
  );
};

export default TableEditAddSales;
