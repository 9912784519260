//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TextField from "@mui/material/TextField";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./freez.css"; 
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_cabinets_warehouse, get_movement_warehouse_cabinets } from "../../services/assets/assets";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { catchErrorModul } from "../../main_components/catchErr/catchError";

const MovementWarehouseCabinets= () => {
  
  //indices inicial y final

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);

  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
 
  const [id_warehouse, setid_warehouse]=useState(null);
  const [datawarehouse, setdatawarehouse]=useState([]);
  const [fi, setfi]=useState("");
  const [ff, setff]=useState("");

useEffect(()=>{
  handlealmacenes();
},[]);


//console.log(sessionStorage.getItem("roles_user"));

var permisosUsuario = sessionStorage.getItem("roles_user");
var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
var permisos = permisoUsuarioReplace?.split(",");
var permisoeditfrezz = permisos?.filter((x) => x === "ROLE_ASSETS_CONFIGURATION");

function alertas(msj,status){
  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

const handlealmacenes = async () =>{  
    try {  
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          var d = await get_cabinets_warehouse();
          setdatawarehouse(d === null ? [] : d.data.warehouses);
        }, 1000);
      }else{}
    } catch (error) {
      console.error(error);
      setdatawarehouse([]);
    }
}

const handleSubmit = async () => {
  setData([]);
  setopen(true);
  setmenssage("Buscando");
  setmodalT(true);


  if(id_warehouse===null||id_warehouse==="vacio"){
    alertas("No ha seleccionado almacén", false);
    return null;
  }

  try {
    var rf_token=await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_movement_warehouse_cabinets(id_warehouse, fi, ff);
          console.log(d);
          setData(d === null ? [] : d.data.movements);
          if(d.data.movements.length===0){
            alertas("No se encontro información de movimientos en el rango de fechas", false);
          }
          setopen(false);
          setmodalT(false);
        }catch(err){
          catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          console.error(err);
          setopen(false);
          setmodalT(false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
};

const columns = useMemo(
  () => [
    {
      Header:"Número Económico",
      accessor:"economic_number",
    },
    {
      Filter: SelectColumnFilter,
      Header:"Marca",
      accessor:"name"
    },
    {
      Header:"Modelo",
      accessor:"name_1"
    },
    {
      Header:"Movimiento",
      accessor:"movement_type"
    },
    {
      Header:"Fecha Movimiento",
      accessor:"date_movement"
    },
    {
      Header:"Almacén",
      accessor:"warehouse_name"
    },
    {
      Header:"Nota",
      accessor:"note"
    }
    ],
    [
    ]
);



  return (
    <>
     <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CONGELADORES", path: "/consult_frezzers"},
                  {label: "MOVIMIENTOS DE ALMACENES DE CONGELADORES", path: null}, 
                ]
              }
      ></BREADCRUMBS>
      
<div className="TableCongeladores-warehouse">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
          <div className="search-mov-frz-war">
          <div className="smfw1">
          <TextField
                  select
                  label="Almacén"
                  value={id_warehouse}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_warehouse(event.target.value)}  
                 >
                  <option key={0} value={"vacio"}>
                      {"Selecciona Almacén"}
                  </option>
                  
                  {
                    datawarehouse?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.warehouse_name}
                  </option>
                    ))
                  }
             </TextField>
        </div>
        <div className="smfw2">
        <input
          type="text"
          className="fi"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha inicial"
        onChange={(event) => setfi(event.target.value)}
        ></input>
        </div>
      <div className="smfw3">
        <input
          type="text"
          className="ff"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
        <div className="smfw4">
              <button className="button" onClick={handleSubmit}>Buscar</button>
        </div>
      </div>
      <div className="waremovfrezz">
        <StickyTable>
            <TableContainer
              paginado={"Congeladores"}
              consultas={"Congeladores Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
      </div>
          
          </>   
          </div>
        </div>
    </>
  );
}

export default MovementWarehouseCabinets;
