//PAQUETERIAS
import { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
//COMPONENTES
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_route_list, post_sector, put_sector } from "../../../services/businesspartners/businesspartners";

function OPERATIONS_SECTORS(props) {
  const dialog = useDialog();
  const notificationSystemRef = useRef();
  const [routes, setroutes] = useState();

  const[numsector, setnumsector] = useState(props.operation==="EDIT"?props.data.original.number_sector:0);
  const[namesector, setnamesector] = useState(props.operation==="EDIT"?props.data.original.name_sector:"");
  const[route, setroute] = useState(props.operation==="EDIT"?props.data.original.id_route_fk:null);
  const[bandera, setbandera]=useState(true);

  const [vnumsector, setvnumsector]=useState(false);
  const [vnamesector, setvnamesector]=useState(false);
  const [vroute, setvroute]=useState(false);

  useEffect(()=>{
    handleroute();
  },[]);

//animacion de boton de carga
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}
//alertas personalizadas
function alertascreate(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'tr', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'tr', 
      autoDismiss: 60, 
    });
  }  
}
  
//consulta todas las rutas
const handleroute = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutes(d === null ? [] : d.data);            
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log("error", error);
  }   
}


//crear un nuevo sector
const createnewsector = async () =>{
 
  if(namesector==="" || numsector===0 || route===null || route===0 ){
    alertascreate("Datos faltantes", false);  
    namesector===""?setvnamesector(true):setvnamesector(false);
    numsector===0?setvnumsector(true):setvnumsector(false);
    route===null || route===0?setvroute(true):setvroute(false);
  }else{
    props.reconsultar(true);
    setbandera(false);
  
  var data = {
    "numberSector":numsector,
    "nameSector":namesector,
    "idRoute":route
  }
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await post_sector(data);
            console.log(dt);
            props.alertas("Sector creado con éxito", true);
            props.reconsultar(false);
            dialog.close();
            props.handleSubmit();  
          }catch(err){
            console.log(err);
            catchErrorModul("Sectores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response)); 
            if(err.response.status===500){
              alertascreate("Error al crear el Sector", false);
              setbandera(true);
              props.reconsultar(false);
            }
            if(err.response.status===409){
              alertascreate("El número de sector ya fue utilizado");
              setbandera(true);
              props.reconsultar(false);
            }
          }       
        }, 1000);
      }else{}
     } catch (err) {
      console.log(err);
     }
  ;}
  } 

const updatesector = async () => {

    if(namesector===props.data.original.name_sector && numsector===props.data.original.number_sector && route===props.data.original.id_route_fk){
      alertascreate("Ninguna cambio realizado", false);
    }
    else{
      console.log("entra")
      props.reconsultar(true);
      setbandera(false);
      var data = {
      "numberSector":numsector,
      "nameSector":namesector,
      "idRoute":route
    }    
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await put_sector(data, props.data.original.id);
            console.log(dt);
            props.alertas("Sector actualizado con éxito", true);
            props.reconsultar(false);
            dialog.close();
            props.handleSubmit();           
          }catch(err){
            console.log(err);
            catchErrorModul("Sectores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            if(err?.response?.status === 500){
              alertascreate("Error al actualizar, vuelva a intentar", false);
              setbandera(true);
              props.reconsultar(false);
            }else{ 
              alertascreate(err?.response?.data?.errorMessage, false);
              setbandera(true);
              props.reconsultar(false);
            }
          }
        }, 1000);
      }else{}
     } catch (err) {  
      console.log(err);  
    }
  ;}    
}
  return (
    <div>
      <div className="addpointsalecss">
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  
          <Box>
            <TextField
              label="Nombre"
              required
              disabled={!bandera}
              value={namesector}
              style={vnamesector===true?{
                "border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onBlur={
                namesector===null || namesector===""?()=>setvnamesector(true):()=>setvnamesector(false)
              }
              onSelect={()=>setvnamesector(false)}
              sx={{ m: 1.5, width: "70%" }}
              onChange={(event) => setnamesector(event.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}

            ></TextField>
            <br/>
            <TextField
              label="Número"
              type={"number"}
              value={numsector}
              required
              style={vnumsector===true?{
                "border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onBlur={
                numsector===0 || numsector===""?()=>setvnumsector(true):()=>setvnumsector(false)
              }
              onSelect={()=>setvnumsector(false)}
              disabled={!bandera}
              sx={{ m: 1.5, width: "25%" }}
              onChange={(event) => setnumsector(event.target.value)}  
              InputLabelProps={{ shrink: true }}

            ></TextField>
            <TextField
             select
             label="Ruta"
             required
            value={route}

             style={vroute===true?{
               "border":"2px solid red", "borderRadius":"5px"}:{}
             }
             disabled={!bandera}
             defaultValue={""}
             InputLabelProps={{
               shrink: true,
             }}
             SelectProps={{
               native: true,
             }}
             sx={{ m: 1.5, width: "41%" }}
             onChange={(event) => setroute(parseInt(event.target.value))}  
             onBlur={
               route===null || route===0?()=>setvroute(true):()=>setvroute(false)
             }
             onSelect={()=>setvroute(false)}
           >
             <option key={0} value={0} selected>
                 {"Seleccione ruta"}
                </option>
              {
               routes?.parametersList?.map((option) => (
               <option
                  key={option.idRoute}
                  value={option.idRoute}
                >
                 {option.route}
                </option>
             ))
           } 
             </TextField>
            
          </Box>
          <br></br>
          <br></br>
          <Box>
            {
              bandera===true?
              <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={()=>{props.operation==="EDIT"?updatesector():createnewsector()}}
            >
              {props.operation==="EDIT"?"Guardar Cambios":"Crear Sector"}
            </Button>:
            <Button
            variant="contained"
            style={{"backgroundColor":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button>
          }  
          <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default OPERATIONS_SECTORS;
 