import { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import {TextField, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import { Checkbox } from "semantic-ui-react";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Candadoc from "../../imgComponents/candado_c.png";
import Candadoa from "../../imgComponents/candado_a.png"; 
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BusinessIcon from '@mui/icons-material/Business';
import RoomIcon from '@mui/icons-material/Room';
import estados from "../../dataComponets/state.json";

import MODAL_TABLE from "../../main_components/modal/modal_Table";

import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
import "../../componentsNewDashboard/dashboar.css";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import "./company.css";
import { get_company, get_company_address, put_company, put_company_address } from "../../services/configuration/configuration";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

function EditCompany() {

const notificationSystemRef = useRef();
var entrada=0;
const [modalT, setmodalT] = useState(false);
const [menssage, setmenssage] = useState("");
const [modalGeneral, setmodalGeneral] = useState(false);

//variables
  const [business_name, setbusiness_name] = useState();
  const [trade_name, settrade_name] = useState();
  const [rfc_uk, setrfc_uk] = useState();
  const [route_handler, setroute_handler] = useState();
  const [logo, setlogo] = useState();
  const [logon, setlogon]=useState(null);
  const [keycode_login, setkeycode_login] = useState();
  const [distance_allowed, setdistance_allowed] = useState();
  const [latitude_central_map, setlatitude_central_map] = useState();
  const [longitude_central_map, setlongitude_central_map] = useState();
  const [company_description, setcompany_description] = useState();
  const [inventory_traking, setinventory_traking] = useState();
  const [bucket, setbucket] = useState();
  const [tax_regimen, settax_regimen]= useState();
  const [employer_registration, setemployer_registration]=useState();
  const [phone, setphone]=useState();
  const [web, setweb]=useState();
  const [mail, setmail]=useState();
  const [dms_user, setdms_user]=useState();
  const [dms_password, setdms_password]=useState();
  const [dms_server, setdms_server]=useState();
  const [dms_port, setdms_port]=useState();
  const [automatic_invoice, setautomatic_invoice]=useState();
  const [c_moneda, setc_moneda]=useState();
  const [c_regimen_fiscal, setc_regimen_fiscal]=useState();
  const [add_oportuno_note, setadd_oportuno_note]=useState();
  const [allow_second_special_folio_number_internal, setallow_second_special_folio_number_internal]=useState();
  const [register_bp_from_invoicing, setregister_bp_from_invoicing]=useState();
  const [expense_default_account, setexpense_default_account]=useState();
  const [redirect_url, setredirect_url]=useState();
  const [ticket_header, setticket_header]=useState();
  const [execute_initial_inventory, setexecute_initial_inventory]=useState();
  const [execute_debts_review, setexecute_debts_review]=useState();
  const [allow_bonification, setallow_bonification]=useState();
  const [clean_warehouse, setclean_warehouse]=useState();
  const [alert_mail, setalert_mail]=useState();
  const [automatic_dms, setautomatic_dms]=useState();
  const [apk, setapk]=useState();
  const [invoicing_test, setinvoicing_test]=useState();
  const [allow_bank_deposit, setallow_banck_deposit]=useState();
  const [allow_cash_settlement, setallow_cash_settlement]=useState();
  const [allow_mandatory_scan, setallow_mandatory_scan]=useState();
  const [apk_version, setapk_version]=useState();
  const [allow_suggested, setallow_suggested]=useState();
  const [questionnaire_url, setquestionnaire_url]=useState();
  const [close_secondary, setclose_secondary]=useState();
  const [integrate_lines_mobile_sales, setintegrate_lines_mobile_sales]=useState();
  const [tracking_of_canceled_invoices, settracking_of_canceled_invoices]=useState();
  const [folio_per_route, setfolio_per_route]=useState();
  const [delivery_commission, setdelivery_commission]=useState();

  //direccion
  const [street, setstreet]=useState(null);
  const [outdoor_number, setoutdoor_number]=useState(null);
  const [inside_number, setinside_number]=useState(null);
  const [neighborhood, setneighborhood]=useState(null);
  const [zip_code, setzip_code]=useState(null);
  const [city, setcity]=useState(null);
  const [town, settown]=useState(null);
  const [state, setstate]=useState(null);
  const [country, setcountry]=useState(null);
  const [reference, setreference]=useState(null);
  const [subsidiary, setsubsidiary]=useState(null);
  const [address_type, setaddress_type]=useState(null);
  
  const [extension, setextension]=useState(null);
  const [imgname, setimgname]=useState(null);
  const [bandera, setbandera]=useState(true);
  
useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);
  
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


const handlecompanyaddress = async () =>{
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_company_address();
          setaddress_type(d===null?"":d.data.address.address_type);
          setcity(d===null?"":d.data.address.city);
          setcountry(d===null?"":d.data.address.country);
          setinside_number(d===null?"":d.data.address.inside_number);
          setneighborhood(d===null?"":d.data.address.neighborhood);
          setoutdoor_number(d===null?"":d.data.address.outdoor_number);
          setreference(d===null?"":d.data.address.reference);
          setstate(d===null?"":d.data.address.state);
          setstreet(d===null?"":d.data.address.street);
          setsubsidiary(d===null?"":d.data.address.subsidiary);
          settown(d===null?"":d.data.address.town);
          setzip_code(d===null?"":d.data.address.zip_code);
          setmodalGeneral(false);
          setmodalT(false);
        }catch(err){
          console.log("error", err);
          alertas("Error al obtener dirección de la empresa, vuelva a internarlo", false);
          setmodalGeneral(false);
          setmodalT(false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}


const handleSubmit = async () => {

  if(entrada===0){
    setmodalGeneral(true);
    setmodalT(true);
    setmenssage("Buscando Información de la Empresa");
    entrada=entrada+1;
  }else{
    setmodalT(true);
  }
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async () => {
        try{
          handlecompanyaddress();
          var d = await get_company();
          setbusiness_name(d===null?"":d.data.company.business_name);
          settrade_name(d===null?"":d.data.company.trade_name);
          setrfc_uk(d===null?"":d.data.company.rfc_uk);
          setroute_handler(d===null?"":d.data.company.route_handler);
          setlogo(d===null?"":d.data.company.logo);
          setkeycode_login(d===null?"":d.data.company.keycode_login);
          setdistance_allowed(d===null?"":d.data.company.distance_allowed);
          setlatitude_central_map(d===null?"":d.data.company.latitude_central_map);
          setlongitude_central_map(d===null?"":d.data.company.longitude_central_map);
          setcompany_description(d===null?"":d.data.company.company_description);
          setinventory_traking(d===null?"":d.data.company.inventory_traking);
          setbucket(d===null?"":d.data.company.bucket);
          settax_regimen(d===null?"":d.data.company.tax_regimen);
          setemployer_registration(d===null?"":d.data.company.employer_registration);
          setphone(d===null?"":d.data.company.phone);
          setweb(d===null?"":d.data.company.web);
          setmail(d===null?"":d.data.company.mail);
          setdms_user(d===null?"":d.data.company.dms_user);
          setdms_password(d===null?"":d.data.company.dms_password);
          setdms_server(d===null?"":d.data.company.dms_server);
          setdms_port(d===null?"":d.data.company.dms_port);
          setautomatic_invoice(d===null?"":d.data.company.automatic_invoice);
          setc_moneda(d===null?"":d.data.company.c_moneda);
          setc_regimen_fiscal(d===null?"":d.data.company.c_regimen_fiscal);
          setadd_oportuno_note(d===null?"":d.data.company.add_oportuno_note);
          setallow_second_special_folio_number_internal(d===null?"":d.data.company.allow_second_special_folio_number_internal);
          setregister_bp_from_invoicing(d===null?"":d.data.company.register_bp_from_invoicing);
          setexpense_default_account(d===null?"":d.data.company.expense_default_account);
          setredirect_url(d===null?"":d.data.company.redirect_url);
          setticket_header(d===null?"":d.data.company.ticket_header);
          setexecute_initial_inventory(d===null?"":d.data.company.execute_initial_inventory);
          setexecute_debts_review(d===null?"":d.data.company.execute_debts_review);
          setallow_bonification(d===null?"":d.data.company.allow_bonification);
          setclean_warehouse(d===null?"":d.data.company.clean_warehouse);
          setalert_mail(d===null?"":d.data.company.alert_mail);
          setautomatic_dms(d===null?"":d.data.company.automatic_dms);
          setapk(d===null?"":d.data.company.apk);
          setinvoicing_test(d===null?"":d.data.company.invoicing_test);
          setallow_banck_deposit(d===null?"":d.data.company.allow_bank_deposit);
          setallow_cash_settlement(d===null?"":d.data.company.allow_cash_settlement);
          setallow_mandatory_scan(d===null?"":d.data.company.allow_mandatory_scan);
          setapk_version(d===null?"":d.data.company.apk_version);
          setallow_suggested(d===null?"":d.data.company.allow_suggested);
          setquestionnaire_url(d===null?"":d.data.company.questionnaire_url);
          setclose_secondary(d===null?"":d.data.company.close_secondary);
          setintegrate_lines_mobile_sales(d===null?"":d.data.company.integrate_lines_mobile_sales);
          settracking_of_canceled_invoices(d===null?"":d.data.company.tracking_of_canceled_invoices);
          setfolio_per_route(d===null?"":d.data.company.folio_per_route);
          setdelivery_commission(d===null?"":d.data.company.delivery_commission);
          setmodalGeneral(false);
          setmodalT(false);
        }catch(err){
          console.log("error", err);
          alertas("Error al obtener información de la empresa, vuelva a internarlo", false);
          setmodalGeneral(false);
          setmodalT(false);
        }  
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
};


function updateCompanyf(){

  console.log(register_bp_from_invoicing);
  console.log(extension);
  console.log(logon);

  if(logon==null){
    updateCompany();
  }else{
    if(extension===".jpeg" || extension===".jpg" ||extension===".png" || extension===".svg"){
      updateCompany();
    }else{
      alertas("Formato de imagen no válido", false);
    }
  }
}

//Actualizar datos de compañia
const updateCompany = async()=>{

  setbandera(false);
  setmodalT(true);

  var data = {
     "business_name":business_name, //string
     "trade_name":trade_name, //string
     "rfc_uk":rfc_uk, //string
     "route_handler":route_handler,//boolean
    "logo":  logon==null?null:   
    {
      'content': logon,
      'name': imgname,
      'type': extension //string
    },
      "keycode_login":keycode_login, //string
     "distance_allowed":distance_allowed, //integer
     "latitude_central_map":latitude_central_map, //float
     "longitude_central_map":longitude_central_map, //float
     "company_description":company_description, //string
     "inventory_traking": inventory_traking, //boolean
     "bucket":bucket, //string
     "tax_regimen":tax_regimen, //string
     "employer_registration":employer_registration, //string
     "phone":phone, //string
     "web":web, //string
     "mail":mail, //string
     "dms_user":dms_user, //string
     "dms_password":dms_password, //string
     "dms_server":dms_server, //string
     "dms_port":dms_port, //string
     "automatic_invoice": automatic_invoice,//boolean
     "c_moneda":c_moneda, //string(3)
     "c_regimen_fiscal":c_regimen_fiscal, //string(3)
     "add_oportuno_note":add_oportuno_note, //boolean
     "allow_second_special_folio_number_internal":allow_second_special_folio_number_internal, //boolean
     "register_bp_from_invoicing":register_bp_from_invoicing, //boolean
     "expense_default_account":expense_default_account, //string = 0000-00-0000
     "redirect_uri":redirect_url, //string
     "ticket_header":ticket_header, //string
     "execute_initial_inventory":execute_initial_inventory, //boolean
     "execute_debts_review":execute_debts_review, //boolean
     "allow_bonification":allow_bonification, //boolean
     "clean_warehouse":clean_warehouse, //boolean
     "alert_mail":alert_mail, //string
     "automatic_dms":automatic_dms, //boolean
     "apk":apk, //string
     "invoicing_test":invoicing_test, //boolean
     "allow_bank_deposit":allow_bank_deposit, //boolean
     "allow_cash_settlement":allow_cash_settlement, //boolean
     "allow_mandatory_scan":allow_mandatory_scan, //boolean
     "apk_version":apk_version, //string
     "allow_suggested":allow_suggested, //boolean
     "questionnaire_url":questionnaire_url, //string
     "close_secondary":close_secondary, //boolean
     "integrate_lines_mobile_sales":integrate_lines_mobile_sales, //boolean
     "tracking_of_canceled_invoices":tracking_of_canceled_invoices, //boolean
     "folio_per_route":folio_per_route, //boolean
      "delivery_commission":delivery_commission,
  }
try {
  var rf_token = await refresh_token();
  if(rf_token===true){
    setTimeout(async() => {
      try{
        const dt = await put_company(data);
        console.log(dt);
        alertas("Datos de empresa actualizados con éxito", true);
        updateaddress();
      }catch(err){
        console.log(err);
        catchErrorModul("Company", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        alertas("No se pudo actualizar la informaciíon de la empresa, vuelva a intertarlo", false);
        setbandera(true);
        seteditables(!editables);
        setmodalT(false);
      }     
    }, 1000);
  }else{}
 } catch (err) {  
  console.log(err);
};
} 


const updateaddress = async ()=>{
  var data = {
    "street":  street,
    "outdoor_number": outdoor_number,
    "inside_number": inside_number,
    "neighborhood": neighborhood,
    "zip_code": zip_code,
    "city": city,
    "town": town,
    "state": state,
    "country": country,
    "reference": reference,
    "subsidiary": subsidiary,
    "address_type": address_type
 }

try {
  var rf_token = await refresh_token();
  if(rf_token===true){
    setTimeout(async() => {
      try{
        const dt = await put_company_address(data);
        console.log(dt);
        alertas("Dirección de empresa actualizada con éxito", true);
        setbandera(true);
        seteditables(!editables);
        setmodalT(false);
        handleSubmit();
      }catch(err){
        console.log(err);
        catchErrorModul("Company", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        alertas("No se pudo actualizar la Dirección de la empresa, vuelva a intertarlo", false);
        setbandera(true);
        seteditables(!editables);
        setmodalT(false);
        handleSubmit();
      }
    }, 1000);
  }else{}
} catch (err) {  
  console.log(err);
};
}

function handleimagen(event){
  var file = event.target.files[0];
  console.log(file);

  var parts = file.name.split(".");
  setextension(("."+parts[+1]).toLowerCase());

  var namei = file.name.split(".",1)[0]
  setimgname(namei);

  const reader =new FileReader();
  reader.onloadend = () =>{

    var partes = reader.result.split(",");
    var before_coma = partes[1];

    //setlogo(reader.result);
    setlogon(before_coma);
  }
  reader.readAsDataURL(file);
}

const [cc1, setcc1] = useState(true);
const [cc2, setcc2] = useState(false);
const [cc3, setcc3] = useState(false);
const [value, setValue] = useState(0);
const [editables, seteditables]=useState(true);

function handlec1(){
  setcc1(true);
  setcc2(false);
  setcc3(false);
}
function handlec2(){
  setcc1(false);
  setcc2(true);
  setcc3(false);
}
function handlec3(){
  setcc1(false);
  setcc2(false);
  setcc3(true);
}

const[showPassword,setShowPassword]=useState(false);

  return (
    <div>

      <BREADCRUMBS niveles={
                [
                  {label: "ACTUALIZAR DATOS DE EMPRESA", path: null},
                ]
              }
      ></BREADCRUMBS>


      <div className="updatecompany">
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
              open={true}
              message={menssage}
              modalGeneral={modalGeneral}
              modalT={modalT}
            ></MODAL_TABLE> 
      

      {/* <button onClick={handleantonio}>Pruebas</button>  */}
      <div className="candado">
          <img onClick={()=> seteditables(!editables)}
            src={editables?Candadoc:Candadoa}
            alt=""
          ></img>
        </div>

    <div className="pestc1c2">
    <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction style={cc1===false?{"backgroundColor":"#E7E7E7"}:{}} label="Información General" icon={<BusinessIcon onClick={handlec1} />}  onClick={handlec1}/>
        <BottomNavigationAction style={cc2===false?{"backgroundColor":"#E7E7E7"}:{}} label="Dirección" icon={<RoomIcon onClick={handlec2} />}  onClick={handlec2}/>
        <BottomNavigationAction style={cc3===false?{"backgroundColor":"#E7E7E7"}:{}} label="Permisos" icon={<ListAltIcon onClick={handlec3}/>} onClick={handlec3}/>
        
      </BottomNavigation>
    </Box>
    </div>
    </div> 
        <div className="pestañac1" style={cc1===true?{"display":"block"}:{"display":"none"}}>
        <div className="compgrid01">
            <div className="comp1">
              <TextField
                label="Razón Social"
                value={business_name}
                disabled={editables}
                sx={{ m: 1.5, width: "95%" }}
                onChange={(event) => setbusiness_name(event.target.value)}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="comp2">
              <TextField
                  label="Nombre comercial"
                  value={trade_name}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => settrade_name(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          </div>  
          <div className="compgrid02">
          <div className="comp3">
              <TextField
                  label="RFC"
                  value={rfc_uk}
                  sx={{ m: 1.5, width: "95%" }}
                  disabled={editables}
                  onChange={(event) => setrfc_uk(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp4">
              <TextField
                  label="Abreviatura"
                  value={keycode_login}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setkeycode_login(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp5">
              <TextField
                  label="Distancia permitida de venta"
                  value={distance_allowed}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setdistance_allowed(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp6">
              <TextField
                  label="Latitud de la Matriz"
                  value={latitude_central_map}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setlatitude_central_map(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp7">
                <TextField
                  label="Longitud de la Matriz"
                  disabled={editables}
                  value={longitude_central_map}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setlongitude_central_map(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          </div>
          <div className="compgrid03">
           
            <div className="comp8">
              <TextField
                  label="Descripción de la empresa"
                  value={company_description}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setcompany_description(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
           
            <div className="comp9">
                <TextField
                  label="Bucket"
                  value={bucket}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setbucket(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp10">
              {editables===false?
                <TextField
                label="Logotipo"
                sx={{ m: 1.5, width: "100%" }}
                type={"file"}
                accept="image/*"
                onChange={handleimagen}  
                InputLabelProps={{ shrink: true }}
              ></TextField>:
              <TextField
              label="Logotipo"
              value={logo}
              disabled
              sx={{ m: 1.5, width: "100%"}}
              InputLabelProps={{ shrink: true }}
            ></TextField>
              }
            </div>
           
          </div>
          <div className="compgrid04"> 
            <div className="comp11">
                <TextField
                  label="Régimen Fiscal"
                  value={tax_regimen}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => settax_regimen(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp12">
               <TextField
                  label="Registro del Empleador"
                  value={employer_registration}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setemployer_registration(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp13">
                <TextField
                  label="Web"
                  value={web}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setweb(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          </div>
          <div className="compgrid05">
          <div className="comp14">
                <TextField
                  label="Phone"
                  value={phone}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setphone(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp15">
               <TextField
                  label="Mail"
                  value={mail}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setmail(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            
          </div>
         
          <div className="compgrid07">
            <div className="comp20">
                <TextField
                  label="Moneda"
                  value={c_moneda}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setc_moneda(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp21">
                <TextField
                  label="Régimen Fiscal"
                  value={c_regimen_fiscal}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setc_regimen_fiscal(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp22">
              <TextField
                  label="Cuenta de Gastos"
                  value={expense_default_account}
                  disabled={editables}
                  required
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setexpense_default_account(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp23">
              <TextField
                  label="URL Redirección"
                  value={redirect_url}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setredirect_url(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
         </div> 
         <div className="compgrid08">
         <div className="comp24">
              <TextField
                  label="Cabecera de Ticket"
                  value={ticket_header}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setticket_header(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          </div>
          <div className="compgrid09">
          <div className="comp26">
               <TextField
                  label="Correo de Alerta"
                  value={alert_mail}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setalert_mail(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          </div>
          <div className="compgrid10">
          
          <div className="comp27">
              <TextField
                  label="Versión APK"
                  value={apk_version}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setapk_version(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp28">
              <TextField
                  label="Enlace del APK"
                  value={apk}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setapk(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          </div>
          <div className="compgrid10b">
          <div className="comp29">
                <TextField
                  label="Questionnaire URL"
                  value={questionnaire_url}
                  disabled={editables}
                  required
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setquestionnaire_url(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          <div className="comp29b">
              <TextField
                  label="Comisión de porteo para venta secundaria"
                  value={delivery_commission}
                  disabled={editables}
                  required
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setdelivery_commission(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
          </div>
          
          </div>
          {editables===false?
            <Box>
            {bandera===true?
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={updateCompanyf}
          >
            Actualizar Información
          </Button>:
          <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button>
          }
            
          <Button
            style={{"background-color":"red"}}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            disabled={!bandera}
            onClick={() => {
              seteditables(!editables);
              } 
            }
          >
          cancelar
        </Button></Box>:""
          }
        </div>
        <div className="pestañac2" style={cc2===true?{"display":"block"}:{"display":"none"}}>
          
          <div className="compgridd1">
            <div className="compaddress1">
            <TextField
            label="Calle"
            value={street}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setstreet(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress2">
            <TextField
            label="Número Exterior"
            value={outdoor_number}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setoutdoor_number(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress3">
            <TextField
            label="Número Interior"
            value={inside_number}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setinside_number(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress4">
            <TextField
            label="Colonia"
            value={neighborhood}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setneighborhood(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
          </div>
          <div className="compgridd2">
          <div className="compaddress5">
            <TextField
            label="Localidad"
            value={town}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => settown(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress6">
            <TextField
            label="Municipio"
            value={city}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setcity(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress7">
            <TextField
            label="Código Postal"
            value={zip_code}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setzip_code(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress8">
            <TextField
              select
              disabled={editables}
              value={state}
              required
              label="Estado"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setstate(event.target.value)}  
            >
              {
                estados.map(item =>(
                  <option key={item.clave} value={item.clave}>
                    {item.label}
                  </option>
                ))
              }
            </TextField>
            </div>
            <div className="compaddress9">
            <TextField
            label="País"
            value={country}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setcountry(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
          </div>
          <div className="compgridd3">
         
            <div className="compaddress10">
            <TextField
            label="Referencia"
            value={reference}
            disabled={editables}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setreference(event.target.value.toUpperCase())}
            InputLabelProps={{ shrink: true }}
          ></TextField>
            </div>
            <div className="compaddress11">
            <TextField
              select
              disabled={editables}
              label="Tipo de Dirección"
              value={address_type}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setaddress_type(event.target.value)}  
            >
              <option key={0} value={"ENTREGA"} selected >
                  {"ENTREGA"}
              </option>
              <option key={1} value={"FISCAL"} selected >
                  {"FISCAL"}
              </option>
            </TextField>
            </div>
            <div className="compaddress12">
            <TextField
            select
            label="Sucursal"
            disabled={editables}
            value={subsidiary}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setsubsidiary(event.target.value)}  
          >
            <option key={1} value={"MATRIZ"} selected >
                {"MATRIZ"}
            </option>
            <option key={2} value={"SUCURSAL"}>
                {"SUCURSAL"}
            </option>
      </TextField>    
            </div>
          </div>

          {editables===false?
            <Box>
            {bandera===true?
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={updateCompanyf}
          >
            Actualizar Información
          </Button>:
          <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button>
          }
            
          <Button
            style={{"background-color":"red"}}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            disabled={!bandera}
            onClick={() => {
              seteditables(!editables);
              } 
            }
          >
          cancelar
        </Button></Box>:""
          }
        </div>      
  
  
  
<div className="pestañac3" style={cc3===true?{"display":"block"}:{"display":"none"}}>
        <h2>Permisos: </h2>

<div className="compgrid11">
<div className="comp30">
    <label className="labelcheck">Administra Ruta:  
      </label>
      <Checkbox
    checked={route_handler}
    disabled={editables}
    onChange={()=>setroute_handler(!route_handler)}
    inputProps={{"aria-label": "primary checkbox"}}
    style={{"padding":"10px", "transform":"scale(1.7)"}}
    />
 </div>
 <div className="comp31">
  <label className="labelcheck">Seguimiento de Inventario: 
   </label>  
   <Checkbox
  checked={inventory_traking}
  disabled={editables}
  onChange={()=>setinventory_traking(!inventory_traking)}
  inputProps={{"aria-label": "primary checkbox"}}
  style={{"padding":"10px", "transform":"scale(1.7)"}}
  /> 
  </div>
  <div className="comp32">
    <label className="labelcheck">Factura Automática: 
     </label>  
     <Checkbox
      checked={automatic_invoice}
      disabled={editables}
      onChange={()=>setautomatic_invoice(!automatic_invoice)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
    /> 
    </div>
</div>
<div className="compgrid12">
<div className="comp33">
  <label className="labelcheck">Nota de Oportuno: 
  </label>  
  <Checkbox
  checked={add_oportuno_note}
  disabled={editables}
  onChange={()=>setadd_oportuno_note(!add_oportuno_note)}
  inputProps={{"aria-label": "primary checkbox"}}
  style={{"padding":"10px", "transform":"scale(1.7)"}}
  /> 
  </div> 
<div className="comp34">
  <label className="labelcheck">Habilitar Segundo Número de Folio:  
  </label>  
  <Checkbox
  checked={allow_second_special_folio_number_internal}
  disabled={editables}
  onChange={()=>setallow_second_special_folio_number_internal(!allow_second_special_folio_number_internal)}
  inputProps={{"aria-label": "primary checkbox"}}
  style={{"padding":"10px", "transform":"scale(1.7)"}}
  /> 
  </div>
  <div className="comp35">
    <label className="labelcheck">Ejecución Inicial de Inventario:  
    </label> 
    <Checkbox
      checked={execute_initial_inventory}
      disabled={editables}
      onChange={()=>setexecute_initial_inventory(!execute_initial_inventory)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>  
</div>
<div className="compgrid13">    
<div className="comp36">
    <label className="labelcheck">Permitir Bonificaciones:  
    </label>
    <Checkbox
      checked={allow_bonification}
      disabled={editables}
      onChange={()=>setallow_bonification(!allow_bonification)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
  <div className="comp37">
  <label className="labelcheck">Permitir prueba de facturación:  
    </label>
    <Checkbox
      checked={invoicing_test}
      disabled={editables}
      onChange={()=>setinvoicing_test(!invoicing_test)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(2.0)"}}
      />
  </div>     
  <div className="comp38">
  <label className="labelcheck">Permitir deposito bancario:  
    </label>
    <Checkbox
      checked={allow_bank_deposit}
      disabled={editables}
      onChange={()=>setallow_banck_deposit(!allow_bank_deposit)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
</div>
<div className="compgrid14">  
<div className="comp39">
   <label className="labelcheck">DMS Automático:  
      </label>
      <Checkbox
      checked={automatic_dms}
      disabled={editables}
      onChange={()=>setautomatic_dms(!automatic_dms)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
   
<div className="comp40">
  <label className="labelcheck">Permitir liquidación en efectivo:  
    </label>
    <Checkbox
      checked={allow_cash_settlement}
      disabled={editables}
      onChange={()=>setallow_cash_settlement(!allow_cash_settlement)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
  <div className="comp41">
  <label className="labelcheck">Permitir escaneo obligatorio:  
    </label>
    <Checkbox
      checked={allow_mandatory_scan}
      disabled={editables}
      onChange={()=>setallow_mandatory_scan(!allow_mandatory_scan)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
  </div>
  <div className="compgrid15">
  <div className="comp42">
  <label className="labelcheck">Permitir pedido sugerido:  
    </label>
      <Checkbox
      checked={allow_suggested}
      disabled={editables}
      onChange={()=>setallow_suggested(!allow_suggested)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
  <div className="comp43">
   <label className="labelcheck">Limpiar Almacén de Reparto:  
    </label>
    <Checkbox
      checked={clean_warehouse}
      disabled={editables}
      onChange={()=>setclean_warehouse(!clean_warehouse)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>
  <div className="comp44">
  <label className="labelcheck">Ejecución de deuda:
  </label> 
  <Checkbox
  checked={execute_debts_review}
  disabled={editables}
  onChange={()=>setexecute_debts_review(!execute_debts_review)}
  inputProps={{"aria-label": "primary checkbox"}}
  style={{"padding":"10px", "transform":"scale(1.7)"}}
  />  
  </div>
</div>

 <div className="compgrid16">
 <div className="comp45">
   <label className="labelcheck">Folio por ruta:  
    </label>
    <Checkbox
      checked={folio_per_route}
      disabled={editables}
      onChange={()=>setfolio_per_route(!folio_per_route)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />  
  </div> 
  <div className="comp46">
  <label className="labelcheck">Seguimiento facturas canceladas:  
    </label>  
    <Checkbox
      checked={tracking_of_canceled_invoices}
      disabled={editables}
      onChange={()=>settracking_of_canceled_invoices(!tracking_of_canceled_invoices)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />
  </div>  
<div className="comp47">
  <label className="labelcheck">Comisión de porteo venta secundaria:  
    </label> 
    <Checkbox
      checked={close_secondary}
      disabled={editables}
      onChange={()=>setclose_secondary(!close_secondary)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />             
  </div>    
</div>
<div className="compgrid17">
<div className="comp48">
    <label className="labelcheck">Integrar renglones de venta en el módulo OD Android:  
    </label>
    <Checkbox
      checked={integrate_lines_mobile_sales}
      disabled={editables}
      onChange={()=>setintegrate_lines_mobile_sales(!integrate_lines_mobile_sales)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      />  
    </div>
  
  <div className="comp49">
  <label className="labelcheck">Registrar Socio de Negocio desde Factura:
   </label>  
   <Checkbox
      checked={register_bp_from_invoicing}
      disabled={editables}
      onChange={()=>setregister_bp_from_invoicing(!register_bp_from_invoicing)}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"padding":"10px", "transform":"scale(1.7)"}}
      /> 
    </div>
  </div>
  <div className="compgrid06">
          <div className="comp16">
                <TextField
                  label="Usuario DMS"
                  value={dms_user}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setdms_user(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp17">
              <TextField
                  label="Clave DMS"
                  value={dms_password}
                  type={showPassword?"text":"password"}
                  disabled={editables}
                  sx={{ m: 1.5, width: "70%" }}
                  onChange={(event) => setdms_password(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                  
                ></TextField>
                <div className="visibleb">
                <IconButton 
                        className="visibility"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                     >
                        {showPassword ? <Visibility/>:<VisibilityOff/>}
                    </IconButton>
              </div>
                
            </div>
            <div className="comp18">
                <TextField
                  label="Servidor DMS"
                  value={dms_server}
                  disabled={editables}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) => setdms_server(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            <div className="comp19">
              <TextField
                  label="Puerto DMS"
                  value={dms_port}
                  disabled={editables}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => setdms_port(event.target.value)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
            
          </div>
            {editables===false?
            <Box>
            {bandera===true?
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={updateCompanyf}
          >
            Actualizar Información
          </Button>:
          <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button>
          }
            
          <Button
            style={{"background-color":"red"}}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            disabled={!bandera}
            onClick={() => {
              seteditables(!editables);
              } 
            }
          >
          cancelar
        </Button></Box>:""
          }
          
</div>        
       
      </div>
    </div>
  );
}
export default EditCompany;
