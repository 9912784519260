import { useEffect, useState, useCallback } from 'react'
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_invoices_map } from '../../../services/invoices/invoice';
import "./map_relation.css";
import 'reactflow/dist/style.css';
import pdf from "../../../imgComponents/pdf dowload.png";
import {CustomDialog, Confirm} from "react-st-modal";
import load_grap from "../../../imgComponents/iconos/loading.gif";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
} from 'reactflow';
import { download_document } from './download_document';

const CustomNode = ({ data }) => {

  return (
    <div className="custom-node">
      <div className={
        data.tipo==="PAGO"?
          "title-pago custom-title":
            data.tipo==="NOTA DE CREDITO"?
              "title-nota custom-title":
                data.tipo==="FACTURA"?
                  "title-factura custom-title":
                    data.tipo==="TRASLADO"?
                      "title-traslado custom-title":
                        "title-default custom-title"
        }
      >
      <h2>{data.tipo}</h2>
      </div>
      <div className='custom-details'>
        <p> UUID: <span>{data.uuid}</span></p>
        <p>Serie: <span>{data.serie}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Folio: <span>{data.folio}</span></p>
        <p>Fecha: <span>{data.date}</span></p>
        <p>Estado: <span>{data.status_cancel===null?"Vigente":data.status_cancel}</span></p>
        {
          data.relacion===undefined || data.relacion===null?<></>:
            <p>Relacion: <span>{data.relacion}</span></p>
        }
        {
          data.tipo==="NOTA DE CREDITO"?
            <p>Total: <span>${data.total}</span></p>:
              data.tipo==="PAGO"?
                  <p>Total: <span>${data.total}</span></p>:
                  data.tipo==="FACTURA"?
                    <p>Total: <span>${data.total}</span></p>:
                      data.tipo==="TRASLADO"?
                        <p>Total: <span>${data.total}</span></p>:
                    <></>
        }
        <div className='descargar-pdf'>
            <img src={pdf} onClick={
             async() =>{
              const result = await Confirm(` ¿Esta usted seguro de descargar el documento ${data.serie+"-"+data.folio} ?` ,'Descarga de Documentos','Si','No');
              if(result){
                  download_document("pdf", data.id, data.filename)
                }
              }  
            } title='Descargar Documento'></img>
        </div>
    </div>
      <Handle type="source" position="right" id="a" />
      <Handle type="target" position="left" id="a" />
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

export const MAP_RELATION_INVOICE = (props) => {

  const [data, setdata]=useState([]);
  const [load, setload]=useState(false);

  console.log(props.row.original);
  
  useEffect(() => {
      handlesubmit()
  }, [])


  const handlesubmit = async()=>{
          try{
              props.setmodalT(true);
              setload(true);

              var rf_token = await refresh_token();
              if(rf_token===true){
                  setTimeout(async() => {
                      try{
                        //const d = await get_invoices_map(91830);
                        const d = await get_invoices_map(props?.row?.original?.id);
                        console.log(d?.data);
                        analisis(d?.data);
                        props.setmodalT(false);
                        setload(false);
                        
                      }catch(err){
                        console.log(err);
                        setdata([]);
                        props.setmodalT(false);
                        setload(false);
                      }
                  }, 1000);
              }else{}
          }catch(err){
              console.log(err)
          }
      }

    const analisis = (d)=>{

      var perspectiva = props?.row?.original?.tipodecomprobante;

        const processedNodesFather = [{
            id: props?.row?.original?.uuid_sat,
            data: { 
              tipo: props?.row?.original?.tipodecomprobante,
              uuid: props?.row?.original?.uuid_sat,
              serie: props?.row?.original?.serie,
              folio: props?.row?.original?.folio,
              date: props?.row?.original?.invoice_date,
              id: props?.row?.original?.id,
              total: props?.row?.original?.total,
              status_cancel: props?.row?.original?.cancelation_status===null?"Vigente":props?.row?.original?.cancelation_status,
            },
            position: { x: 100, y: 300}, // Ajusta las posiciones según tus necesidades
            type: 'custom',
          }
        ]
        
    const processedNodesSon = d.map((item, index)=>{
          
          if(perspectiva==="FACTURA"){
            return(
              {
                id: item.uuid,
                data: {...item},
                position: {
                  x: 500,
                  y: item.tipo==="FACTURA"?0:              
                      item.tipo==="PAGO"? 300: 
                        item.tipo==="NOTA DE CREDITO"? 900: 600
                },
                type: 'custom'
              }
            )
          }else if(perspectiva==="PAGO"){
            return(
              {
                id: item.uuid,
                data: {...item},
                position: {
                  x: 500,
                  y: item.tipo==="PAGO"?0:              
                      item.tipo==="FACTURA"? 300: 600
                },
                type: 'custom'
              }
            )
          }
          else{
            return(
              {
                id: item.uuid,
                data: {...item},
                position: {
                  x: 500, 
                  y: item.tipo==="NOTA DE CREDITO"? 0: 
                        item.tipo==="PAGO"? 300: 600
                },
                type: 'custom'
              }
            )
          }
        });

        const arregloCombinado = processedNodesFather.concat(processedNodesSon);

        const processedEdges = processedNodesSon.map((item ,index) =>{
            return(
              {
                id: `e-${index}`,
                source: props?.row?.original?.uuid_sat, // Origen de la conexión (puedes ajustarlo según tus necesidades)
                target: item.data.uuid,
                sourceHandle: "a"
              }
            )
        })

        console.log(arregloCombinado);
        console.log(processedEdges);


        setNodes(arregloCombinado);
        setEdges(processedEdges);    
      }
    

      
      
      const [nodes, setNodes, onNodesChange] = useNodesState([]);
      const [edges, setEdges, onEdgesChange] = useEdgesState([]);
      
      const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
    

      return (
      <div className='container-map-relation'>
        <div className='container-map'>
          {
            load===false?
            <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            fitView
          >
            <Controls/>
            <MiniMap />
            <Background className='fondo-map-modal'/>
          </ReactFlow>:
          <div className='load_grap_map'>
              <img src={load_grap} alt=''></img>
              <p>Cargando mapa de relaciones...</p>
          </div>
          }
          
        </div>
    </div>
  )
}



