import { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CustomDialog } from "react-st-modal";
import estados from "../../dataComponets/state.json";
import { Checkbox } from "semantic-ui-react";
import usoCFDI from "../../dataComponets/usoCFDI.json";
import metodoPago from "../../dataComponets/metodo_pago.json";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import dataregimen from "../../dataComponets/regimen.json";
//ICONOS
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import StoreIcon from '@mui/icons-material/Store';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import CODEQR from "../../imgComponents/codigoqr.png";

//imagenes
import Dir from "../../imgComponents/direccion.png";
import Update_bussines_p from "./update_bussines_p.jsx";
import "./registergeneral.css";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_code_businesspartners, get_requirements, post_register_general } from "../../services/businesspartners/businesspartners";
import { get_route_list } from "../../services/businesspartners/businesspartners";
import { get_businesspartners_list } from "../../services/businesspartners/businesspartners";
import { get_sectors } from "../../services/businesspartners/businesspartners";
import { get_clasification } from "../../services/businesspartners/businesspartners";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

function RegisterGeneral() {

  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("Agregando Registro General");

  //requerimentos
  const [datareq, setdatareq] = useState([]);
  const [routedata, setroutedata] = useState([]);
  const [businesdata, setbusinesdata] = useState([]);
  const [sectordata, setsectordata] = useState([]);
  const [sectordatafilter, setsectordatafilter] = useState([]);
  const [clasificaciones, setclasificaciones]=useState([]);
  const [subcanales, setsubcanales] = useState([]);
  const [subcanalesf, setsubcanalesf] = useState([]);

  const [bandera, setbandera]=useState(true);
  //variables del formulario socio de negocios
  const [enables, setenables]=useState(false);
  const [codesocio, setcodesocio]=useState(null);
  const [bussnespartner, setbussnespartner] = useState(null);
  const [namepartner, setnamepartner] = useState(null);
  const [firstlastname, setfirstlastname] = useState(null);
  const [secondlastname, setsecondlastname] = useState(null);
  const [rfc, setrfc] = useState(null);
  const [balancepartner, setbalancepartner] = useState(0.0);
  const [persontype, setpersontype] = useState(" ");
  const [businesspartnertype, setbusinesspartnertype] = useState("CLIENTE");
  const [partnertype, setpartnertype] = useState("PROPIO");
  const [email, setemail] = useState(null);
  const [paymethod, setpaymethod] = useState(null);
  const [accountnumber, setaccountnumber] = useState(null);
  const [allowinvoicing, setallowinvoicing]= useState(false); //booleano
  const [cfdi, setcfdi] = useState(null);
  const [creditdays, setcreditdays] = useState(0);
  const [phone, setphone] = useState(null);
  const [methodpay, setmethodpay] = useState("PUE");
  const [tax_residence, settax_residence]=useState(null);
  const [num_reg_id_trib, setnum_reg_id_trib]=useState(null);
  const [tax_regime, settax_regime]=useState(null);
  const [capital_regime,setcapital_regime]=useState(null);

  const [street, setstreet] = useState(null); //calle
  const [outdoornumber, setoutdoornumber] = useState(null); //numero exterior
  const [insidenumber, setinsidenumber] = useState(null); //numero interios
  const [neighborhood, setneighborhood] = useState(null); //colonia
  const [zipcode, setzipcode] = useState(null); //codigo postal
  const [city, setcity] = useState(null); //municipio
  const [state, setstate] = useState(null); //estado
  const [town, settown] = useState(null); //localidad
  const [country, setcountry] = useState("MÉXICO"); //pais
  const [reference, setreference] = useState(null); //referencia
  const [subsidiary, setsubsidiary] = useState("MATRIZ");
  const [addresstype, setaddresstype] = useState("ENTREGA");

  //variables del formulario puntos de venta

  const [enable,setenable] = useState(false);
  const [codigo, setcodigo] = useState(0); //obligatorio
  const [nombre, setnombre] = useState(null); //obligatorio  //si
  const [codigoU, setcodigou] = useState(null); //si
  const [gdeuda, setgdeuda] = useState(true); //si
  const [limcredito, setlimcredito] = useState(0.00); //si
  const [saldo, setsaldo] = useState(0.00); //si
  const [nota, setnota] = useState(null);  //si
  const [lat, setlat] = useState(null); //obligatorio //si
  const [lng, setlng] = useState(null); //obligatorio //si
  const [numtienda, setnumtienda] = useState(null); //obligatorio //si
  const [folioesp, setfolioesp] = useState(null); //obligatorio //si
  const [canal, setcanal] = useState(null); //obligatorio //si
  const [subcanal, setsubcanal] = useState(null); //obligatorio //si
  const [ruta, setruta] = useState(null); //obligatorio //si
  const [socioneg, setsocioneg] = useState(null); //obligatorio 
  const [condpago, setcondpago] = useState(null); //obligatorio //si
  const [listprecios, setlistprecios] = useState(null); //obligatorio//si
  const [correo, setcorreo] = useState(null); //si
  const [telefono, settelefono] = useState(null); //si
  const [visit, setvisit] = useState(null); //obligatorio //si
  const [frecvisit, setfrecvisit] = useState(null); //obligatorio //si
 
  const [calle, setcalle] = useState(null); //obligatorio //si
  const [numext, setnumext] = useState(null); //obligatorio //si
  const [numint, setnumint] = useState(null);//si
  const [colonia, setcolonia] = useState(null); //obligatorio //si
  const [cp, setcp] = useState(null); //obligatorio //si
  const [munic, setmunic] = useState(null); //obligatorio //si
  const [estado, setestado] = useState(null); //obligatorio//si
  const [local, setlocal] = useState(null); //obligatorio//si
  const [pais, setpais] = useState("MÉXICO"); //obligatorio//si
  const [refer, setrefer] = useState(null); //si
  const [tipdir, settipdir] = useState("ENTREGA");//si 
  const [sucursal, setsucursal] = useState("MATRIZ");//si
  const [clasificacion, setclasificacion] = useState(null); //si
  //para eliminar o actualizar la relacion con el punto de venta con el sector dirigirse al modulo de sectores
  const [sector, setsector] = useState(null); 
  const [ordensector, setordensector] = useState(null);  

  useEffect(()=>{
      handleSubmit();
      handleroute();
      handlebusines();
      handlesectors(); 
      handleclasificacion();
      handlecodebus();     
  },[]);
 

  const handleCorreo = (event) => {

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/;
    const newValue = (event.target.value).split(",");

    var isValid = true;
    //const isValidEmail = emailRegex.test(newValue);
    for(var i=0; i<newValue.length;i++){
      if(!emailRegex.test(newValue[i]?.trim())){
        isValid=false;
        break;
      }
    }

    if(isValid){
      setemail(event.target.value);
      setvmail(false);
    }else{
      setvmail(true);
    }

  }
  const handleCorreo2 = (event) => {

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/; 
    const newValue = (event.target.value).split(",");

    var isValid2 = true;

    for(var i=0;i<newValue.length;i++){
      if(!emailRegex.test(newValue[i]?.trim())){
        isValid2=false;
        break;
      }
    }

    if (isValid2) {
      setcorreo(event.target.value);
      setvcorreo(false);
    } else {
      setvcorreo(true);
    }
  }

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_requirements();
            setdatareq(d === null ? [] : d.data);
            setsubcanales(d === null ? [] : d.data.subchannels);
            setsubcanalesf(d === null ? [] : d.data.subchannels);
            setcodigo((d === null ? [] : d.data.code).toString());  
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }else{}
    } catch (error) {
      console.log(error);
    }
}
const handlecodebus = async () =>{
  //console.log("entra a consultar codigo de socio");
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_code_businesspartners();
          setcodesocio(d === null ? [] : (d.data.code).toString());
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}
const handleroute = async () => {
  try {
    var rf_token= await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutedata(d === null ? [] : d);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}
const handlebusines = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_businesspartners_list();
          setbusinesdata(d === null ? [] : d.data); 
        }catch(err){
          console.log(err);
        } 
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}
const handlesectors = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_sectors();
          setsectordata(d === null ? [] : d.data.sectors); 
          setsectordatafilter(d === null ? [] : d.data.sectors);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
   } catch (error) {
    console.log(error);
  }
}
const handleclasificacion = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_clasification();
          setclasificaciones(d === null ? [] : d.data);  
        }catch(err){
          console.log(err);
        }      
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


const createregistergeneral = async () =>{
  setmodalT(true);
  //verificacion de datos obligatorios para crear un punto de venta 
  setbandera(false);

var datarg = {  

  "business_partner":{
    "bp_code":codesocio,
    "editable":enables==="true"?true:false,
    "business_partner":bussnespartner,
    "rfc":rfc,
    "name":namepartner,
    "first_last_name":firstlastname,
    "second_last_name":secondlastname,
    "balance":balancepartner,
    "person_type":persontype,
    "business_partner_type":businesspartnertype,
    "partner_type":partnertype,
    "email":email,
    "paymethod":paymethod,
    "account_number":accountnumber,
    "allow_invoicing":allowinvoicing===false?false:(allowinvoicing==="true"?true:false),
    "cfdi_use":cfdi,
    "credit_days": parseInt(creditdays),
    "phone":phone,
    "metodo_pago":methodpay,
    "tax_residence":tax_regime, //Para socios extranjeros - Opcional
    "num_reg_id_trib": num_reg_id_trib,//Para socios extranjeron - Opcional
    "tax_regime": tax_regime,//Regimén fiscal del socio
    "capital_regime": capital_regime //Tipo de sociedad del socio - Opcional"
  },
  "address_partner":{
    "street":street,
    "outdoor_number":outdoornumber,
    "inside_number":insidenumber,
    "neighborhood":neighborhood,
    "zip_code":zipcode,
    "city":city,
    "state":state,
    "town":town,
    "country":country,
    "reference":reference,
    "subsidiary":subsidiary,
    "address_type":addresstype
  },
  "point_of_sale":{
    "pos_code": codigo,
    "editable":enable==="true"?true:false,
    "pos_name": nombre,
    "pos_outer_code": codigoU,
    "pos_generates_debt": gdeuda==="true"?true:(gdeuda==="false"?false:true),
    "pos_credit_limit": limcredito, 
    "pos_balance": saldo,
    "pos_note": nota,
    "pos_latitude": lat, 
    "pos_longitude": lng,  
    "pos_number_oxxo_store": numtienda,  
    "pos_id_special_number_folio": folioesp,  
    "pos_id_channel_fk": canal, 
    "pos_id_subchannel_fk": subcanal, 
    "pos_id_route_fk": ruta,  
    "pos_id_condition_pay_fk": condpago,
    "pos_id_price_list_fk": listprecios,
    "pos_email": correo,
    "pos_phone": telefono,
    "pos_frequency": frecvisit,
    "pos_visit_days": visit,
    "classification": clasificacion,
    "idSector":sector,
    "orderSector":ordensector
    },
    "address_point_of_sale":{
    "street": calle,
    "outdoor_number": numext,
    "inside_number": numint,
    "neighborhood": colonia,
    "zip_code": cp,
    "city": munic,
    "state": estado,
    "town":local,
    "country": pais,
    "reference": refer,
    "subsidiary": sucursal,
    "address_type": tipdir,
  }
}

console.log(datarg);

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
             const dt = await post_register_general(datarg);
             console.log(dt);
             alertas("Registro General creado con éxito", true);
             setTimeout(() => {
               window.location="/register_general";  
             }, 3000);   
          }catch(err){
            console.log(err);
            catchErrorModul("Registro General", JSON.stringify(datarg), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            alertas(err?.response?.data?.errorMessage, false);
            setmodalT(false);
            setbandera(true);  
          }
        }, 1000);
      }else{}
      } catch (err) {}
}

//obtener direccion mediante latitud y longitud
const handleClick = async()=>{

  if(bandera===true){
    if(lat===null||lng===null){
      alertas("Error en campos de latitud y longitd", false);
    }else{
      const response=await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc`);
      const data = await response.json();
      //console.log(data)
      llenado(data?.results[0])
    }
  }else{
    //console.log("no entra")
  }  
}

//funcion que llena los campos unicamente cuando se ha accedido a la funcion de direccion por gps automatica
function llenado (m){

  if(m.address_components.length===7){
  setnumext((m.address_components[0].long_name).toUpperCase());
  setcalle((m.address_components[1].long_name).toUpperCase());
  setcolonia((m.address_components[2].long_name).toUpperCase());
  setmunic((m.address_components[3].long_name).toUpperCase());
  setcp((m.address_components[6].long_name).toUpperCase());
  setestado((m.address_components[4].long_name).toUpperCase());
  }
  if(m.address_components.length===4){
    setmunic((m.address_components[1].long_name).toUpperCase());
    setestado((m.address_components[2].long_name).toUpperCase());
  }
}

//autocompletar los campos de direccion 
function Autocompletardir(){

  setvstreet(false);
  setvoutdoor(false);
  setvneigh(false);
  setvzip(false);
  setvcity(false);
  setvstate(false);
  setvtown(false);

  setcalle(street);
  setnumext(outdoornumber);
  setnumint(insidenumber);
  setcolonia(neighborhood);
  setcp(zipcode);
  setmunic(city);
  setestado(state);
  setlocal(town);
  setrefer(reference);
  setsucursal(subsidiary);
  settipdir(addresstype);
}

const [moralfisica, setmoralfisica]= useState(false);
const [formbussnes, setformbussnes] = useState(true);
const [formdirecb, setformdirecb]= useState(false);
const [formper, setformper] = useState(false);
const [formdirec, setformdirec] = useState(false);

const socionegocios = () =>{
  if(bandera===true){
    setformbussnes(true);
    setformdirecb(false);
    setformper(false);
    setformdirec(false);
  }
}

const direccionbuss = () => {

if(bandera===true){

  if(persontype==="FISICA"){
    if(bussnespartner===null || namepartner===null || namepartner==="" || firstlastname===null || firstlastname==="" ||
      secondlastname===null || secondlastname==="" || rfc===null || paymethod===null || accountnumber === null|| accountnumber.length<4 ||
      cfdi === null || email===null || vmail===true || phone===null || phone.length<10 || tax_regime===null || tax_regime==="")
  {
    bussnespartner===null?setvsocio(true):setvsocio(false);
    namepartner===null || namepartner===""?setvname(true):setvname(false);
    firstlastname===null || firstlastname===""?setvfname(true):setvfname(false);
    secondlastname===null || secondlastname===""?setvsname(true):setvsname(false);
    rfc===null?setvrfc(true):setvrfc(false);
    paymethod===null?setvmetodop(true):setvmetodop(false);
    accountnumber==null?setvnumcuenta(true): (accountnumber.length<4?setvnumcuenta(true):setvnumcuenta(false));
    cfdi===null?setvcfdi(true):setvcfdi(false);
    email===null?setvmail(true):(vmail===true?setvmail(true):setvmail(false));
    phone===null?setvphone(true):(phone.length<10?setvphone(true):setvphone(false));
    tax_regime===null||tax_regime===""?setvtax_regime(true):setvtax_regime(false);

    alertas("Datos faltantes", false); 
  }else{
    setformbussnes(false);
    setformdirecb(true);
    setformper(false);
    setformdirec(false);  

  }
  }else{
    if(bussnespartner===null || rfc===null || paymethod===null || accountnumber === null|| accountnumber.length<4 ||
      cfdi === null || email===null || vmail===true || phone===null || phone.length<10 || tax_regime===null || tax_regime==="")
  {
    bussnespartner===null?setvsocio(true):setvsocio(false);
    rfc===null?setvrfc(true):setvrfc(false);
    paymethod===null?setvmetodop(true):setvmetodop(false);
    accountnumber===null?setvnumcuenta(true):(accountnumber.length<4?setvnumcuenta(true):setvnumcuenta(false));
    cfdi===null?setvcfdi(true):setvcfdi(false);
    email===null?setvmail(true):(vmail===true?setvmail(true):setvmail(false));
    phone===null?setvphone(true):(phone.length<10?setvphone(true):setvphone(false));
    tax_regime===null||tax_regime===""?setvtax_regime(true):setvtax_regime(false);

    alertas("Datos faltantes", false);
  }else{
    setformbussnes(false);
    setformdirecb(true);
    setformper(false);
    setformdirec(false);
    
  }
}
}   
}
const Puntoventainfo = () =>{

if(bandera===true){

  if(street===null || outdoornumber===null || neighborhood===null || 
    zipcode===null || city===null || state===null ||
    town===null ){

    street===null?setvstreet(true):setvstreet(false);
    outdoornumber===null?setvoutdoor(true):setvoutdoor(false);
    neighborhood===null?setvneigh(true):setvneigh(false);
    zipcode===null?setvzip(true):setvzip(false);
    city===null?setvcity(true):setvcity(false);
    state===null?setvstate(true):setvstate(false);
    town===null?setvtown(true):setvtown(false);

    alertas("Datos faltantes", false);

  }else{
    setformbussnes(false);
    setformdirecb(false);
    setformper(true);
    setformdirec(false);
   }
 }
}

const Direccionpuntoventa = () => {

if(bandera===true){
  if(sector===null || sector==="" || sector==="vacio"){
    setvordens(false);
    setordensector(null);
  }else{
    if(ordensector===null || ordensector===""){
      setvordens(true);
      alertas("Parámetros faltantes", false);
      return null;
    }else{
      setvordens(false);  
    }
  }

if(nombre===null  || condpago===null || visit===null || frecvisit===null || listprecios===null || 
  folioesp===null || canal===null || subcanal===null || ruta===null || telefono===null || telefono.length<10 ||
  correo===null || vcorreo===true || clasificacion===null || clasificacion===0 || vordens===true){
    alertas("Datos faltantes", false);

    nombre===null?setvnombre(true):setvnombre(false);
    condpago===null?setvcondpago(true):setvcondpago(false);
    visit===null?setvdiasvisit(true):setvdiasvisit(false);
    frecvisit===null?setvfrec(true):setvfrec(false);
    listprecios===null?setvlistp(true):setvlistp(false);
    folioesp===null?setvfolio(true):setvfolio(false);
    canal===null?setvcanal(true):setvcanal(false);
    subcanal===null?setvsubcanal(true):setvsubcanal(false);
    ruta===null?setvruta(true):setvruta(false);
    telefono===null?setvtelefono(true):(telefono.length<10?setvtelefono(true):setvtelefono(false));
    correo===null?setvcorreo(true):(vcorreo===true?setvcorreo(true):setvcorreo(false));
    clasificacion==null || clasificacion===0?setvclasificacion(true):setvclasificacion(false);

  } else{
    setformbussnes(false);
    setformdirecb(false);
    setformper(false);
    setformdirec(true);  
    //console.log(clasificacion);
  }
 }
}
const enviarparametros=()=>{

  if(lat===null || lng===null || calle === null || numext===null || colonia===null ||
     cp===null || munic===null || estado===null || local===null){
      alertas("Valores faltantes", false);

      lat===null?setvlat(true):setvlat(false);
      lng===null?setvlng(true):setvlng(false);
      calle===null?setvcalle(true):setvcalle(false);
      numext===null?setvnumext(true):setvnumext(false);
      colonia===null?setvcolonia(true):setvcolonia(false);
      local===null?setvlocalidad(true):setvlocalidad(false);
      munic===null?setvmunicipio(true):setvmunicipio(false);
      cp===null?setvcp(true):setvcp(false);
      estado===null?setvestado(true):setvestado(false);
     }else{
      createregistergeneral();
     }
}

const handleChange = () => {
  if(enable===true){
    setenable(false);
  }else{
    setenable(true);
  }
};
const handleChange2 = () => {
  if(enables===true){
    setenables(false);
  }else{
    setenables(true);
  }
};


function verificarRFC(){

  if(rfc===null || rfc===""){
      setvrfc(true);
  }else{
    setvrfc(false);
    var rfcverif = rfc?.trim();
    if(rfcverif.length===13){
      setpersontype("FISICA");
      setmoralfisica(true);
      datospersonafisica();
    }else{
      if(rfcverif.length===12){
        setpersontype("MORAL");
        setmoralfisica(false);
      }
      else{
        alertas("Verifica la longitud del RFC",false);
        setrfc(null);
        setvrfc(true);
      }
    }
}
}
function datospersonafisica(){
  
  const [p1, p2, p3] = bussnespartner.split(" ").reverse();
  const nombres = ((bussnespartner.replace(p2?.trim(),"")).replace(p1?.trim(),""))?.trim();  
  const nombresactualizado = nombres.replace(/ +/g, ' ');

  if(p2===undefined && p3===undefined){
    setnamepartner(p1);
    setfirstlastname("");
    setsecondlastname("");
    setvfname(true);
    setvsname(true);
    return null;
  }
  if(p3===undefined){
    setnamepartner(p2);
    setfirstlastname(p1);
    setsecondlastname("");
    setvsname(true);
    return null;
  }

  setnamepartner(nombresactualizado);
  setfirstlastname(p2);
  setsecondlastname(p1);

  setvname(false);
  setvfname(false);
  setvsname(false);
}

const [vsocio, setvsocio]= useState(false);
const [vrfc, setvrfc] = useState(false);
const [vcfdi, setvcfdi] = useState(false);
const [vname, setvname] = useState(false);
const [vfname, setvfname] = useState(false);
const [vsname, setvsname] = useState(false);
const [vmetodop, setvmetodop] = useState(false);
const [vnumcuenta, setvnumcuenta] = useState(false);
const [vphone, setvphone]=useState(false);
const [vmail, setvmail]=useState(false);
const [vtax_regime, setvtax_regime]=useState(false);

const [vstreet, setvstreet] = useState(false);
const [voutdoor, setvoutdoor] = useState(false);
const [vneigh, setvneigh] = useState(false);
const [vzip, setvzip] = useState(false);
const [vcity, setvcity] = useState(false);
const [vstate, setvstate] = useState(false);
const [vtown, setvtown] = useState(false);
const [vnombre, setvnombre] = useState(false);
const [vcondpago, setvcondpago] = useState(false);
const [vdiasvisit, setvdiasvisit] = useState(false);
const [vfrec, setvfrec] = useState(false);
const [vlistp, setvlistp] = useState(false);
const [vfolio, setvfolio]=useState(false);
const [vcanal, setvcanal] = useState(false);
const [vsubcanal, setvsubcanal] = useState(false);
const [vruta, setvruta] = useState(false);
const [vtelefono, setvtelefono]=useState(false);
const [vcorreo, setvcorreo]=useState(false);
const [vclasificacion, setvclasificacion]=useState(false);
const [vordens, setvordens]=useState(false);

//verificacion datos de direccion
const [vlat, setvlat] = useState(false);
const [vlng, setvlng] = useState(false);
const [vcalle, setvcalle]= useState(false);
const [vnumext, setvnumext] = useState(false);
const [vcolonia, setvcolonia] = useState(false);
const [vlocalidad, setvlocalidad] = useState(false);
const [vmunicipio, setvmunicipio] = useState(false);
const [vcp, setvcp] = useState(false);
const [vestado, setvestado] = useState(false);


const [value, setValue] =useState(0);

const handlesectororden =(event)=>{
  console.log(event.target.value);
  if(event.target.value===null || event.target.value==="" || event.target.value==="vacio"){
      setvordens(false);
      setordensector(null);
      console.log(ordensector);
      console.log(vordens);
  }
}


const scanqr =()=>{
  const result =  CustomDialog(
    <Update_bussines_p
      setbussnespartner={setbussnespartner}
      setnamepartner={setnamepartner}
      setfirstlastname={setfirstlastname}
      setsecondlastname={setsecondlastname}
      setrfc={setrfc}
      setemail={setemail}
      settax_regime={settax_regime}
      setcapital_regime={setcapital_regime}

      setstreet={setstreet}
      setoutdoornumber={setoutdoornumber}
      setinsidenumber={setinsidenumber}
      setneighborhood={setneighborhood}
      setzipcode={setzipcode}
      setcity={setcity}
      setstate={setstate}
      setpersontype={setpersontype}

      setvsocio={setvsocio}
      setvrfc={setvrfc}
      setvname={setvname}
      setvsname={setvsname}
      setvfname={setvfname}
      setvmail={setvmail}

      setvstreet={setvstreet}
      setvoutdoor={setvoutdoor}
      setvneigh={setvneigh}
      setvzip={setvzip}
      setvcity={setvcity}
      setvstate={setvstate}
    >

    </Update_bussines_p>,
    {
      className: "modalSetBussnes",
      title: "Actualizar socio de negocios",
      showCloseIcon: true,
      isCanClose: false,
    }
  );
}

return (
<div>

      <BREADCRUMBS niveles={
        [
          {label: "REALIZAR REGISTRO GENERAL", path: null},
        ]
        }
      ></BREADCRUMBS>

   <div className="formulariopointofsale">
   <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

   <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
  ></MODAL_TABLE>

   <Box>
  <br/>
  <div className="pestañas">
  
<div className="menu-registergeneral">
  <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400}}>
      <img src={CODEQR} title="Escanear código QR" className="qr-register-general"  onClick={scanqr} alt=""></img>
     
      <BottomNavigation
        showLabels
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Socio de Negocios" icon={<PersonIcon onClick={socionegocios} />}  onClick={socionegocios}/>
        <BottomNavigationAction label="Dirección Socio de Negocios" icon={<RoomIcon onClick={direccionbuss}/>} onClick={direccionbuss}/>
        <BottomNavigationAction label="Punto de Venta" icon={<StoreIcon onClick={Puntoventainfo}/>} onClick={Puntoventainfo}/>
        <BottomNavigationAction label="Dirección Punto de Venta" icon={<RoomIcon onClick={Direccionpuntoventa}/>} onClick={Direccionpuntoventa}/>
        
      </BottomNavigation>
    </Box>
    </div>
  
</div>
    </div>
    <br/>
      </Box> 
<div className="socioinfo" style={formbussnes===true?{"display":"block"}:{"display":"none"}}
>
<div className="grids01">
<div className="item0">
  {
  enables===true?
  <TextField
  label="Código Socio"
  required
  disabled={!bandera}
  value={codesocio}
  sx={{ m: 1.5, width: "100%" }}
  onChange={(event) => setcodesocio(event.target.value)}
  InputLabelProps={{ shrink: true }}
>
</TextField>:
<TextField
  label="Código Socio"
  disabled
  value={codesocio}
  sx={{ m: 1.5, width: "100%" }}
  InputLabelProps={{ shrink: true }}
></TextField>


}
</div>
<div className="item0ch">
<Checkbox
   checked={enables}
   disabled={!bandera}
   onChange={handleChange2}
   inputProps={{"aria-label": "primary checkbox"}}
   style={{ "paddingTop":"30px"}}
/>  
</div>

  <div className="items01">
    <TextField
      label="Socio de Negocios"
      required
      value={bussnespartner===null?"":bussnespartner}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setbussnespartner(event.target.value.toUpperCase().replace(/ +/g, " "))}
      InputLabelProps={{ shrink: true }}
      style={vsocio===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvsocio(false)}
      onBlur={
        bussnespartner===null || bussnespartner===" " || bussnespartner===''?()=>setvsocio(true):()=>setvsocio(false)
      }
    ></TextField>
     <p className="p_alert" style={vsocio===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
  <div className="items02">
    <TextField
      label="RFC"
      required
      value={rfc===null?"":rfc}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setrfc(event.target.value.slice(0,13).toUpperCase())}
      onBlur={verificarRFC}
      InputLabelProps={{ shrink: true }}
      style={vrfc===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvrfc(false)}

    ></TextField>
     <p className="p_alert" style={vrfc===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="items03">
   <TextField
    label="Línea de Crédito"
    type={"number"}
    value={balancepartner}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setbalancepartner(event.target.value)}
    InputLabelProps={{ shrink: true }}
  ></TextField>
  </div>
  <div className="items04">
   <TextField
    label="Días de Crédito"
    type={"number"}
    value={creditdays}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setcreditdays(event.target.value)}
    InputLabelProps={{ shrink: true }}
  ></TextField>
  </div>
</div>
<div style={
  moralfisica===true?{"display":"block"}:{"display":"none"}
}>
<div className="grids02">
  <div className="items05">
    <TextField
    label="Nombre del Representante Legal o Persona Física"
    required
    value={namepartner===null?"":namepartner}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setnamepartner(event.target.value.toUpperCase())}
    InputLabelProps={{ shrink: true }}
    style={vname===true?{
      "border":"2px solid red", "borderRadius":"5px"}:{}
    }
    onSelect={()=>setvname(false)}
    onBlur={
      namepartner==null || namepartner===""?()=>setvname(true):()=>setvname(false)
    }
  ></TextField>
   <p className="p_alert" style={vname===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="items06">
    <TextField
      label="Apellido Paterno del Representante Legal o Persona Física"
      required
      value={firstlastname===null?"":firstlastname}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setfirstlastname(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={vfname===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvfname(false)}
      onBlur={
        firstlastname==null || firstlastname===""?()=>setvfname(true):()=>setvfname(false)
      }
    ></TextField>
     <p className="p_alert" style={vfname===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  
  </div>
  <div className="items07">
    <TextField
      label="Apellido Materno del Representante Legal o Persona Física"
      required
      value={secondlastname===null?"":secondlastname}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setsecondlastname(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={vsname===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvsname(false)}
      onBlur={
        secondlastname==null || secondlastname===""?()=>setvsname(true):()=>setvsname(false)
      }
    ></TextField>
     <p className="p_alert" style={vsname===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
</div>
</div>
<div className="grids03">
  <div className="items08">
    <TextField
      select
      label="Tipo de Socio de Negocios"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setbusinesspartnertype(event.target.value)}  
    >
      <option key={0} value={"CLIENTE"} selected >
          {"CLIENTE"}
      </option>
      <option key={1} value={"PROVEEDOR"}>
          {"PROOVEDOR"}
      </option>
      <option key={2} value={"AMBOS"}>
          {"AMBOS"}
      </option>
    </TextField>
  </div>
  <div className="items09">
    <TextField
      select
      label="Clasificación del Cliente"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setpartnertype(event.target.value)}  
    >
      <option key={0} value={"PROPIO"} selected >
          {"PROPIO"}
      </option>
      <option key={1} value={"UNILIVER"}>
          {"UNILEVER"}
      </option>
      <option key={1} value={"EXTERNO"}>
          {"EXTERNO"}
      </option>
      
    </TextField>
  </div>
  <div className="items10">
    <TextField
      value={persontype}
      label="Tipo de Persona"
      sx={{ m: 1.5, width: "100%" }}
    >
    </TextField>
  </div>
  <div className="items11">
  <TextField
      select
      required
      label="Uso CFDI"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setcfdi(event.target.value)}  
      style={vcfdi===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvcfdi(false)}
      onBlur={
        cfdi===null?()=>setvcfdi(true):()=>setvcfdi(false)
      }  
    >
    {
      usoCFDI.map(item=>(
        <option key={item.value} value={item.value} >
          {item.label}
        </option>
    
      ))
    }
    </TextField>
   <p className="p_alert" style={vcfdi===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>


  </div> 
</div>
<div className="grids03">
  <div className="items12">
    <TextField
      label="Correo Electrónico"
      value={email===null?"":email}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setemail(event.target.value)}
      InputLabelProps={{ shrink: true }}
      style={vmail===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvmail(false)}
      onBlur={handleCorreo}
    ></TextField>
    <p className="p_alert" style={vmail===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
  <div className="items13">
    <TextField
      label="Teléfono(s)"
      value={phone}
      type={"number"}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setphone(event.target.value.slice(0,10))}
      InputLabelProps={{ shrink: true }}
      style={vphone===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvphone(false)}
      onBlur={
        phone==null?()=>setvphone(true):(phone.length<10?()=>setvphone(true):()=>setvtelefono(false))
      }
    ></TextField>
     <p className="p_alert" style={vphone===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
</div>
<div className="grids04">
  <div className="items14">
    <TextField
      select
      required
      label="Metodo de Pago"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setpaymethod(event.target.value)}  
      style={vmetodop===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvmetodop(false)}
      onBlur={
        paymethod==null?()=>setvmetodop(true):()=>setvmetodop(false)
      }>
    {
      metodoPago.map(item=>(
        <option key={item.value} value={item.value} >
          {item.label}
        </option>
      ))
    } 
  </TextField>
  <p className="p_alert" style={vmetodop===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
  <div className="items15">
    <TextField
      select
      label="Pago"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setmethodpay(event.target.value)}  
    >
    <option key={1} value={"PUE"} >
      {"PUE"}
    </option>
    <option key={2} value={"PPD"}>
      {"PPD"}
    </option>
  </TextField>
  </div>
  <div className="items16">
    <TextField
      label="Número de Cuenta"
      required
      type={"number"}
      value={accountnumber}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setaccountnumber(event.target.value.slice(0,4))}
      InputLabelProps={{ shrink: true }}
      style={vnumcuenta===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvnumcuenta(false)}
      onBlur={
        accountnumber==null?()=>setvnumcuenta(true):(accountnumber.length<4?()=>setvnumcuenta(true):()=>setvnumcuenta(false))
      }
    ></TextField>
     <p className="p_alert" style={vnumcuenta===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="items17">
    <TextField
      select
      label="Facturar en Automático"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setallowinvoicing(event.target.value)}  
    >
    <option key={1} value={false} >
      {"NO"}
    </option>
    <option key={2} value={true}>
      {"SI"}
    </option>
  </TextField>
</div>
</div>
<div className="grids50">
    <div className="items51">
      <TextField
        select
        label="Regimén fiscal"
        required
        value={tax_regime}
        InputLabelProps={{shrink: true,}}
        SelectProps={{native: true,}}
        sx={{ m: 1.5, width: "100%" }}
        onChange={(event) => settax_regime(event.target.value)}  
        style={vtax_regime===true?{
          "border":"2px solid red", "border-radius":"5px"}:{}
        }
        onSelect={()=>setvtax_regime(false)}
        onBlur={
          tax_regime===null||tax_regime===""?()=>setvtax_regime(true):()=>setvtax_regime(false)
        }
      >
      {
        dataregimen.map(item=>(
            <option key={item.index} value={item.value}>{item.value + " - " + item.label}</option>
        ))
      }
      </TextField>
      <p className="p_alert" style={vtax_regime===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  
    </div>
    <div className="items52">
    <TextField
       label="Regimen Capital"
       value={capital_regime===null?"":capital_regime}
       sx={{ m: 1.5, width: "100%" }}
       onChange={(event) => setcapital_regime(event.target.value)}
       InputLabelProps={{ shrink: true }}
       >
    </TextField>
  </div>
  </div>
  <h1 className="title-register-genral">DATOS SOLO SI ES UN CLIENTE EXTRANJERO</h1>
      <div className="grids51" >
      <div className="items53">
      <TextField
       label="Residencia Fiscal (País)"
       value={tax_residence}
       sx={{ m: 1.5, width: "100%" }}
       onChange={(event) => settax_residence(event.target.value)}
       InputLabelProps={{ shrink: true }}
       >
    </TextField>
    </div>
    <div className="items54">
      <TextField
        label="Número de Registro de Identificación Fiscal"
        value={num_reg_id_trib}
        sx={{ m: 1.5, width: "100%" }}
        onChange={(event) => setnum_reg_id_trib(event.target.value)}
        InputLabelProps={{ shrink: true }}
        >
      </TextField>
    </div>
  </div>
<br></br>
          <Box>
            <Button
              variant="contained"
              style={{"background-color":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={direccionbuss}
            >
              Siguiente
            </Button>
          </Box>
</div>

<div className="socioinfodir" style={formdirecb===true?{"display":"block"}:{"display":"none"}}
>
<div className="grids05">
  <div className="items18">
    <TextField
      label="Calle"
      value={street===null?"":street}
      required
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setstreet(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={
        vstreet===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvstreet(false)}
      onBlur={
        street==null?()=>setvstreet(true):()=>setvstreet(false)
      }
    ></TextField>
     <p className="p_alert" style={vstreet===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="items19">
    <TextField
      label="Número Exterior"
      value={outdoornumber===null?"":outdoornumber}
      required
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setoutdoornumber(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={
        voutdoor===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvoutdoor(false)}
      onBlur={
        outdoornumber==null?()=>setvoutdoor(true):()=>setvoutdoor(false)
      }
   ></TextField>
    <p className="p_alert" style={voutdoor===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
  </div>
  <div className="items20">
    <TextField
    label="Número Interior"
    value={insidenumber===null?"":insidenumber}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setinsidenumber(event.target.value)}
    InputLabelProps={{ shrink: true }}
  ></TextField>
  </div>
  <div className="items21">
    <TextField
      label="Colonia"
      value={neighborhood===null?"":neighborhood}
      required
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setneighborhood(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={
        vneigh===true?{"border":"2px solid red", "border-radius":"5px"}:{}
      }
      onSelect={()=>setvneigh(false)}
      onBlur={
        neighborhood==null?()=>setvneigh(true):()=>setvneigh(false)
      }
  ></TextField>
   <p className="p_alert" style={vneigh===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>  
</div> 
<div className="grids06">
  <div className="items22">    
    <TextField
      label="Localidad"
      required
      value={town}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => settown(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={
        vtown===true?{"border":"2px solid red", "border-radius":"5px"}:{}
      }
      onSelect={()=>setvtown(false)}
      onBlur={
        town===null?()=>setvtown(true):()=>setvtown(false)
      }
    ></TextField>  
     <p className="p_alert" style={vtown===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="items23">
    <TextField
      label="Municipio"
      value={city===null?"":city}
      required
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setcity(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
      style={
        vcity===true?{"border":"2px solid red", "border-radius":"5px"}:{}
      }
      onSelect={()=>setvcity(false)}
      onBlur={
        city===null?()=>setvcity(true):()=>setvcity(false)
      }
    ></TextField>
     <p className="p_alert" style={vcity===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p> 
  </div>
  <div className="items24">
    <TextField
      label="Código Postal"
      type={"number"}
      required
      value={zipcode===null?"":zipcode}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setzipcode(event.target.value.slice(0,5))}
      InputLabelProps={{ shrink: true }}
      style={
        vzip===true?{"border":"2px solid red", "border-radius":"5px"}:{}
      }
      onSelect={()=>setvzip(false)}
      onBlur={
        zipcode==null?()=>setvzip(true):()=>setvzip(false)
      }
    ></TextField>
     <p className="p_alert" style={vzip===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="items25">
  <TextField
      select
      required
      label="Estado"
      value={state}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setstate(event.target.value)}  
      style={
        vstate===true?{"border":"2px solid red", "border-radius":"5px"}:{}
      }
      onSelect={()=>setvstate(false)}
      onBlur={
        state==null?()=>setvstate(true):()=>setvstate(false)
      }
    >
      {
        estados.map(item =>(
          <option key={item.clave} value={item.clave}>
            {item.label}
          </option>
        ))
      }
    </TextField>
    <p className="p_alert" style={vstate===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>

</div>
<div className="grids07">
  <div className="items26">
    <TextField
      label="País"
      value={country}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setcountry(event.target.value)}
      InputLabelProps={{ shrink: true }}
    ></TextField>
  </div>
  <div className="items27">
    <TextField
      label="Referencia"
      sx={{ m: 1.5, width: "100%" }}
      value={reference}
      onChange={(event) => setreference(event.target.value.toUpperCase())}
      InputLabelProps={{ shrink: true }}
    ></TextField>
  </div>
  <div className="items28">
  <TextField
    selectUpdate_bussines_p
    label="Tipo de Dirección"
    defaultValue={""}
    InputLabelProps={{shrink: true,}}
    SelectProps={{native: true,}}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setaddresstype(event.target.value)}  
  >
    <option key={0} value={"ENTREGA"} selected >
        {"ENTREGA"}
    </option>
    <option key={1} value={"FISCAL"} selected >
        {"FISCAL"}
    </option>
  </TextField>
  </div>
  <div className="items29">
    <TextField
            select
            label="Sucursal"
            defaultValue={""}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setsubsidiary(event.target.value)}  
          >
            <option key={1} value={"MATRIZ"} selected >
                {"MATRIZ"}
            </option>
            <option key={2} value={"SUCURSAL"}>
                {"SUCURSAL"}
            </option>
      </TextField>    
  </div>
</div>

<br></br>
          <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={Puntoventainfo}
            >Siguiente
            </Button>
          </Box>
</div>

<div className="puntoventainfo" style={formper===true?{"display":"block"}:{"display":"none"}}
>
<div className="gridpg01">
  <div className="item1">
  {
  enable===true?
  <TextField
  label="Código"
  required
  value={codigo}
  sx={{ m: 1.5, width: "100%" }}
  onChange={(event) => setcodigo(event.target.value)}
  InputLabelProps={{ shrink: true }}
>
</TextField>:
 <TextField
  label="Código"
  value={codigo}
  disabled
  sx={{ m: 1.5, width: "100%" }}
></TextField>
}
</div>
<div className="itemch">
    <Checkbox
      checked={enable}
      onChange={handleChange}
      inputProps={{"aria-label": "primary checkbox"}}
      style={{"paddingRight":"20px", "paddingTop":"30px"}}
    />  
</div>
  <div className="item2">
    <TextField
      label="Nombre Punto de Venta"
      required
      value={nombre}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setnombre(event.target.value.toUpperCase().replace(/ +/g, " "))}
      style={
        vnombre===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvnombre(false)}
      onBlur={
        nombre==null?()=>setvnombre(true):()=>setvnombre(false)
      }
      InputLabelProps={{ shrink: true }}
    ></TextField>
  <p className="p_alert" style={vnombre===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="item3">
  <TextField
          label="Código Unilever"
          sx={{ m: 1.5, width: "100%" }}
          value={codigoU}
          onChange={(event) => setcodigou(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
        ></TextField>
  </div>
</div>
<div className="gridpg02">
<div className="item4">
   <TextField
        label="Número de tienda"
        value={numtienda}
        sx={{ m: 1.5, width: "100%" }}
        onChange={(event) => setnumtienda(event.target.value.toUpperCase())}
        InputLabelProps={{ shrink: true }}
      ></TextField>
</div>    
<div className="item5">
    <TextField
          label="Nota"
          value={nota}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnota(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
    ></TextField>
</div>
</div>

<div className="gridpg02">
<div className="item6">
    <TextField
        label="Correo Electrónico"
        required
        sx={{ m: 1.5, width: "100%" }}
        onChange={(event) => setcorreo(event.target.value)}
        InputLabelProps={{ shrink: true }}
        style={vcorreo===true?{
          "border":"2px solid red", "borderRadius":"5px"}:{}
        }
        onSelect={()=>setvcorreo(false)}
        onBlur={handleCorreo2}
      ></TextField>
      <p className="p_alert" style={vcorreo===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>   

</div>
<div className="item7">
<TextField
        label="Teléfono(s)"
        required
        sx={{ m: 1.5, width: "100%" }}
        value={telefono}
        type={"number"}
        onChange={(event) => settelefono(event.target.value.slice(0,10))}
        InputLabelProps={{ shrink: true }}
        style={vtelefono===true?{
          "border":"2px solid red", "borderRadius":"5px"}:{}
        }
        onSelect={()=>setvtelefono(false)}
        onBlur={
          telefono==null?()=>setvtelefono(true):(telefono.length<10?()=>setvtelefono(true):()=>setvtelefono(false))  
        }
      ></TextField>
      <p className="p_alert" style={vtelefono===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>   
</div>
</div>

<div className="gridpg03">
<div className="item8">
    <TextField
          select
          label="Genera Deuda"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setgdeuda(event.target.value)}  
        >
          <option key={1} value={true} selected >
              {"Si (Es Cliente Directo)"}
          </option>
          <option key={2} value={false} >
              {"No (Es Cliente Unilever)"}
          </option>
         </TextField>
</div>
<div className="item9"> 
    <TextField
          label="Límite de Crédito"
          type={"number"}
          value={0.00}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setlimcredito(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>
</div>
<div className="item10">
    <TextField
          label="Saldo"
          value={0.00}
          type={"number"}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setsaldo(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>
</div>
<div className="item11">
    <TextField
          select
          required
          label="Condición de Pago"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          style={
            vcondpago===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvcondpago(false)}
          onBlur={
            condpago==null?()=>setvcondpago(true):()=>setvcondpago(false)
          }
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setcondpago(event.target.value)}  
        >
          <option key={0} value={0} selected >
              {"Seleccione Condición de Pago"}
          </option>
          {
            datareq?.payConditions?.map((item) => (
              <option key={item.id} value={item.id} >
                {item.description}
              </option>
            ))
          }
         </TextField>
         <p className="p_alert" style={vcondpago===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
     
    </div>

</div>
<div className="gridpg04">

<div className="item12a">
  <TextField
          label="Socio de Negocio"
          value={bussnespartner}
          disabled
          sx={{ m: 1.5, width: "100%" }}
></TextField>                    
</div>
<div className="item13">
<TextField
            select
            required
            label="Días de Visita"
            defaultValue={""}
            style={
              vdiasvisit===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
            }
            onSelect={()=>setvdiasvisit(false)}
            onBlur={
              visit==null?()=>setvdiasvisit(true):()=>setvdiasvisit(false)
            }
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setvisit(event.target.value)}
            >
            <option key={0} value={0} selected >
                {"Seleccione Día"}
            </option>
            <option key={1} value={"LUNES"}>LUNES</option>
            <option key={2} value={"MARTES"}>MARTES</option>
            <option key={3} value={"MIERCOLES"}>MIERCOLES</option>
            <option key={4} value={"JUEVES"}>JUEVES</option>
            <option key={5} value={"VIERNES"}>VIERNES</option>
            <option key={6} value={"SABADO"}>SABADO</option>
            <option key={7} value={"DOMINGO"}>DOMINGO</option>
            </TextField>
            <p className="p_alert" style={vdiasvisit===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
     
</div>
<div className="item14">
<TextField
            select
            required
            label="Frecuencia de Visita"
            defaultValue={""}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setfrecvisit(event.target.value)}  
            style={vfrec===true?{
              "border":"2px solid red", "borderRadius":"5px"}:{}
            }
            onSelect={()=>setvfrec(false)}
            onBlur={
              frecvisit===null?()=>setvfrec(true):()=>setvfrec(false)
            }
         >
            <option key={0} value={0} selected >
                {"Seleccione Frecuencia"}
            </option>
            <option key={1} value={"SEMANAL"}>SEMANAL</option>
            <option key={2} value={"QUICENAL(1-3)"}>QUINCENAL(1-3)</option>
            <option key={3} value={"QUICENAL(2-4)"}>QUINCENAL(2-4)</option>

          </TextField>
          <p className="p_alert" style={vfrec===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
</div>
</div>
<div className="gridpg05">
  <div className="item15">
  <TextField
          select
          required
          label="Lista de Precios"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setlistprecios(event.target.value)}  
          style={vlistp===true?{
            "border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvlistp(false)}
          onBlur={
            listprecios==null?()=>setvlistp(true):()=>setvlistp(false)
          }
        >
          <option key={0} value={0} selected >
              {"Seleccione Lista de Precios"}
          </option>
          {
            datareq?.priceLists?.map((item) => (
              <option key={item.id} value={item.id} >
                {item.name}
              </option>
            ))
          }
         </TextField>
         <p className="p_alert" style={vlistp===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
  <div className="item16">
  <TextField
          select
          required
          label="Folios Especiales"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setfolioesp(event.target.value)} 
          style={vfolio===true?{
            "border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvfolio(false)}
          onBlur={
            folioesp==null?()=>setvfolio(true):()=>setvfolio(false)
          } 
        >
          <option key={0} value={0} selected >
              {"Seleccione Folio"}
          </option>
          {
            datareq?.specialFolios?.map((item) => (
              <option key={item.id} value={item.id} >
                {item.folio_name}
              </option>
            ))
          }
         </TextField>
         <p className="p_alert" style={vfolio===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
  <div className="item17">
  <TextField
          select
          required
          label="Canal"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setcanal(event.target.value) 
          && (event.target.value==="0")?"":setsubcanales(subcanalesf.filter(item=>item.id_channel_fk==event.target.value))
          }  
          style={vcanal===true?{
            "border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvcanal(false)}
          onBlur={
            canal===null?()=>setvcanal(true):()=>setvcanal(false)
          }
        >
          <option key={0} value={0} selected >
              {"Seleccione Canal"}
          </option>
          {
            datareq?.channels?.map((item) => (
              <option key={item.id} value={item.id} >
                {item.description}
              </option>
            ))
          }
         </TextField>
         <p className="p_alert" style={vcanal===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
  <div className="item18">
  <TextField
          select
          required
          label="Subcanal"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setsubcanal(event.target.value)}  
          style={vsubcanal===true?{
            "border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvsubcanal(false)}
          onBlur={
            subcanal===null?()=>setvsubcanal(true):()=>setvsubcanal(false)
          }
       >
          <option key={0} value={0} selected >
              {"Seleccione Subcanal"}
          </option>
          {
            subcanales.map((item) => (
              <option key={item.id} value={item.id} >
                {item.description}
              </option>
            ))
          }
         </TextField>
         <p className="p_alert" style={vsubcanal===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

  </div>
</div>
<div className="gridpg05">
  <div className="item19">
  <TextField
          select
          required
          label="Ruta"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setruta(event.target.value) && 
            (event.target.value==="100")?
            console.log("no entra"):setsectordata(sectordatafilter.filter(item=>item.id_route_fk==event.target.value))}  
          style={vruta===true?{
            "border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvruta(false)}
          onBlur={
            ruta==null?()=>setvruta(true):()=>setvruta(false)
          }
      
        >
          <option key={0} value={0} selected >
              {"Seleccione una Ruta"}
          </option>
          {
          routedata?.data?.parametersList?.map((item)=>(
          <option key={item.idRoute} value={item.idRoute}>
            {item.route}
          </option>
      ))
    }
         </TextField>
         <p className="p_alert" style={vruta===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
  </div>
  <div className="item20">
  <TextField
          select
          label="Sector"
          defaultValue={""}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setsector(event.target.value)}  
          onBlur={handlesectororden}
        >
          <option key={0} value={"vacio"} selected >
              {"Seleccione Sector"}
          </option>
          {
             sectordata?.map((item) => (
              <option key={item.id} value={item.id} >
                {item.number_sector+" - "+item.name_sector}
              </option>
            ))
          }
         </TextField>
  </div>
  <div className="item21">
  <TextField
        label="Orden del sector"
        type={"number"}
        value={ordensector===null?"":ordensector}
        sx={{ m: 1.5, width: "100%" }}
        onChange={(event) => setordensector(event.target.value)}
        InputLabelProps={{ shrink: true }}
        style={vordens===true?{
          "border":"2px solid red", "borderRadius":"5px"}:{}
        }
        onSelect={()=>setvordens(false)}
        onBlur={
          sector===null||sector===""||sector==="vacio"?"":ordensector===null||ordensector===""?()=>setvordens(true):()=>setvordens(false)
        } 
      ></TextField>
      <p className="p_alert" style={vordens===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  <div className="item21b">
  <TextField
      select
      required
      label="Clasificacion"
      defaultValue={""}
      InputLabelProps={{shrink: true,}}
      SelectProps={{native: true,}}
      sx={{ m: 1.5, width: "100%" }}
      onChange={(event) => setclasificacion(event.target.value)}  
      style={vclasificacion===true?{
        "border":"2px solid red", "borderRadius":"5px"}:{}
      }
      onSelect={()=>setvclasificacion(false)}
      onBlur={
        clasificacion==null?()=>setvclasificacion(true):()=>setvclasificacion(false)
      }  
    >
      <option key={0} value={0} selected >
          {"Seleccione clasificación"}
      </option>
      {
        clasificaciones?.map((item)=>(
          <option key={item.id} value={item.clasification}>
            {item.clasification}
          </option>
        ))
      }
    
    </TextField>
    <p className="p_alert" style={vclasificacion===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
  </div>

</div>          <br></br>
          <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={Direccionpuntoventa}
            >
              Siguiente
            </Button>
          </Box>
    </div>
<div className="puntoventainfodir" style={formdirec===true?{"display":"block"}:{"display":"none"}}
      >
        <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"gray"}}
              sx={{ m: 1.5, width: "60ch" }}
              onClick={Autocompletardir}
              disabled={!bandera}
            >
             Misma Dirección de Socio
            </Button>
          </Box>

<div className="gridpg06">
  <div className="item22">
    <TextField
    label="Latitud"
    value={lat}
    type="number"
    disabled={!bandera}
    required
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setlat(event.target.value)}
    style={
      vlat===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
    }
    onSelect={()=>setvlat(false)}
    onBlur={
      lat===null?()=>setvlat(true):()=>setvlat(false)
    }
    
    InputLabelProps={{ shrink: true }}
  ></TextField>
  <p className="p_alert" style={vlat===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
</div>
<div className="item23">
  <TextField
    label="Longitud"
    required
    disabled={!bandera}
    value={lng}
    type="number"
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setlng(event.target.value)}
    style={
      vlng===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
    }
    onSelect={()=>setvlng(false)}
    onBlur={lng===null?()=>setvlng(true):()=>setvlng(false)}
    InputLabelProps={{ shrink: true }}
  ></TextField>
  <p className="p_alert" style={vlng===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
</div>
<div className="item23bb">
            <img id="crearsector" src={Dir} onClick={handleClick} title="Buscar Dirección" alt=""
            ></img>
</div>
<div className="item24">
  <TextField
    label="Calle"
    required
    disabled={!bandera}
    value={calle}
    sx={{ m: 1.5, width: "100%" }}
    onChange={(event) => setcalle(event.target.value.toUpperCase())}
    style={
      vcalle===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
    }
    onSelect={()=>setvcalle(false)}
    onBlur={
      calle===null?()=>setvcalle(true):()=>setvcalle(false)
    }
    
    InputLabelProps={{ shrink: true }}
  ></TextField>
    <p className="p_alert" style={vcalle===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
  </div>
  </div>
      <div className="gridpg07">
          <div className="item25">
          <TextField
          label="Número Exterior"
          required
          disabled={!bandera}
          value={numext}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnumext(event.target.value)}
          style={
            vnumext===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvnumext(false)}
          onBlur={
            numext===null?()=>setvnumext(true):()=>setvnumext(false)
          }
          
          InputLabelProps={{ shrink: true }}
        ></TextField>
         <p className="p_alert" style={vnumext===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
          </div>
          <div className="item26">
          <TextField
          label="Número Interior"
          value={numint}
          disabled={!bandera}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnumint(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>
          </div>
          <div className="item27">
          <TextField
          label="Colonia"
          value={colonia}
          required
          disabled={!bandera}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setcolonia(event.target.value.toUpperCase())}
          style={
            vcolonia===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvcolonia(false)}
          onBlur={
            colonia===null?()=>setvcolonia(true):()=>setvcolonia(false)
          }
          
          InputLabelProps={{ shrink: true }}
        ></TextField>
         <p className="p_alert" style={vcolonia===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
          </div>
          <div className="item28">
          <TextField
          label="Localidad"
          required
          disabled={!bandera}
          value={local}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setlocal(event.target.value.toUpperCase())}
          style={
            vlocalidad===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvlocalidad(false)}
          onBlur={
            local===null?()=>setvlocalidad(true):()=>setvlocalidad(false)
          }
          InputLabelProps={{ shrink: true }}
        ></TextField>
         <p className="p_alert" style={vlocalidad===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
          </div>


      </div>
      <div className="gridpg08">
          <div className="item29">
          <TextField
          label="Municipio"
          required
          value={munic}
          disabled={!bandera}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setmunic(event.target.value.toUpperCase())}
          style={
            vmunicipio===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvmunicipio(false)}
          onBlur={
            munic===null?()=>setvmunicipio(true):()=>setvmunicipio(false)
          }
          InputLabelProps={{ shrink: true }}
        ></TextField>
         <p className="p_alert" style={vmunicipio===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
          </div>
          <div className="item30">
          <TextField
          label="Código Postal"
          type={"number"}
          value={cp}
          disabled={!bandera}
          required
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setcp(event.target.value)}
          style={
            vcp===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
          }
          onSelect={()=>setvcp(false)}
          onBlur={
            cp===null?()=>setvcp(true):()=>setvcp(false)
          }
          InputLabelProps={{ shrink: true }}
        ></TextField>
         <p className="p_alert" style={vcp===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
          </div>
          <div className="item31">
 
          <TextField
            select
            required
            disabled={!bandera}
            label="Estado"
            value={estado}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            style={
              vestado===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
            }
            onSelect={()=>setvestado(false)}
            onBlur={
              estado==null?()=>setvestado(true):()=>setvestado(false)
            }
            onChange={(event) => setestado(event.target.value)}  
          >
           
            {
              estados.map(item =>(
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))
            }

          </TextField>
          <p className="p_alert" style={vestado===true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
 
          </div>
          <div className="item32">
          <TextField
          label="País"
          disabled={!bandera}
          value={pais}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setpais(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>
          </div>
      </div>
      <div className="gridpg09">
        <div className="item33">
        <TextField
          label="Referencia"
          disabled={!bandera}
          sx={{ m: 1.5, width: "100%" }}
          value={refer}
          onChange={(event) => setrefer(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
        ></TextField>
        </div>
        <div className="item34">
        <TextField
            select
            disabled={!bandera}
            label="Sucursal"
            defaultValue={""}
            value={sucursal}
            InputLabelProps={{shrink: true,}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) => setsucursal(event.target.value)}  
          >
            <option key={1} value={"MATRIZ"} selected >
                {"MATRIZ"}
            </option>
            <option key={2} value={"SUCURSAL"}>
                {"SUCURSAL"}
            </option>
          </TextField>
        </div>
      </div>  
  <br>
    </br>
          <Box>
            {
              bandera===true?

              <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={enviarparametros}
            >
              Crear Registro General
            </Button>:
            <Button
              disabled
              variant="contained"
              style={{"backgroundColor":"green", "height":"35px"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={enviarparametros}
            ><Spinner/></Button>

            }
                      </Box>
      </div>                      
      <div>
      </div>
      </div>
    </div>
  );
}

export default RegisterGeneral;
