import React, { useEffect, useState } from "react";
import "./SearchBar.css";
import { Search, } from "@material-ui/icons";
import {  DoDisturbAlt, } from "@mui/icons-material";
import { ListItemButton } from "@mui/material";
import play from "../imgComponents/play.png";

function SearchBar({ placeholder = "Search ...", data = [] }) {

  const [color, setcolor] = useState("black");
  const [searchText, setSearchText] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);

  const arrLocalStorage = data;

  const handleOnBlur = () => {
    setcolor("black");
  }

  const handleOnFocus = () => {
    setcolor("#1853A7");
  }


  useEffect(() => {
    const filtered = arrLocalStorage.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase()) 
      || item.parent.toLowerCase().includes(searchText.toLowerCase() );
    });
    const grouped = filtered.reduce((acc, curr) => {
      const parent = curr.parent;
      if (!acc[parent]) {
        acc[parent] = [];
      }
      acc[parent].push(curr);
      return acc;
    }, {});
    const result = Object.keys(grouped).map((key) => {
      return {
        parent: key,
        childs: grouped[key],
      };
    });
    
    //Filtrar secciones sin hijos visibles
    const filteredResult = result.filter(item => {
      return item.childs.some(child => { 
        return (child.module_restriccion.filter( valor => valor !== "none").length > 0 && child.restriccion !== "none");
      });
    });
  
    setFilteredArray(filteredResult);
  }, [searchText]);
  

  function handlevideo(value){
    console.log(value.id);
    localStorage.setItem("id_video_busqueda",value.id);
    localStorage.setItem("ban_video_busqueda", 0);
    window.location.href = "/consult_capacitation";
  }


  return (
  <>
    <div onBlur={ handleOnBlur } 
        onFocus={ handleOnFocus } 
        style={{
          border: `1px solid ${color}`,
          background: color === "black" ? "white" : "#E5FAFA",
          color: color
       }} 
          className="container_search">
      <Search className="icon_search"/>
      <input
        className="barra"
        type="search"
        placeholder={placeholder}
        style={{
                borderLeft:`1px solid ${color}`, 
                color: color, 
                 }}
        onChange={(event)=>{
          setSearchText(event.target.value);
        }}
        />
    </div>
   <div style={{ 
          position: "absolute",
          display: searchText !== "" ? "block" : "none",
          backgroundColor: "#1a374d",
          color: "black",
          padding: "0 10px 10px 10px",
          minWith: "400px",
          width: "100%",
          height: "auto",
          borderRadius: "0 0 10px 10px",
          border: "1px solid gray",
          maxHeight: "600px",
          overflowY: "auto",
        }} 
        className="result tour-opciones-input"
        id="tour-opciones-input"
        >

         {
            filteredArray.length > 0 ?
            filteredArray.map( (item, index) => {
             
              return(
                <div onFocus={ handleOnFocus } 
                  key={index} 
                  style={{ 
                  color: "black", 
                   marginBottom:"10px", borderRadius: "5px"}} className="section">
                  <ListItemButton 
                          key={item.parent} 
                          onFocus={ handleOnFocus }
                          style={{ 
                            whiteSpace:"nowrap",
                            color: "white", 
                            fontSize: "1.2em",
                            marginBottom: "1px",
                            borderRadius: "15px",
                            }}>
                           {item.parent} {" "} 
                  </ListItemButton>

                    <ul onFocus={ handleOnFocus }
                          style={{ listStyleType: "none", margin: 0 }}>
                      { item.childs.map( (valor) => {

                          return(<li 
                                    key={ valor.name } 
                                    style={{
                                      display: valor.restriccion,
                                      color: "white", 
                                      padding: "10px", 
                                      margin: "5px 0", 
                                      borderRadius: "15px", 
                                      backgroundColor: "#214A5C" }}>
                                  <a href={ `/${valor.ruta}` }
                                    id="tour-123"
                                    className="tour-123"
                                  >
                                   {valor.icono} <span style={{fontSize: "1.2em", fontFamily: 1}}>{`${valor.name}`.toString()}
                                   </span>
                                  </a>
                                    
                                  <img 
                                    className="reproductor-search tour-playvideo"
                                    id="tour-playvideo"
                                    src={play}
                                    style={{width: "40px",height: "40px", float:"right", cursor:"pointer"}}
                                    alt=""
                                    title="Capacitación del módulo"
                                    onClick={()=>handlevideo(valor)}
                                   ></img>
                                </li>)
                      })}
                    </ul>
                </div>
                
              )})
            
            :
            <ListItemButton onFocus={ handleOnFocus }
                          style={{ 
                            whiteSpace:"nowrap",
                            color: "white", 
                            fontSize: "1.2em",
                            width: "100%",
                            left:"0%",
                            right:"0%",
                            }}>
                         <span><strong>No hay resultados ... <DoDisturbAlt style={{color: "white"}}/></strong></span>
                  </ListItemButton>
          }

        </div>
  </>
  );
}

export default SearchBar;
