//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./annual_s.css";
import TextField from "@mui/material/TextField";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { get_semaphore } from "../../services/reports/reports";
import { pagination } from "../../main_components/pagination/pagination";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
 
const Consult_annual_summary = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [datasemaforo, setdatasemaforo]=useState([]);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [yy, setyy]=useState(null);
  const [vyy, setvyy]=useState(false);
function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


useEffect(() => {
  handlesemaforo();
}, [])


const handlesemaforo=async()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          var d = await get_semaphore();
          const semaphores = (d === null ? [] : d.data.semaphores);
          const updatedSemaphores = semaphores.map(item => ({
            ...item,
            minimum: item.minimum * 12,
            max: item.max * 12
          }));
          setdatasemaforo(updatedSemaphores);
          //setdatasemaforo(d === null ? [] : d.data.semaphores);  
        }catch(err){
          console.log(err);
          setdatasemaforo([]);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}

const handleSubmit = async () => {

if(yy===null || yy===''){
  alertas("Parámetros incompletos", false);
  return null;
}
if(vyy===true){
   alertas("Año no admitido", false);
   return null;
}
      
    setmodalT(true);
    setopen(true);
    setData([]);

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await pagination(3, "sumary", yy);
            const ndata = d.flatMap((result) => result?.report || []);
            console.log(ndata);
            sumatotales(ndata===null?[] : ndata);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
};

const sumatotales = (n)=>{  
  console.log(n);
 
  const newData = n.map(item => {
    let sumaTotal = 0;
    for (let month in item) {
      if (month !== 'code_pos' && month !== 'id_pos' && month !== 'deleted_logical_pos' && month !== 'id_route' 
      && month !== 'name_pos' && month !== 'name_route'
      ) {
        sumaTotal += item[month];
      }
    }
  
    return {
      ...item,
      sumaTotal: (sumaTotal).toFixed(2),
      promedio: (sumaTotal / 12).toFixed(2),
    };
  });

  setData(newData);
  console.log(newData);
}

const handleyy = (event)=>{

  if(event.target.value<=2030 && event.target.value>=2010){
    setvyy(false);
  }else{
    setvyy(true);
  }
}
const columns = useMemo(
  () => [
    {
      Header: "Código",
      accessor:"code_pos",
    },
    {
      Header:"Vigente",
      accessor:(d)=>{
        return d.deleted_logical_pos===true?"NO":"SI";
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: "Punto de Venta",
      accessor:"name_pos"
    },
    {
      Header: "Ruta",
      accessor: "name_route"
    },
    {
      Header: "Enero",
      accessor: (d) => {
             return d.Enero
               ? Intl.NumberFormat("es-MX", {
                   style: "currency",
                   currency: "MXN",
                 }).format(d.Enero)
               : Intl.NumberFormat("es-MX", {
                   style: "currency",
                   currency: "MXN",
                 }).format(d.Enero);
           },    
    },
    {
      Header: "Febrero",
      accessor: (d) => {
        return d.Febrero
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Febrero)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Febrero);
      },   
    },
    {
      Header: "Marzo",
      accessor: (d) => {
        return d.Marzo
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Marzo)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Marzo);
      },   
    },
    {
      Header: "Abril",
      accessor: (d) => {
        return d.Abril
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Abril)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Abril);
      },   
    },
    {
      Header: "Mayo",
      accessor: (d) => {
        return d.Mayo
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Mayo)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Mayo);
      },   
    },
    {
      Header: "Junio",
      accessor: (d) => {
        return d.Junio
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Junio)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Junio);
      }, 
    },
    {
      Header: "Julio",
      accessor: (d) => {
        return d.Julio
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Julio)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Julio);
      },       
    },
    {
      Header: "Agosto",
      accessor: (d) => {
        return d.Agosto
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Agosto)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Agosto);
      },   
    },
    {
      Header: "Septiembre",
      accessor: (d) => {
        return d.Septiembre
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Septiembre)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Septiembre);
      },   
    },
    {
      Header: "Octubre",
      accessor: (d) => {
        return d.Octubre
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Octubre)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Octubre);
      },   
    },
    {
      Header: "Noviembre",
      accessor: (d) => {
        return d.Noviembre
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Noviembre)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Noviembre);
      },
    },
    {
      Header: "Diciembre",
      accessor: (d) => {
        return d.Diciembre
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Diciembre)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.Diciembre);
      },
    },
    {
      Header: "Total",
      accessor: (d) => {
        return d.sumaTotal
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sumaTotal)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.sumaTotal);
      },
    },
    {
      Header: "Semaforo",
      accessor: (d)=>{
        const item = datasemaforo.find((item) => d.sumaTotal >= (item.minimum) && d.sumaTotal <= (item.max));
       // console.log(item);
        return (item ? <div className="semaforo-celda" style={{"backgroundColor": item.color_hex}}/>:"Sin semáforo")
      }
    }
    ],
    [datasemaforo]
);

return (
    <>
<div className="TableSummaryAnual">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>
    <BREADCRUMBS niveles={
      [
        {label:"REPORTE SUMARIA ANUAL", path: null}, 
      ]
    }
    ></BREADCRUMBS>
    
    <div className="gridann">
      <div className="ann2">
        <TextField
            label="Año"
            value={yy}
            type={"number"}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) =>{setyy(event.target.value)}} 
            onBlur={handleyy}
            InputLabelProps={{ shrink: true }}
        ></TextField>

        
      </div>
      <div className="ann3">
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>


      </div>
    </div>
      <div className="tableanual">            
        <StickyTable>
            <TableContainer
              paginado={"Ventas"}
              consultas={"Ventas Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              Grantotal={true}
              GrantotalCabeceras={["vigente", "Punto de Venta", "Ruta", "Semaforo"]}
              />
          </StickyTable>
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default Consult_annual_summary;
