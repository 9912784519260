import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Delete from "../../imgComponents/borrar.png";
import Flecha from "../../imgComponents/agregarRole.png";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_company_roles, post_user } from "../../services/configuration/configuration";

function CreateUser(props) {

const dialog = useDialog();
const [name, setname]=useState(null);
const [username, setusername]=useState(null);
const [telefono, settelefono]=useState(null);
const [puesto, setpuesto]=useState(null);
const [correo, setcorreo]=useState(null);
const [bandera, setbandera]=useState(true);
const [elementos, setElementos] = useState([]);
const [elementosArrastrados, setElementosArrastrados] = useState([]);
const [value, setValue] = useState(0);
const [vname, setvname]=useState(false);
const [vusername, setvusername]=useState(false);
const [vtelefono, setvtelefono]=useState(false);
const [vpuesto, setvpuesto]=useState(false);
const [vphone, setvphone]=useState(false);
const [vcorreo, setvcorreo]=useState(false);
const [testcorreo, settestcorreo]=useState(null);
const [elementosFiltrados, setElementosFiltrados] = useState([]);
const [elementosFiltrados2, setElementosFiltrados2]=useState([]);
const [searchTerm, setSearchTerm] = useState('');
const [searchTerm2, setSearchTerm2]=useState('');

useEffect(()=>{
  handleSubmit();
},[]);

//proceso de consultar todos los ROLES
const handleSubmit = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_company_roles();
          setElementos(d === null ? [] : d.data.company_roles);
          setElementosFiltrados(d === null ? [] : d.data.company_roles);   
        }catch(err){
          console.log(err);
          setElementos([]);
        }        
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
};

// Creamos las funciones para manejar el drag and drop
const handleOnDragStart = (event, item) => {
  // Almacenamos el elemento en el estado "elementosArrastrados"
  setElementosArrastrados((prevState) => [...prevState, item]);
  setElementosFiltrados2((prevState) => [...prevState, item]);
  setElementos((prevState) => prevState.filter((i) => i.id !== item.id));
  setElementosFiltrados((prevState) => prevState.filter((i) => i.id !== item.id));
  
};

const handleOnDrop = (event) => {
  // Eliminamos el elemento del estado "elementosArrastrados"
  setElementosArrastrados([]);
  setElementosFiltrados2([]);
};

const handleOnDragOver = (event) => {
  // Evitamos que el elemento sea movido por defecto
  //event.preventDefault();
};

const handleRemoveItem = (item) => {
  setElementosArrastrados((prevState) =>
    prevState.filter((i) => i.id !== item.id)
  );
  setElementosFiltrados2((prevState)=>
    prevState.filter((i)=>i.id !==item.id)
  )
  // Añadimos el elemento a la lista izquierda
  setElementos((prevState) => [...prevState, item]);
  setElementosFiltrados((prevState)=>[...prevState, item]);
};

const handleSearchInputChange = (event) => {
  if(event.target.value==='' || event.target.value===' '){
    setSearchTerm(event.target.value);
    setElementos(elementosFiltrados);
  }else{
    setSearchTerm(event.target.value);
    const filteredElements = elementosFiltrados.filter((item) => item.name_role.toLowerCase().includes(searchTerm.toLowerCase()))
    setElementos(filteredElements);  
  }
};

const handleSearchInputChange2 = (event) => {
  if(event.target.value==='' || event.target.value===' '){
    setSearchTerm2(event.target.value);
    setElementosArrastrados(elementosFiltrados2);  
  }else{
    setSearchTerm2(event.target.value);
    const filteredElements = elementosFiltrados2.filter((item) => item.name_role.toLowerCase().includes(searchTerm2.toLowerCase()))
    setElementosArrastrados(filteredElements);  
  }
};

const createuser = async ()=>{
var nameroles=elementosFiltrados2.map(role=>role.name_role);

if(name==null || username==null || puesto==null || correo==null || telefono==null){
  props.alertas("Parámetros faltantes", false);
}else{

if(nameroles.length==0){
  props.alertas("Debe asignar por lo menos un rol", false);
}else{
  props.reconsultar(true);
  setbandera(false);
  
    var data = {
      "username": username,
      "email": correo,
      "fullusername": name,    
      "phone" : telefono,
      "job": puesto,
      "roles": nameroles
  }
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          const dt = await post_user(data);
          console.log(dt);
          props.alertas("Usuario registrado con éxito", true);
          props.reconsultar(false); 
          dialog.close();
          props.handleSubmit(); 
        }catch(err){
          console.log(err);
          catchErrorModul("Usuarios", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          setbandera(true);
          if(err.response.status===500){
            props.alertas("Error 500: No se pudo crear el usuario, vuelva a intertarlo", false);
          }else{
            props.alertas("No se pudo crear el usuario, vuelva a intertarlo", false);  
          }
          props.reconsultar(false);
        }    
      }, 1000);
    }else{}
  } catch (err) {  
    console.log(err);  
  };
  }
 }
}
 
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}
function IsMail(email){
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/;
  if(regex.test(email)===true)
    {
      setvcorreo(false); 
      settestcorreo(0);
    }
    else{
      setvcorreo(true);
    }
}

const [infousuarios, setinfousuarios]=useState(true);
const [inforoles, setinforoles]=useState(false);
function seccionrol(){
   setinforoles(false);
   setinfousuarios(true);
}
function seccionusuario(){

name==null?setvname(true):setvname(false);
username==null?setvusername(true):setvusername(false);
telefono==null?setvtelefono(true):setvtelefono(false);
puesto==null?setvpuesto(true):setvpuesto(false);
testcorreo==null?setvcorreo(true):setvcorreo(false);

  if(name==null || username==null || telefono==null || puesto==null || testcorreo==null){
    props.alertas("Valores faltantes", false);
  }else{
    setinforoles(true);
    setinfousuarios(false);
  }
   
}

  return (
    <div>
      <div className="adduser">
    <br/>
    <div className="pestañasusuarios">
    
    <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction style={infousuarios===false?{"backgroundColor":"#E7E7E7"}:{}} label="Información General" icon={<PersonIcon onClick={seccionrol} />}  onClick={seccionrol}/>
        <BottomNavigationAction style={inforoles===false?{"backgroundColor":"#E7E7E7"}:{}} label="Permisos" icon={<ListAltIcon onClick={seccionusuario}/>} onClick={seccionusuario}/>
        
      </BottomNavigation>
    </Box>
    </div>
    </div>
      <div className="infouser" style={infousuarios===true?{"display":"block"}:{"display":"none"}}>
      <div className="griduser1">
          <div className="user1">
           <TextField
              label="Nombre"
              value={name}
              required
              onSelect={()=>setvname(false)}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setname(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="vericorreo" style={vname===true?{"display":"block"}:{"display":"none"}}>Completa este campo</p>
         
          </div>
          <div className="user2">
          <TextField
              label="Nombre de Usuario"
              required
              value={username}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setusername(event.target.value)}  
              onSelect={()=>setvusername(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="vericorreo" style={vusername===true?{"display":"block"}:{"display":"none"}}>Completa este campo</p>
         
          </div>
        </div>
        <div className="griduser2">
          <div className="user3">
             <TextField
              label="Número Telefónico"
              value={telefono}
              type={"number"}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settelefono(event.target.value.slice(0,10))}  
              onSelect={()=>{
                setvphone(true);
                setvtelefono(false);
              }}
              onBlur={()=>setvphone(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="veriphone" style={vphone===true?{"display":"block"}:{"display":"none"}}>Ingresa el Número Teléfonico sin utilizar guiones</p>
            <p className="vericorreo" style={vtelefono===true?{"display":"block"}:{"display":"none"}}>Completa este campo</p>
         
          </div>
          <div className="user4">
            <TextField
              label="Puesto"
              required
              value={puesto}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setpuesto(event.target.value.toUpperCase())}  
              onSelect={()=>setvpuesto(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="vericorreo" style={vpuesto===true?{"display":"block"}:{"display":"none"}}>Completa este campo</p>
         
          </div>
        </div>
        <div className="griduser3">
        <div className="user5">
          <TextField
              label="Correo"
              value={correo}
              onSelect={()=>setvcorreo(false)}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcorreo(event.target.value)}  
              onBlur={()=>{
                IsMail(correo)
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="vericorreo" style={vcorreo===true?{"display":"block"}:{"display":"none"}}>Por favor, verifique que el correo electrónico ingresado sea válido</p>
          </div> 
          
        </div>
        <br></br>
          <Box>  
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={seccionusuario}
          >
            Siguiente
          </Button>
          </Box>
      </div>
      <div className="infouserroles" style={inforoles===true?{"display":"block"}:{"display":"none"}}>
      <div className="drag-and-drop-user">
      {/* Mostramos los elementos en la lista izquierda */}
      <div className="lista-izquierda-drag">
      <div className="contenedorcabecera">
        <h2 className="tituloRoles">Roles Disponibles</h2>

        {/* buscador  */}
        <div className="buscador-roles">
          <input
            type="text"
            placeholder="Buscar Roles"
            value={searchTerm}
            onChange={(event)=>handleSearchInputChange(event)}
          />
        </div>
      </div>
        {elementos.map((item) => (
          <div
            title={item.description}
            key={item.id}
            className="item-drag"
            draggable
            //onClick={(event) => handleOnDragStart(event, item)}
          >
            <div className="itemnamerole">      
            {item.name_role}
            </div>
            <div className="imagitem">
            <img
              src={Flecha}
              onClick={(event) => handleOnDragStart(event, item)}     
              alt=""         
            ></img>
            </div>

            
          </div>
        ))}
      </div>
      {/* Mostramos los elementos arrastrados en la lista derecha */}
      <div
        className="lista-derecha-drag"
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
      >
        <div className="contenedorcabecera2">
         <h2 className="tituloRoles">Roles Habilitados</h2>        
         <div className="buscador-roles">
          <input
            type="text"
            placeholder="Buscar Roles"
            value={searchTerm2}
            onChange={(event)=>handleSearchInputChange2(event)}
          />
        </div>
         </div>       
        {elementosArrastrados.map((item) => (
          <div key={item.id} className="item-drop" title={item.description}>
            <img
              src={Delete}
              onClick={() => handleRemoveItem(item)} alt=""                
            ></img>
            {item.name_role}
          </div>
        ))}
      </div>
  </div>
  <br/>
  <Box>
      {
  bandera===true?
      <Button
      variant="contained"
      style={{"background-color":"green"}}
      sx={{ m: 1.5, width: "30ch" }}
      onClick={createuser}
    >
      Crear Usuario
    </Button>:
    <Button
    variant="contained"
    disabled
    style={{"background-color":"green", "height":"35px"}}
    sx={{ m: 1.5, width: "30ch" }}
  ><Spinner/></Button>
    }
    
    <Button
      style={{"background-color":"red"}}
      variant="contained"
      disabled={!bandera}
      sx={{ m: 1.5, width: "20ch" }}
      onClick={() => {
          dialog.close();
        } 
      }
    >
    cancelar
  </Button>
  </Box>

</div>
        <br/>

          
      </div>
    </div>
  );
}

export default CreateUser;
