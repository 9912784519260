import React, { useState, useEffect } from 'react';

const CountdownClock = ({ expirationDateAcces }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Calcula el tiempo restante y actualiza el estado
  function calculateTimeRemaining() {
    

    console.log(expirationDateAcces);


    const currentTime = new Date();
    const timeDifference = expirationDateAcces.getTime() - currentTime.getTime();
    const totalSecondsRemaining = Math.floor(timeDifference / 1000);

    if (totalSecondsRemaining <= 0) {
      // Si el tiempo ha expirado, retorna 0
      return 0;
    }

    const hoursRemaining = Math.floor(totalSecondsRemaining / 3600);
    const minutesRemaining = Math.floor((totalSecondsRemaining % 3600) / 60);
    const secondsRemaining = totalSecondsRemaining % 60;

    return hoursRemaining * 3600 + minutesRemaining * 60 + secondsRemaining;
  }

  // Actualiza el tiempo restante cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining();
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Convierte el tiempo restante a formato de reloj HH:MM:SS
  function formatTimeRemaining() {
    const hours = Math.floor(timeRemaining / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((timeRemaining % 3600) / 60).toString().padStart(2, '0');
    const seconds = (timeRemaining % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <div className='tiempo-restante'>
      {formatTimeRemaining()}
    </div>
  );
};

export default CountdownClock;