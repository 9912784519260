import Vppd from "../../components_cfdi/invoice/consult_invoice/consult_invoice_PPD";
import { Component } from "react";

export class SetfacturaPPD extends Component {

  render() {
    return (
      <div>
        <Vppd></Vppd>
      </div>
    );
  }
}

export default SetfacturaPPD;
