import axios from "axios";
import config from "../../config";

const urlreport = `${config?.baseURLREPORT}`;
const urlbp = `${config?.baseURLCONF}`;


//////////////////// C R U D   D E   M E T A S /////////////////////

//consultar todas las metas
export const get_goals = async (yy, mm) => {
  const url = `${urlreport}/prod/goals`;
  try {
    const response = await axios.get(url, {
      params:{year: yy ,month: mm
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar meta
export const delete_goal = async (id) => {
  const url = `${urlreport}/prod/goals/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear META
export const post_goals = async (data) => {
  const url = `${urlreport}/prod/goals`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//ACTUALIZAR META
export const put_goals = async (id, data) => {
  const url = `${urlreport}/prod/goals/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//////////////////// C R U D   D E   S E M A F O R O ////////////////////////


//consultar semaforos
export const get_semaphore = async () => {
  const url = `${urlreport}/prod/semaphore`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//ELIMINAR SEMAFORO
export const delete_semaphore = async (id) => {
  const url = `${urlreport}/prod/semaphore/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//CREAR SEMAFORO
export const post_semaphore = async (data) => {
  const url = `${urlreport}/prod/semaphore`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//ACTUALIZAR SEMAFORO
export const put_semaphore = async (id, data) => {
  const url = `${urlreport}/prod/semaphore/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

////////////// R E P O R T E     G P S ///////////////////////////

//CONSULTAR DATA REPORTE GPS
export const get_report_gps = async () => {
  const url = `${urlreport}/prod/reporte-gps/route`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

///////////// REPORTE DE VENTAS POR RUTA //////////////////////////
//una ruta en especifico
export const get_sales_by_route_ind = async (nameroute, fi, ff) => {
  const url = `${urlreport}/prod/sales-by-route`;
  try {
    const response = await axios.get(url, {
      params:{route: nameroute,startDate: fi, finalDate: ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//todas las rutas
export const get_sales_by_route_all = async (fi, ff) => {
  const url = `${urlreport}/prod/general-routes-report`;
  try {
    const response = await axios.get(url, {
      params:{startDate: fi, finalDate: ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//consulta sumario anual
export const get_sumary_annual = async (yy) => {
  const url = `${urlreport}/prod/annual-summary-by-pos`;
  try {
    const response = await axios.get(url, {
      params: { year: yy },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


///////////////////REPORTE DE VENTAS EN TIEMPO REAL ///////////////7777

export const get_sales_in_real_time = async (nameroute, fecha) => {
  const url = `${urlreport}/prod/sales-in-real-time`;
  try {
    const response = await axios.get(url, {
      params: {route: nameroute, date: fecha},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};






/////////////////// DAILY STAFF ///////////////////////////////////

export const get_daily_staff = async (di, df) => {
  const url = `${urlreport}/prod/daily_staff`;
  try {
    const response = await axios.get(url, {
      params: {di: di, df: df},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const get_daily_staff_user = async (di, df) => {
  const url = `${urlbp}/dev/route/general_information`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const delete_daily_staff = async (id) => {
  const url = `${urlreport}/prod/daily_staff/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const create_daily_staff = async (dt) => {
  const url = `${urlreport}/prod/daily_staff`;
  try {
    const response = await axios.post(url,dt,{
      
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update_daily_staff = async (dt,id) => {
  const url = `${urlreport}/prod/daily_staff/${id}`;
  try {
    const response = await axios.put(url,dt,{
      
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//////////////////// C R U D   D E   B O N I F I C A C I O N E S/////////////////////



export const get_detail_bonus = async (fi, ff ,selectRutas ,productBonus , poductNote,page,id_detail) => {
  const url = `${urlreport}/prod/dashboard1/bonus/${id_detail?.replaceAll(' ', '_')?.replaceAll("/","-")}`;
  try {
    const response = await axios.get(url, {
      params: {
        datei: fi,
        datef: ff,
        route: selectRutas,
        filtro:productBonus,
        note:poductNote,
        page:page
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
