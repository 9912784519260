//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import {get_movements} from "../../services/inventory/inventory";
import { get_warehouse } from "../../services/inventory/inventory";
import { get_warehouse_existence } from "../../services/inventory/inventory";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
const STOCK = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  const [warehouse, setwarehouse] = useState([]);
  const [warehouse_name, setwarehouse_name] = useState(1);

  const handle_warehouse = async () => {
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try{
              const dt= await get_warehouse();
              const data=dt.data;
                var dtAdd = {
                  id: -1,
                  warehouse_name: "ENTRADA",
                };
              data.push(dtAdd);
              setwarehouse(data);
              handleSubmit();
            }catch(err){
              setwarehouse([]);
            }
          }, 1000); 
        }
      } catch (err) {
        console.log(err);
      }
  };


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    setData([]);

      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try{
              const dt = await get_warehouse_existence(warehouse_name);
              setData( dt.data.sort((a, b) => b.quantity - a.quantity ));
              setOpen(false);
            }catch(err){
              setData([]);
              setMenssage("");
            }
          }, 1000); 
        }
      } catch (err) {
       console.log(err);
      }
  };

 
  useEffect(() => {
    handle_warehouse();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = useMemo(
    () => [
    
    
      {
        id: "code",
        Header: "Código",
        accessor:"code"
      },
      {
        id: "description",
        Header: "Descripción",
        accessor:"description"
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
     
    ],
    []
  );

  return (
    <div className="">
      <div>
        
      </div>
      <BREADCRUMBS niveles={
            [
              {label: "CONSULTAR ALMACEN DE PRODUCTOS", path: null},
            ]
          }
      ></BREADCRUMBS>
      <br></br>
      <div className="containerStock">
        <div className="pdc-1 color-1">
        <TextField
                select
                label="Origen"
                child
                value={warehouse_name}
                sx={{ m: 1.5, width: "90%"}}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
                onChange={(e)=>setwarehouse_name(e.target.value)}

              >
                {warehouse?.map((option) => (
                  <option
                    key={option?.id}
                    value={option?.id}
                    selected={option?.id}
                  >
                    {option?.warehouse_name}
                  </option>
                ))}
              </TextField>
        </div>

      

        <div className="pdc-2 existProduct">
          <Button
            sx={{ width: "100%"}}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="TableTaxes">
        <div className="">
          <TableContainer
            columns={columns}
            exportar={true}
            data={data}
            Gmodal={open}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTableContainer />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default STOCK;
