import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_route_list } from "../../../services/businesspartners/businesspartners";
import { post_goals } from "../../../services/reports/reports";


function CreateGoal(props) {

  useEffect(() => {
    ConsutarRutas();
  }, [])
  
const dialog = useDialog();
const[idroute, setidroute]=useState(null);
const[meta, setmeta]=useState(null);
const[my, setmy]=useState(null);
const[vid, setvid]=useState(false);
const[vmeta, setvmeta]=useState(false);
const[bandera, setbandera]=useState(true);
const[dataroute, setdataroute]=useState([]);

//consultar data de rutas
const ConsutarRutas = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setdataroute(d === null ? [] : d);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};

//crear un nuevo Rol
const createnewdiscount = async () => {

  var year = null;
  var month = null;

  if(my===null){
    
  }else{
    const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
    year = parseInt(partes[0]); // Convierte el año en un número entero
    month = parseInt(partes[1]); // Convierte el mes en un número entero
  }
  
if(idroute===null || idroute==="" || idroute==="vacio" || meta===null ||meta==="" || year === null || month=== null||my===null){
  props.alertas("Parámetros faltantes", false);
  idroute===null||idroute===""||idroute==="vacio"?setvid(true):setvid(false);
  meta===null||meta===""?setvmeta(true):setvmeta(false);
}else{

props.reconsultar(true);
setbandera(false);
  var data = {
    "goal": meta,//Float = Indica la meta que tendrá la ruta
    "id_route": parseInt(idroute),//Int = Indica el ID de la ruta a la que hará referencia
    "year": year,//Int = Año 2023
  	"month": month,//Int = Mes 1 - 12
  }
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await post_goals(data);
            console.log(dt);
            props.alertas("Meta creada con éxito", true);
            props.setactualizacionmeta(true);
            props.setfiltrometas(false);
            props.reconsultar(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log(err);
            catchErrorModul("Metas", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            setbandera(true);
            props.reconsultar(false);
            if(err.response.status===500){
              props.alertas("Error 500: No se pudo crear la meta, vuelva a intertarlo", false);
            }else if(err?.response?.status===409){
              props.alertas("Error 409: La ruta tiene una meta ya asignada");
            }else{
              props.alertas("No se pudo crear la meta, vuelva a initentarlo", false);
            }
          }
        }, 1000);
      }else{}
    } catch (err) {  
      console.log(err);
    };
  }
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
        <div className="grid-goal">
          <div className="goal1">
          <TextField
            select
            label="Ruta"
            defaultValue={""}
            InputLabelProps={{shrink: true}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%"}}
            onChange={(event) => setidroute(event.target.value)}
            style={
              vid===true?{"border":"2px solid red", "border-radius":"5px"}:{}
            }
            onSelect={(event)=>setvid(false)}
            onBlur={
              idroute===null||idroute===""?(event)=>setvid(true):(event)=>setvid(false)
            }
        >
        <option key={0} value={"vacio"} selected>
          {"Seleccione ruta"}
        </option>
        {
          dataroute?.data?.parametersList?.map((item)=>(
            <option
              key={item.idRoute}
              value={item.idRoute}
            >
              {item.route}
            </option>
          ))
        }
      </TextField>
          </div>
          <div className="goal2">
            
          <TextField
              label="Meta"
              type={"number"}
              required
              value={meta}
              defaultValue={""}
              sx={{ m: 1.5, width: "100%" }}
              style={
                vmeta===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={(event)=>setvmeta(false)}
              onBlur={
                meta==null?(event)=>setvmeta(true):(event)=>setvmeta(false)
              }
              onChange={(event) => setmeta(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>    
          <div className="goal3">
            <label>Fecha</label>
          < input 
              type="month" 
              value={my} 
              onChange={(e)=>setmy(e.target.value)} 
          />  
          </div>    
        </div> 
        <br></br>
        <br></br>
          <Box>
            {
              bandera===true?
            <Button
            variant="contained"
            style={{"background-color":"green","height":"35px","color":"white", "marginRight":"10px"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={createnewdiscount}
          >
            Crear Meta
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>

            }
            <Button
              style={{"background-color":"red","color":"white","height":"35px"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch"}}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateGoal;
