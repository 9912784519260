import Get_bussines_p from "../../form_business_partner/get_busiines_p";
import { Component } from "react";


export class Get_bussinesP extends Component {
  constructor(props) {
   
  }
  

  render() {
    
      
      return (    
      
      <div className="tc" >
      <Get_bussines_p/> 

      </div>
          );
       }     
}

export default Get_bussinesP;


