export const modulos = [
    {value: '',label: 'Seleccione un módulo',},
    {value: 'FINANZAS',label: 'FINANZAS',},
    {value: 'COMPRAS',label: 'COMPRAS',},
    {value: 'FACTURAS',label: 'FACTURAS',},
    {value: 'SOCIO DE NEGOCIOS',label: 'SOCIO DE NEGOCIOS',},
    {value: 'SOPORTE', label: 'SOPORTE'}
];
export const finanzas_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Cuentas contables',label: 'Cuentas Contables',}
];

export const compras_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Consultar documentos envíados',label: 'Consultar documentos envíados',},
    {value: 'Envío de documentos',label: 'Envío de documentos',},
    {value: 'Catálogo de gastos',label: 'Catálogo de gastos',},
    {value: 'Reglas de gastos',label: 'Reglas de gastos',},
    {value: 'Gastos no deducibles',label: 'Gastos no deducibles',},
    {value: 'Gastos del extranjero',label: 'Gastos del extranjero',},   
    {value: 'Reporte de gastos',label: 'Reporte de gastos',},
    {value: 'Reporte de gastos agrupados',label: 'Reporte de gastos agrupados',},
    {value: 'Pólizas',label: 'Pólizas',},   
];

export const facturas_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Consultar conceptos',label: 'Consultar conceptos',},
    {value: 'Consultar facturas',label: 'Consultar facturas',},
    {value: 'Consultar facturas PPD',label: 'Consultar facturas PPD',},
    {value: 'Consultar impuestos',label: 'Consultar impuestos',},
    {value: 'Realizar Carta Porte',label: 'Realizar Carta Porte',},
    {value: 'Realizar complemento de pago', label: 'Realizar complemento de pago',},
    {value: 'Realizar factura',label: 'Realizar factura',},
];

export const snegocios_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Consultar socio de negocios',label: 'Consultar socio de negocios',},
];

export const soporte = [
    {value: 'Seleccione un valor', label: 'Seleccione un valor'},
    {value: 'Centro de Ayuda', label: "Centro de ayuda"},
    {value: 'Capacitación', label: 'Capacitación'}
]

export const severidad = [
    {value: 'Baja', label: 'Baja',},
    {value: 'Media', label: 'Media',},
    {value: 'Alta', label: 'Alta',},
];


