//paqueterias
import { useMemo, useRef, useState } from 'react';
import { StickyTable} from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
import FormControlLabel from "@mui/material/FormControlLabel";
//componentes
import { COLUMS_SHOW } from '../../../main_components/Table/ColumsShow';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh'
import { get_invoices_payment_report } from '../../../services/invoices/invoice';
import TableContainer from '../../../main_components/Table/TableContainer';
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";
import AlertResponse from '../../../main_components/alerts/alertResponse';
import { SelectColumnFilter } from '../../filters';
import { GENERATE_COLUMNS, SHOWDATA } from '../../../main_components/Table/generate_columns';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
//Estilos css
import "./payment_report.css";

export const PAYMENT_REPORT = () => {

    const[fi, setfi]=useState("");
    const[ff, setff]=useState("");
    const [data, setdata]=useState([]);
    const showcolumns = GENERATE_COLUMNS("payment_report", 14);
    const notificationSystemRef = useRef();
    const [modalT, setmodalT]=useState(false);
    const [filtersColumsView, setfiltersColumsView] = useState(true);

    const handlesubmit = async()=>{

      if(fi==="" || fi===" " || ff === "" || ff === " "){
        alertas("Falta asignar Fecha", false);
        return null;
      }

        setmodalT(true);
        setdata([]);
        
        try{
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                    try{
                      const d = await get_invoices_payment_report(fi, ff);
                      setdata(d.data);
                      setmodalT(false);
                    }catch(err){
                      console.log(err);
                      setmodalT(false);
                      alertas("Error al obtener información del reporte", false);
                      setdata([]);
                    }
                }, 1000);
            }else{}
        }catch(err){
            console.log(err)
        }
    }

    const alertas = (msj, status) => {
      if (status === true) {
        notificationSystemRef.current.addNotification({
          message: <AlertResponse msj={msj} view={true}></AlertResponse>,
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
      } else{
        notificationSystemRef.current.addNotification({
          message: <AlertResponse msj={msj} view={false}></AlertResponse>,
          level: "error",
          position: "br",
          autoDismiss: 60,
        });
      }
    };


    const columns = useMemo(
        () => [
          {
            Header: "Folio Pago",
            accessor:"foliop",
            show: showcolumns.payment_report_c1.value,
            id: "foliop"
          },
          {
            Header: "Fecha Pago",
            accessor: "fecha",
            show: showcolumns.payment_report_c2.value,
            id: "fecha"
          },
          {
            Header: "UUID Pago",
            accessor:"uuidp",
            show: showcolumns.payment_report_c3.value,
            id: "uuidp"
          },
          {
            Header: "Socio de Negocios",
            accessor: "socio",
            show: showcolumns.payment_report_c4.value,
            id: "socio",
          },
          {
            Header: "RFC",
            accessor: "rfc",
            show: showcolumns.payment_report_c5.value,
            id: "rfc"
          },
          {
            Header: "Cancelada",
            accessor: (d)=>{
                return d.cancel===false?"NO":"SI"
            },
            Filter: SelectColumnFilter,
            show: showcolumns.payment_report_c6.value,
            id: "cancel"
          },
          {
            Header: "Total",
            show: showcolumns.payment_report_c7.value,
            id: "total",
            accessor: (d) => {
              return d.total
                ? Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.total)
                : Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.total);
            }
          },
          {
            Header: "Base IVA Pago",
            show: showcolumns.payment_report_c8.value,
            id: "baseiva0",
            accessor: (d) => {
              return d.baseiva0
                ? Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseiva0)
                : Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseiva0);
            }
          },
          {
            Header: "Base IEPS Pago",
            show: showcolumns.payment_report_c9.value,
            id: "baseieps8",
            accessor: (d) => {
              return d.baseieps8
                ? Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseieps8)
                : Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseieps8);
            }
          },
          {
            Header: "Folio Factura",
            accessor: "folioi",
            show: showcolumns.payment_report_c10.value,
            id: "folioi"
          },
          {
            Header: "UUID Factura",
            accessor: "uuidi",
            show: showcolumns.payment_report_c11.value,
            id: "uuidi"
          },
          {
            Header: "Monto Factura",
            show: showcolumns.payment_report_c12.value,
            id: "montoi",
            accessor: (d) => {
              return d.montoi
                ? Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.montoi)
                : Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.montoi);
            }
          },
          {
            Header: "Base IVA Factura",
            show: showcolumns.payment_report_c13.value,
            id: "baseiva0i",
            accessor: (d) => {
              return d.baseiva0i
                ? Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseiva0i)
                : Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseiva0i);
            }
          },
          {
            Header: "Base IEPS Factura",
            show: showcolumns.payment_report_c14.value,
            id: "baseieps8i",
            accessor: (d) => {
              return d.baseieps8i
                ? Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseieps8i)
                : Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(d.baseieps8i);
            }
          }
         
          ],
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [showcolumns]
      );
  
  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };
      
  return (
    <>
    <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem> 
     <BREADCRUMBS niveles={
                [
                  {label: "REPORTE DE COMPLEMENTOS DE PAGO", path: null},
                ]
              }
      ></BREADCRUMBS>
       <COLUMS_SHOW
            filtersColumsView={filtersColumsView}
            datacolums={SHOWDATA(columns, showcolumns, "payment_report")}
         />

    <div className='paymentreport-container'>
        <div className='search_payment_report'>
            <div className="fil1">
                <input
                type="text"
                className="fi"
                onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
                ></input>
            </div>
            <div className="fil2">
                <input
                type="text"
                className="ff"
                onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                }}
                placeholder="Fecha Final"
                onChange={(event) => setff(event.target.value)}
                ></input>
            </div>
            <div className='button-report'>
                <button onClick={()=>handlesubmit()}>Buscar</button>
            </div>
            <div className='filtro-report-payment'>
              <div className="expen-8 color-1">
                <FormControlLabel
                  control={
                    <MaterialUISwitch
                      sx={{ m: 1 }}
                      onChange={handlechecked}
                      checked={filtersColumsView}
                    />
                  }
                  label={
                    filtersColumsView === true
                      ? "Activar Filtros"
                      : "Desactivar Filtros"
                  }
                />
              </div>
            </div>
        </div>
        <div className='table_report_payment'>
          <StickyTable>
            <TableContainer 
              Grantotal={true}
              GrantotalCabeceras={["Total","Base IVA Pago","Base IEPS Pago"]}
              paginado={"Pagos"}
              consultas={"Pagos Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              nametable={"Reporte de Complementos de Pago"}
            />
          </StickyTable>

        </div>
    </div>
    
    </>
    
  )
}
