//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import { StickyTable } from "react-sticky-table";

import NotificationSystem from "react-notification-system";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_debts } from "../../services/banck/banck";
import CREATE_UPDATE from "./tools/create_update";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import { delete_products } from "../../services/inventory/inventory";
import { recovery_products } from "../../services/inventory/inventory";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { date_current } from "../../main_components/date/date";
import { previous_date } from "../../main_components/date/date";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { COLUMS_SHOW } from "../../main_components/Table/ColumsShow";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MaterialUISwitch } from "../../main_components/material_switch/switch";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/pago.png";


import TAX from "./tools/tax";
import { pagination } from "../../main_components/pagination/pagination";

const SALES_DEBTS = (props) => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [dtjson, setdtjson] = useState(null);
  const [dtjson2, setdtjson2] = useState(null);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [deleted, setdeleted] = useState(false);
  const [fi, setfi] = useState(previous_date);
  const [ff, setff] = useState(date_current);
  const notificationSystemRef = useRef();

  const [debts_1, setdebts_1]=useState(localStorage.getItem("debts_1") === null? true: localStorage.getItem("debts_1") === "true"? true:false);
  const [debts_2, setdebts_2]=useState(localStorage.getItem("debts_2") === null? true: localStorage.getItem("debts_2") === "true"? true:false);
  const [debts_3, setdebts_3]=useState(localStorage.getItem("debts_3") === null? true: localStorage.getItem("debts_3") === "true"? true:false);
  const [debts_4, setdebts_4]=useState(localStorage.getItem("debts_4") === null? true: localStorage.getItem("debts_4") === "true"? true:false);
  const [debts_5, setdebts_5]=useState(localStorage.getItem("debts_5") === null? true: localStorage.getItem("debts_5") === "true"? true:false);
  const [debts_6, setdebts_6]=useState(localStorage.getItem("debts_6") === null? true: localStorage.getItem("debts_6") === "true"? true:false);
  const [debts_7, setdebts_7]=useState(localStorage.getItem("debts_7") === null? true: localStorage.getItem("debts_7") === "true"? true:false);
  const [debts_8, setdebts_8]=useState(localStorage.getItem("debts_8") === null? true: localStorage.getItem("debts_8") === "true"? true:false);
  const [debts_9, setdebts_9]=useState(localStorage.getItem("debts_9") === null? true: localStorage.getItem("debts_9") === "true"? true:false);
  const [debts_10, setdebts_10]=useState(localStorage.getItem("debts_10") === null? true: localStorage.getItem("debts_10") === "true"? true:false);
  const [debts_11, setdebts_11]=useState(localStorage.getItem("debts_11") === null? true: localStorage.getItem("debts_11") === "true"? true:false);
  const [debts_12, setdebts_12]=useState(localStorage.getItem("debts_12") === null? true: localStorage.getItem("debts_12") === "true"? true:false);
  const [debts_13, setdebts_13]=useState(localStorage.getItem("debts_13") === null? true: localStorage.getItem("debts_13") === "true"? true:false);
  const [debts_14, setdebts_14]=useState(localStorage.getItem("debts_14") === null? true: localStorage.getItem("debts_14") === "true"? true:false);
  const [debts_15, setdebts_15]=useState(localStorage.getItem("debts_15") === null? true: localStorage.getItem("debts_15") === "true"? true:false);
  const [debts_16, setdebts_16]=useState(localStorage.getItem("debts_16") === null? true: localStorage.getItem("debts_16") === "true"? true:false);
  const [debts_17, setdebts_17]=useState(localStorage.getItem("debts_17") === null? true: localStorage.getItem("debts_17") === "true"? true:false);
  const [debts_18, setdebts_18]=useState(localStorage.getItem("debts_18") === null? true: localStorage.getItem("debts_18") === "true"? true:false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);

  useEffect(()=>{
    localStorage.setItem("debts_1", debts_1);
    localStorage.setItem("debts_2", debts_2);
    localStorage.setItem("debts_3", debts_3);
    localStorage.setItem("debts_4", debts_4);
    localStorage.setItem("debts_5", debts_5);
    localStorage.setItem("debts_6", debts_6);
    localStorage.setItem("debts_7", debts_7);
    localStorage.setItem("debts_8", debts_8);
    localStorage.setItem("debts_9", debts_9);
    localStorage.setItem("debts_10", debts_10);
    localStorage.setItem("debts_11", debts_11);
    localStorage.setItem("debts_12", debts_12);
    localStorage.setItem("debts_13", debts_13);
    localStorage.setItem("debts_14", debts_14);
    localStorage.setItem("debts_15", debts_15);
    localStorage.setItem("debts_16", debts_16);
    localStorage.setItem("debts_17", debts_17);
    localStorage.setItem("debts_18", debts_18);



  },[debts_1, debts_10, debts_11, debts_12, debts_13, debts_14, debts_15, debts_16, debts_17, debts_18, debts_2, debts_3, debts_4, debts_5, debts_6, debts_7, debts_8, debts_9]);
  
  const datacolumns = [
    {
      view: debts_1,
      setview: setdebts_1,
      label: "Fecha Factura"
    },
    {
      view: debts_2,
      setview: setdebts_2,
      label: "Factura"
    },
    {
      view: debts_3,
      setview: setdebts_3,
      label: "Ruta"
    },
    {
      view: debts_4,
      setview: setdebts_4,
      label: "Fecha Venta"
    },
    {
      view: debts_5,
      setview: setdebts_5,
      label: "Venta"
    },
    {
      view: debts_6,
      setview: setdebts_6,
      label: "Código Punto de Venta"
    },
    {
      view: debts_7,
      setview: setdebts_7,
      label: "Punto de Venta"
    },
    {
      view: debts_8,
      setview: setdebts_8,
      label: "Código Socio de Negocios"
    },
    {
      view: debts_9,
      setview: setdebts_9,
      label: "Cliente"
    },
    {
      view: debts_10,
      setview: setdebts_10,
      label: "Fecha Deuda"
    },
    {
      view: debts_11,
      setview: setdebts_11,
      label: "Vencimiento"
    },
    {
      view: debts_12,
      setview: setdebts_12,
      label: "Total"
    },
    {
      view: debts_13,
      setview: setdebts_13,
      label: "Devolución / Bonificación"
    },
    {
      view: debts_14,
      setview: setdebts_14,
      label: "Pago"
    },
    {
      view: debts_15,
      setview: setdebts_15,
      label: "Deuda"
    },
    {
      view: debts_16,
      setview: setdebts_16,
      label: "Pagado"
    },
    {
      view: debts_17,
      setview: setdebts_17,
      label: "Método"
    },
    {
      view: debts_18,
      setview: setdebts_18,
      label: "Semaforo"
    },


  
  ]
  



  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setMenssage("Productos...");
    try {
      await refresh_token();

      setTimeout(async () => {
        try {
          var dt = await pagination(5, "debts", null, null, fi, ff,dtjson,dtjson2);
          console.log(dt);
          setmodalT(false);
          setData(dt);
          if (a) {
            handle_alert(b, a);
          }
    
        } catch (error) {
          console.log(error);
          return null;
        }

      },1000);
    } catch (err) {
      setData([]);
      setMenssage("");
      setmodalT(false);
    }
  };
 
  const handleSubmit2 = async (a, b) => {
    try {
      try {
        var dt = await pagination(5, "aux1", null, null, null, null);
        console.log(dt);
        setData2(dt);
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setData([]);
      setMenssage("");
    }
  };

  const handleSubmit3 = async (a, b) => {

    try {

      try {
        var dt = await pagination(5, "aux2", null, null, null, null);
        console.log(dt);
        setData3(dt);
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setData([]);
      setMenssage("");
    }
  };

  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  const handle_reconsult = (a, b) => {
    if (a) {
      handleSubmit(a, b);
    } else if (a === false) {
      handle_alert(b, a);
    }
  };

  useEffect(() => {
    handleSubmit2();
    handleSubmit();
    handleSubmit3();
  }, [deleted]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_debts_detail", row.original.id);
          }

          return (
            <div>
              <a
                title="Detalle de la deuda"
                {...row.getToggleRowExpandedProps()}
              >
                {row?.isExpanded ? (
                  <span
                    title="Detalle de la deuda"
                    style={{
                      display: row.original.paid > 0 ? "" : "none",
                    }}
                  >
                    🔼
                  </span>
                ) : (
                  <span
                    style={{
                      display: row.original.paid > 0 ? "" : "none",
                    }}
                    title="Detalle de la deuda"
                    onClick={guardardato}
                  >
                    🔽
                  </span>
                )}
              </a>

              <img
                alt=""
                title="Realizar pago"
                src={SAVE}
                style={{ display: row.original.debt > 0 ? "" : "none" }}
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      subtabla={props.subtabla}
                      id={row.original.id}
                      uuid={row.original.uuid}
                      ticket={row.original.ticket}
                      totalsale={row.original.total_debt}
                      pointofsale={row.original.name_pos}
                      bp={row.original.name_business_partner}
                      max_payment={row.original.debt}
                      method_payment={row.original.ticket}
                      payment={row.original.ticket}
                      note={row.original.ticket}
                      tarjet={row.original.ticket}
                      name_line={row.original.id_products_line_fk}
                      description={row.original.description}
                      enable={row.original.enable}
                      method={"UPDATE"}
                      name_supplier={row.original.name_supplier}
                      id_supp={row.original.id_supp}
                      product_name={row.original.product_name}
                      barcode={row.original.barcode}
                      type={row.original.type}
                      product_line_data={row.original.product_line_data}
                      only_promo={row.original.only_promo}
                      cost={row.original.cost}
                      pieces={row.original.pieces}
                      clave_prod_serv={row.original.clave_prod_serv}
                      clave_unidad={row.original.clave_unidad}
                      clave_unidad_2={row.original.clave_unidad}
                      weight={row.original.weight}
                      iva0={row.original.iva0}
                      iva16={row.original.iva16}
                      ieps8={row.original.ieps8}
                      minimum_sales_unit={row.original.minimum_sales_unit}
                      minimum_sales_unit2={row.original.minimum_sales_unit_2}
                      unit={row.original.unit}
                      unit_dms={row.original.unit_dms}
                      unit_conversion={row.original.unit_conversion}
                      brand={row.original.brand}
                      paidout={row.original.paidout}
                      handleSubmit={handleSubmit}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Actualizar deuda",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result?.validate, result?.msj);
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Fecha Factura",
        accessor: (d)=>{
          return d.invoice_date?.split('.')[0]
        },
        show: debts_1,
        id: "debts_1",

      },
      {
        Header: "Factura",
        accessor: "folio_invoice",
        show: debts_2,
        id: "debts_2",
      },
      {
        Header: "Ruta",
        accessor: "route",
        show: debts_3,
        id: "debts_3",
      },
      {
        Header: "Fecha Venta",
        accessor: "sale_date",
        show: debts_4,
        id: "debts_4",
      },
      {
        Header: "Venta",
        accessor: "ticket",
        show: debts_5,
        id: "debts_5",
      },
      {
        Header: "Código Punto de Venta",
        accessor: "code_pos",
        show: debts_6,
        id: "debts_6",
      },
      {
        Header: "Punto de venta",
        accessor: "name_pos",
        show: debts_7,
        id: "debts_7",
      },
      {
        Header: "Código Socio de Negocios",
        accessor: "code_business_partner",
        show: debts_8,
        id: "debts_8",
      },
      {
        Header: "Cliente",
        accessor: "name_business_partner",
        show: debts_9,
        id: "debts_9",
      },
      {
        Header: "Fecha Deuda",
        accessor: (d)=>{
          return d.debt_date.split('.')[0]
        },
        show: debts_10,
        id: "debts_10",
      },
      {
        Header: "Vencimiento",
        accessor: (d)=>{
          return d.due_date.split('.')[0]
        },
        show: debts_11,
        id: "debts_11",
      },
      {
        Header: "Total",
        accessor: "total_debt",
        show: debts_12,
        id: "debts_12",
      },
      {
        Header: "Devolución / Bonificación",
        accessor: "total_returns",
        show: debts_13,
        id: "debts_13",
      },
      {
        Header: "Pago",
        accessor: "paid",
        show: debts_14,
        id: "debts_14",
      },
      {
        Header: "Deuda",
        accessor: "debt",
        show: debts_15,
        id: "debts_15",
      },
      {
        Header: "Pagado",
        accessor: (d) => {
          return d.debt === 0 ? "SI" : "NO";
        },
        show: debts_16,
        id: "debts_16",
      },
      {
        Header: "Método",
        accessor: "payment_method",
        show: debts_17,
        id: "debts_17",
      },
      {
        Header: "Semaforo",
        accessor: "debt_flag",
        show: debts_18,
        id: "debts_18",
      },
   
    ],
    [debts_1, debts_10, debts_11, debts_12, debts_13, debts_14, debts_15, debts_16, debts_17, debts_18, debts_2, debts_3, debts_4, debts_5, debts_6, debts_7, debts_8, debts_9]
  );



  const handleOnSelect = (item) => {
    setdtjson(item?.id);
    setdtjson2(null)
  };


  const handleOnSelect2 = (item) => {
    setdtjson(null);
    setdtjson2(item?.id)
  };
  return (
    <div className="">
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR DEUDAS", path: null},
                ]
              }
      ></BREADCRUMBS>
       <COLUMS_SHOW
            filtersColumsView={filtersColumsView}
            datacolums={datacolumns}
         />
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>


      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <br></br>


      <div className="containersaledebts">
        <div className="sdbts-1 color-1">
        <div style={{width: "100px", margin:"0 auto"}}>
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
                    </div>

        </div>
         
        <div className="sdbts-2 color-2">
        <div style={{width: "100px", margin:"0 auto"}}>

          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
                    </div>

        </div>

        <div className="sdbts-3 color-5"  >
          <div style={{width: "250px", margin:"0 auto"}}>
          <ReactSearchAutocomplete
              items={data2}
              fuseOptions={{ keys: ["id","business_partner"]}}
              resultStringKeyName={"business_partner"}
              onSelect={handleOnSelect}
              onClear={handleOnSelect}
              showIcon={true}
              maxResults={20}
              placeholder="Buscar socio de negocios..."
              styling={{
                height: "34px",
                border: "1px solid darkgreen",
                borderRadius: "4px",
                backgroundColor: "white",
                boxShadow: "none",
                hoverBackgroundColor: "lightgreen",
                color: "black",
                iconColor: "black",
                lineColor: "black",
                placeholderColor: "black",
                clearIconMargin: "3px 8px 0 0",
                zIndex: 10,
                }}
            />
          </div>
          
        </div>

        <div className="sdbts-4 color-5" >
        <div style={{width: "250px", margin:"0 auto"}}>
          <ReactSearchAutocomplete
              items={data3}
              fuseOptions={{ keys: ["id","name"]}}
              resultStringKeyName={"name"}
              onSelect={handleOnSelect2}
              onClear={handleOnSelect}

              showIcon={true}
              maxResults={20}
              placeholder="Buscar por punto de venta..."
              styling={{
                height: "34px",
                border: "1px solid darkgreen",
                borderRadius: "4px",
                backgroundColor: "white",
                boxShadow: "none",
                hoverBackgroundColor: "lightgreen",
                color: "black",
                iconColor: "black",
                lineColor: "black",
                placeholderColor: "black",
                clearIconMargin: "3px 8px 0 0",
                zIndex: 10,
                }}
            />
          </div>
        </div>

        <div className="sdbts-5 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={() =>
              handleSubmit(false, "Deudas consultadas correctamente")
            }
          >
            Buscar
          </Button>
        </div>

        <div className="sdbts-6 color-5">
        <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 1 }}
                        onChange={handlechecked}
                        checked={filtersColumsView}
                      />
                    }
                    label={
                      filtersColumsView === true
                        ? "Activar Filtros"
                        : "Desactivar Filtros"
                    }
                  />
        </div>
      </div>



     

      <div className="TableDebts">
          <StickyTable>
          <TableContainer
            columns={columns}
            data={data}
            exportar={true}
            style={{ overflowx: 10 }}
            Grantotal={false}
            GrantotalCabeceras={["Fecha Factura","Factura","Metodo","Semaforo","Pagado por","Fecha pago"]}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <TAX row={row} handleSubmit={handleSubmit}/>
                </div>
              );
            }}
          />
          </StickyTable>
      </div>
    </div>
  );
};

export default SALES_DEBTS;
