//PAQUETERIAS
import React, {useEffect,useState} from "react";
import {  styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

//COMPONENTES
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { create_price_list } from "../../../services/inventory/inventory";
import { update_price_list } from "../../../services/inventory/inventory";
import { get_businesspartners_supplier } from "../../../services/businesspartners/businesspartners";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";

//IMAGENES
//ESTILOS

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});


const CREATE_UPDATE = (props) => {
  const [modalT, setmodalT] = useState(false);
  const [price_list, setprice_list] = useState(props.price_list);
  const dialog = useDialog();
  const handle_create_products_line = async () => {
    setmodalT(true);
    var data = {
      name: price_list,
    };
   
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await create_price_list(data);
            setmodalT(false);
            dialog.close({confirm:true,msj:"Lista de precios creado correctamente"});
          } catch (err) {
            catchErrorModul("Lista de Precios",JSON.stringify(data),JSON.stringify(err?.response));
            setmodalT(false);
            dialog.close({confirm:false,msj:JSON.stringify(err.response.data)});
          }
        }, 1000);
      }else{}
    } catch (err) {
     console.log(err);
   
    }

  };

  const handle_update_products_line = async () => {
    setmodalT(true);
    var data = {
      name: price_list,
    };

    try {
      await refresh_token();
      setTimeout(async() => {
        try {
          await update_price_list(data, props.id);
          setmodalT(false);
          dialog.close({confirm:true,msj:"Lista de precios actualizada correctamente"});
        } catch (err) {
          catchErrorModul("Lista de Precios",JSON.stringify(data),JSON.stringify(err?.response));
          setmodalT(false);
          dialog.close({confirm:false,msj:JSON.stringify(err.response.data)});
        }
      }, 1000);
    } catch (err) {
      setmodalT(false);
   
    }
  };



  return (
    <div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <form>
        <Box>
          <ValidationTextField
            label="Lista de precio "
            variant="outlined"
            placeholder="Lista de precio"
            sx={{ m: 2, width: "80%" }}
            value={price_list}
            onChange={(e) => setprice_list(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>


        <br></br>

        <Box>
          <Button
            variant="contained"
            style={{ display: props.method === "CREATE" ? "" : "none" }}
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_create_products_line}
            className="ButtonModal"
          >
            Crear
          </Button>
          <Button
            style={{ display: props.method === "UPDATE" ? "" : "none" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_update_products_line}
            className="ButtonModal"
          >
            Actualizar
          </Button>

          <Button
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            className="ButtonModal2"
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
};
export default CREATE_UPDATE;
