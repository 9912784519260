import  { Component } from "react";
import TableEditAddSales from "../../componentBonificaciones/EditVentasBonificaciones/TableEditAddSales";
export class SetEditAddBonuses extends Component {
  


  render() {

    
      
      return (    
      
      <div className="tc" >
        <TableEditAddSales/> 
      </div>
          );
       }     
}

export default SetEditAddBonuses;


