//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";

import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Cashclosing";
import SET_CASHCLOSING from "./set_cashclosing";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { date_current } from "../../../main_components/date/date";
import { previous_date} from "../../../main_components/date/date";
import { get_cashclosing } from "../../../services/cashclosing/cashclosing";
import { put_cashclosing } from "../../../services/cashclosing/cashclosing";
import { delete_cashclosing } from "../../../services/cashclosing/cashclosing";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import "../cashclosing.css";
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import ADD from "../../../imgComponents/lupa.png";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";

const CONSULT_CASHCLOSING= () => {
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState("Ingresando al modulo corte de caja...");
  const [fi, setfi] = useState(previous_date);
  const [ff, setff] = useState(date_current);
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral] = useState(false);

  const handleSubmit = async (event) => {
    setmodalT(true);
    setMenssage("Ingresando al modulo corte de caja...");
    setData([]);
    try {
      const dt= await get_cashclosing(fi,ff);
      const dataorder = dt.data.sort((a, b) =>a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
      setData(dataorder);
      setMenssage("");
      setmodalT(false);
    } catch (err) {
      console.log("error", err);
      setData([]);
      setmodalT(false);
      setMenssage("");
    }
  };

  const handelCashclosing = async(fi, ff, route, id, id_cashclosing) =>{
    setmodalT(true);
    try {
      await put_cashclosing(id_cashclosing,fi,ff,route,id);
      setmodalT(false);
      handleReconsulta(true);
    } catch (err) {
      setmodalT(false);
      catchErrorModul("Actualizar Corte de Caja", JSON.stringify({}), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      var errM = JSON.stringify(err.response.data);
      alert(errM);
    }    
  }


  const handelDeleteCashclosing=async(id_cashclosing)=> {
    refresh_token ();
    setData([]);
    try {
      await delete_cashclosing(id_cashclosing);  
      setmodalT(false);
      handleReconsulta(true);
    } catch (err) {
      setmodalT(false);
      catchErrorModul("Eliminar Corte de Caja ", JSON.stringify({}), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      var errM = JSON.stringify(err.response.data);
      alert(errM);
    }
  }

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };
  useEffect(() => {
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <>
              <p style={{ color: "white" }}>Acciones</p>
              <img
              alt=""
                title="Consultar ventas"
                src={CREATE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <SET_CASHCLOSING></SET_CASHCLOSING>,
                    {
                      className: "cutBoxModal",
                      title: "Consultar ventas",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>
            </>
          );
        },
        Cell: ({ row }) => {
          const handelFuncionReload=()=> { handelCashclosing(row.original.idate, row.original.fdate, row.original.route, row.original.user_to_review_fk, row.original.id); }

          const handelFuncionDelete=()=> { handelDeleteCashclosing(row.original.id); }

          return (
            <div>
              <img
              alt=""
                title="Conlsultar corte de caja"
                src={ADD}
                onClick={async () => {
                  sessionStorage.setItem("routeSale", row.original.route);
                  sessionStorage.setItem("dateiSale", row.original.idate);
                  sessionStorage.setItem("datefSale", row.original.fdate);
                  sessionStorage.setItem("selectwarehouse", row.original.user_to_review_fk);
                  sessionStorage.setItem("datawarehose", row.original.id);
                  window.location = "/Consult_cut_box_sale";
                }}
                className="centerText, taxes"
              ></img>

              <img
              alt=""
                title="Actualizar corte de caja"
                src={SAVE}
                onClick={async () => {
                  if (
                    sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                    const result = await Confirm(
                      "¿Esta usted seguro de actualizar este corte de caja?",
                      "Actualizar corte de caja",
                      "Si",
                      "No"
                    );
                    if (result) {
                      handelFuncionReload();
                    } else {
                    }
                  } else {
                    alert( "No tienes permisos para actualizar un corte de caja" );
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
              alt=""
                title="Eliminar corte de caja"
                style={{display:row.original.close===false?"":"none"}}
                src={DELETE}
                onClick={async () => {
                  if (
                    sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                    const result = await Confirm(
                      "¿Esta usted seguro de eliminar este corte de caja?",
                      "Eliminar corte de caja",
                      "Si",
                      "No"
                    );
                    if (result) {
                      handelFuncionDelete();
                    } else {
                    }
                  } else {
                    alert("No tienes permisos para eliminar un corte de caja");
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
      },

      {
        Header: "F. Registro",
        accessor: "date_register",
        Cell: ({ value }) => value.substring(0, 16)

      },
      {
        Header: "Ruta",
        accessor: "route",
      },
      {
        Header: "F. Inicial",
        accessor: "idate",
      },
      {
        Header: "F. Final",
        accessor: "fdate",
      },
      {
        Header: "Facturado",
        accessor: "invoiced",
      },
      {
        Header: "Pagado",
        accessor: "payed",
      },
      {
        Header: "No pagado",
        accessor: "not_payed",
      },
      {
        Header: "Obsequios",
        accessor: "gifts",
      },
      {
        Header: "Crédito",
        accessor: "credit",
      },

      {
        Header: "Venta Secundaria Crédito",
        accessor: "secundary_sales",
      },

      {
        Header: "Venta Secundaria de Contado",
        accessor: "cash_secondary_sales",
      },

      {
        Header: "Total Venta Propia",
        accessor: "subtotal1",
      },
      {
        Header: "Venta total",
        accessor: "total_sales",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="">

      <BREADCRUMBS niveles={
                [
                  {label: "CORTE DE CAJA", path: null},
                ]
              }
      ></BREADCRUMBS>

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <div className="containerCutBox020">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="ContainerTableCutBox">
        <div
          className="TableReport TableCutBoxx"
          style={{ overflowX: "auto", overflowY: "auto" }}
        >
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            rp={true}
            reportsale={true}
            totalCutBox={true}
            Gmodal={modalT}
            style={{ overflowx: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default CONSULT_CASHCLOSING;
