//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_movements } from "../../services/inventory/inventory";
import { previous_date } from "../../main_components/date/date";
import { date_current } from "../../main_components/date/date";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import SET_MOVEMENTS from "./tools/create_movements";
import CONSULT_DETAIL_WAREHOUSE from "./detail/consult_detail_sale";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { SelectColumnFilter } from "../../components_cfdi/filters"; 

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import VIEW from "../../imgComponents/lupa.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const MOVEMENTS = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  var fechaActual = new Date();
  var fechaAyer = new Date();
  fechaAyer.setDate(fechaActual.getDate() - 1);
  var date1 = fechaActual.toISOString().slice(0, 10);
  var dat2 = fechaAyer.toISOString().slice(0, 10);

  const [fi, setfi] = useState(dat2);
  const [ff, setff] = useState(date1);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setMenssage("Productos...");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_movements(fi, ff);
            setData(dt.data);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setmodalT(false);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <div>
              <br></br>
              <img
                alt=""
                title="Crear movimiento de producto"
                src={CREATE}
                className=""
                style={{
                  display:
                    sessionStorage.getItem("ROLE_INVENTORY_MOVEMENTS") ===
                    "ROLE_INVENTORY_MOVEMENTS"
                      ? ""
                      : "none",
                }}
                onClick={async () => {
                  const result = await CustomDialog(
                    <SET_MOVEMENTS></SET_MOVEMENTS>,
                    {
                      className: "modalProduct",
                      title: "Movimiento de producto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>
            </div>
          );
        },
        Cell: ({ row }) => {
          return (
            <>
              <img
                alt=""
                title="Mostrar detalles del movimiento"
                src={VIEW}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <CONSULT_DETAIL_WAREHOUSE
                      id={row.original.id}
                      date={row.original.movement_date}
                      rows={row.original.rows}
                      note={row.original.note}
                      reference={row.original.reference}
                      warehouse_name={row.original.warehouse_name}
                      sale={row.original.sale}
                      returns={row.original.returns}
                      type={row.original.type}
                      movement_type={row.original.movement_type}
                    ></CONSULT_DETAIL_WAREHOUSE>,
                    {
                      className: "modalProduct",
                      title: "Movimiento de producto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>
            </>
          );
        },
      },

      {
        Header: "Partida",
        accessor: "movement_date",
      },
      {
        Header: "Almacen",
        accessor: "warehouse_name",
        Filter:SelectColumnFilter,
        filter: "equals",

      },
      {
        Header: "Tipo",
        accessor: "movement_type",
        Filter:SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Nota",
        accessor: "note",
      },
      {
        Header: "Usuario",
        accessor: "fullusername",
      },
      {
        Header: "Venta",
        accessor: "sale",
      },
      {
        Header: "Devoluciones",
        accessor: "returns",
      },
    ],
    []
  );

  return (
    <div className="">
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS niveles={
            [
              {label: "MOVIMIENTO DE PRODUCTOS", path: null},
            ]
          }
      ></BREADCRUMBS>
      <div className="containerMovementsProduct">
        <div className="pdc-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="pdc-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="pdc-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="Table_Movements_Product">
        <div className="">
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            exportar={true}
          />
        </div>
      </div>
    </div>
  );
};

export default MOVEMENTS;
