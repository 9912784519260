import axios from 'axios';
import { get_telegram_secrets } from '../../services/supports/supports';

export const TELEGRAM = (folio, body, subject, desc, type) => {
  credenciales(folio, body, subject, desc, type);
}

const credenciales=async(folio, body, subject, desc, type)=>{
  try{
    var r = await get_telegram_secrets();
    handleposttelegram(folio, body, subject, desc, r?.data, type);
  }catch(err){
    console.log(err);
  }
}


const handleposttelegram=(folio, body, subject,desc, r, type)=>{

  var permisosUsuario = sessionStorage.getItem("roles_user");
  var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
  var permisos = permisoUsuarioReplace?.split(",");
  var permisosuport = permisos?.filter((x) => x === "ROLE_SUPPORT");


  const token = r?.token;
  const grupoId = r?.grupo;
  const apiUrl = `https://api.telegram.org/bot${token}/sendMessage`;
  var mensaje = "";
  const nombreCompleto = permisosuport.length===1?"SOPORTE HI":localStorage.getItem("user_name");

  
  switch(type){
    case "coment":
      mensaje = 
      "AGENTE SMIT-SEGUIMIENTO\n\n\n"+
      "Se dio respuesta al ticket con Folio: *"+folio+"*\n\n"+
      "Asunto del ticket: *"+ subject+"*\n\n"+
      "Usuario que responde: *"+ nombreCompleto+"*\n\n"+
      "Comentario: \n*"+body?.comment+"*"; 
    break;
    case "create":
      mensaje = 
      "AGENTE SMIT\n\n\n"+
      "Nuevo ticket creado con Folio: *" + folio + "*\n\n" +
      "Registrado por: *" + nombreCompleto + "*\n\n" +
      "Asunto: *" + subject+"*\n\n"
    break;
    case "process":
      mensaje =
      "AGENTE SMIT\n\n\n"+
      "El Ticket con Folio: *"+ folio + "*\n\n Se encuentra en revisión, notificaremos al usuario cuando tengamos respuesta al soporte"
    break;
  }


   
  // Parámetros de la solicitud
  const params = {
    chat_id: grupoId,
    text: mensaje,
    parse_mode: 'Markdown',
  };
   axios.post(apiUrl, params)
     .then((response) => {
       console.log('Mensaje enviado:', response.data);
     })
     .catch((error) => {
       console.error('Error al enviar el mensaje:', error);
     });
}
