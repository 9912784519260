import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import LoadingScreen from "react-loading-screen";
import Spinner from "../imgComponents/S.gif";
import estado from "../dataComponets/state.json";
import regimen from "../dataComponets/regimen.json";
import usoCFDI from "../dataComponets/usoCFDI.json";
import medotoPagoJ from "../dataComponets/metodo_pago.json";
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { create_bp, get_code_businesspartners } from "../services/businesspartners/businesspartners";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});


const op5 = [
  { value: "", label: "Selecciona una forma de pago" },
  { value: "PUE", label: "PUE" },
  { value: "PPD", label: "PPD" },
];


function Set_bussines_pInvoice() {
  const [values, setValues] = useState({
    code: "",
    business_partner: "",
    rfc: "",
    tax_regime: "",
    name: "",
    first_last_name: "",
    second_last_name: "",
    balance: "",
    credit_days: "",
    business_partner_type: "",
    capital_regime: "",
    partner_type: "",
    person_type:  "",
    cfdi_use: "",
    email: "",
    phone: "",
    metodo_pago: "",
    paymethod: "",
    account_number: "",
    allow_invoicing: "",
    street: "",
    outdoor_number:  "",
    inside_number:  "",
    neighborhood:  "",
    zip_code: "",
    city: "",
    state: "",
    town:  "",
    country:  "",
    reference: "",
    address_type:  "",
    subsidiary: "",
    tax_residence:  "",
    num_reg_id_trib: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };



  const [opp3, setOP3] = React.useState("");
  const [opp4, setOP4] = React.useState("");

  const [mPago, setmPago] = React.useState("");

  const [opp6, setOP6] = React.useState("AGUASCALIENTES");
 
  const [opp9, setOP9] = React.useState("");

  const [tax_regimeSelect, settax_regimeSelect] = React.useState("none");
  const [tax_regimeinput, settax_regimeinput] = React.useState("");


  const [stateSelec, setstateSelec] = React.useState("none");
  const [stateInput, setstateInput] = React.useState("");


  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState("");

  useEffect(async() => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_code_businesspartners();
            setList(d === null ? [] : d.data);
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    /*
    axios
      .get(
        "https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/businesspartners/code",
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
      */
  }, [setList]);

  

  function handleCambioComponente() {
    settax_regimeSelect("");
    settax_regimeinput("none");
  }

  function handleCambioComponente04() {
    setstateSelec("");
    setstateInput("none");
  }

 
 
  const handleChangee3 = (event) => {
    setOP3(event.target.value);
  };
  const handleChangee4 = (event) => {
    setOP4(event.target.value);
  };

  const handleChangeepago = (event) => {
    setmPago(event.target.value);
  };
  const handleChangee6 = (event) => {
    setOP6(event.target.value);
  };

  const handleChangee9 = (event) => {
    setOP9(event.target.value);
  };


  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);


    var data = {
      business_partner: {
        business_partner: values.business_partner,

        rfc: values.rfc,

        tax_regime: opp9 || values.tax_regime, // "Regimen Fiscal"

        business_partner_type: "CLIENTE", // Lista de opciones

        partner_type: "PROPIO", // Lista de opciones

        person_type: values?.rfc?.length > 12 ? "FISICA" : "MORAL", //depende del rfc

        cfdi_use: opp3||values.cfdi_use, //Lista de Opciones

        tax_residence: values.tax_residence, //extranjeros

        num_reg_id_trib: values.num_reg_id_trib, //extranjeros

        metodo_pago: mPago||values.metodo_pago, //Lista de opciones

        paymethod: opp4 ||values.paymethod, //Lista de opciones,

        capital_regime: values.capital_regime, // "Regimen Capital"

        code: list.code,

        force_code: false, // Forzar código

        name: values.name,

        first_last_name: values.first_last_name,

        second_last_name: values.second_last_name,

        email: values.email,

        phone: values.phone,

        balance: 0,

        credit_days: 0,

        account_number: values.account_number,

        enable: true,
      },

      address: {
        zip_code: values.zip_code,

        state: opp6||values.state,

        address_type: "FISCAL",

        country: values.country,

        neighborhood: values.neighborhood,

        city: values.city,

        town: values.town,

        street: values.street,

        outdoor_number: values.outdoor_number,

        inside_number: values.inside_number,

        reference: values.reference,

        subsidiary: "MATRIZ",
      },
    };

    /*
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    */
   
    setIsLoading(true);

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await create_bp(data);
            setInterval(
              alert('Exito se creo el socio de negocios'),
               60000
            );
            setInterval(setIsLoading(false), 60000);
            window.location= '/updateBP';
          }catch(err){
            // console.log("AXIOS ERROR: ", err);
            setInterval(setIsLoading(false), 60000);
            setInterval(
              alert("No se puedo crear el socio de negocios"),
              60000
            );
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

  /*
    axios.post('https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/businesspartners', data, axiosConfig) 
  .then((res) => {
    // console.log("RESPONSE RECEIVED: ", res);
    setInterval(
      alert('Exito se creo el socio de negocios'),
       60000
    );
    setInterval(setIsLoading(false), 60000);
    window.location= '/updateBP';
  })

  .catch((err) => {
    // console.log("AXIOS ERROR: ", err);
    setInterval(setIsLoading(false), 60000);
    
    setInterval(
      alert("No se puedo crear el socio de negocios"),
      60000
    );
  });*/
  }

  return (
    <div>
      {isLoading ? (
        <LoadingScreen
          className="LoadinScreen"
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc={Spinner}
          text="Creando socio de negocios..."
        />
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <Box>
              <h2>DATOS DEL CLIENTE NUEVO</h2>

              <ValidationTextField
                label="Codigo"
                placeholder="Codigo"
                sx={{ m: 2, width: "8%" }}
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                name="code"
                value={list.code}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <Checkbox {...label}  color="success" id='miElementoCheckbox' onChange={handleChangeecheckbox} /> */}
              <ValidationTextField
                label="Socio de Negocios "
                placeholder="Socio de Negocios"
                sx={{ m: 2, width: "40%" }}
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                name="business_partner"
                value={values.business_partner}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <ValidationTextField
                label="RFC"
                placeholder="RFC"
                sx={{ m: 2, width: "20%" }}
                variant="outlined"
                defaultValue="Success"
                id="getRFC"
                name="rfc"
                value={values.rfc}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box>
              <ValidationTextField
                placeholder="Regimen Capital"
                variant="outlined"
                label="Regimen Fiscal"
                sx={{ m: 2, width: "40%" }}
                defaultValue="Success"
                id="validation-outlined-input"
                style={{ display: tax_regimeinput }}
                onClick={handleCambioComponente}
                name="tax_regime"
                value={values.tax_regime}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="regimenfiscal"
                select
                label="Regimen Fiscal"
                value={opp9}
                sx={{ m: 1.5, width: "40%" }}
                onChange={handleChangee9}
                size="small"
                style={{ display: tax_regimeSelect }}
                name="regimenfiscal"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {regimen.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
              <ValidationTextField
                label="Regimen Capital"
                placeholder="Regimen Capital"
                variant="outlined"
                sx={{ m: 2, width: "20.5%" }}
                defaultValue="Success"
                id="validation-outlined-input"
                name="capital_regime"
                value={values.capital_regime==="null"?"":values.capital_regime}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <ValidationTextField
                label="Tipo de Persona"
                variant="outlined"
                placeholder="Calcular Iva"
                sx={{ m: 2, width: "8%" }}
                value={values?.rfc?.length > 12 ? "FISICA" : "MORAL"}
                InputLabelProps={{ shrink: true }}
              />
             
            </Box>

            <br></br>
            <Box>
              <h2>DATOS DEL CLIENTE, SOLO SI ES UNA PERSONA FISICA</h2>

              <ValidationTextField
                label="Nombre del Representante Legal "
                variant="outlined"
                placeholder="Nombre del Representante Legal"
                sx={{ m: 2, width: "22.66%" }}
                defaultValue="Success"
                id="validation-outlined-input"
                name="name"
                value={values.name==="null"?"":values.name}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
              />

              <ValidationTextField
                label="Apellido Paterno del Representante Legal "
                placeholder="Apellido Paterno del Representante Legal"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "23%" }}
                name="first_last_name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.first_last_name==="null"?"":values.first_last_name}
                onChange={handleChange}
              />
              <ValidationTextField
                label="Apellido Materno del Representante Legal"
                placeholder="Apellido Materno del Representante Legal"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "22.66%" }}
                name="second_last_name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.second_last_name==="null"?"":values.second_last_name}
                onChange={handleChange}
              />
            </Box>
            <br></br>

            <Box>
              <h2>DATOS NECESARIOS PARA FACTURAR</h2>

              <ValidationTextField
                label="Correo Electrónico"
                placeholder="Correo Electrónico"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "22.66%" }}
                name="email"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.email}
                onChange={handleChange}
              />

           


              <TextField
                id="CFDIUse"
                select
                label="Uso de CFDI"
                value={opp3}
                sx={{ m: 1.5, width: "22%" }}
                onChange={handleChangee3}
                size="small"
                name="CFDIUse"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {usoCFDI.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              
              <TextField
                id="payMethod"
                select
                label="Método de pago"
                value={opp4}
                sx={{ m: 1.5, width: "13.4%" }}
                onChange={handleChangee4}
                size="small"
                name="payMethod"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {medotoPagoJ.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>


              <TextField
                id="metodoPago"
                select
                label="Forma de pago"
                value={mPago}
                sx={{ m: 1.5, width: "10.5%" }}
                onChange={handleChangeepago}
                size="small"
                name="metodoPago"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {op5.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
            <br></br>

            <Box>
              <ValidationTextField
                label="Calle"
                placeholder="Calle"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "24.5%" }}
                name="street"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.street}
                onChange={handleChange}
              />

              <ValidationTextField
                label="Num.Ext."
                placeholder="Num.Ext."
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "5%" }}
                name="outdoor_number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.outdoor_number}
                onChange={handleChange}
              />
              <ValidationTextField
                label="Num.Int."
                placeholder="Num.Int."
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "5%" }}
                name="inside_number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.inside_number}
                onChange={handleChange}
              />

              <ValidationTextField
                label="Código Postal"
                required
                placeholder="Codigo Postal"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "7%" }}
                name="zip_code"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.zip_code}
                onChange={handleChange}
              />
              <ValidationTextField
                label="Municipio"
                placeholder="Municipio"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "24%" }}
                name="city"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.city}
                onChange={handleChange}
              />
            </Box>
            <br></br>

            <Box>
              <ValidationTextField
                label="Estado"
                placeholder="Estado"
                variant="outlined"
                defaultValue="Success"
                sx={{ m: 2, width: "26%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ display: stateInput }}
                value={values.state}
                onChange={handleChange}
                onClick={handleCambioComponente04}

              />

              <TextField
                select
                label="Estado"
                value={opp6}
                sx={{ m: 1.5, width: "28%" }}
                onChange={handleChangee6}
                size="small"
                style={{ display: stateSelec }}
                name="regimenfiscal"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {estado.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>


              <ValidationTextField
                label="Referencia"
                placeholder="Referencia"
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                sx={{ m: 2, width: "44.5%" }}
                name="reference"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.reference}
                onChange={handleChange}
              />
            </Box>
            <br></br>

            <Box>
              <h2>DATOS SOLO SI ES UN CLIENTE EXTRANJERO</h2>

              <ValidationTextField
                label="País"
                placeholder="País)"
                variant="outlined"
                sx={{ m: 2, width: "35.5%" }}
                defaultValue="Success"
                id="validation-outlined-input"
                name="tax_residence"
                value={values.tax_residence}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              

              <ValidationTextField
                label="Número de Registro de Identificación Fiscal "
                placeholder="Número de Registro de Identificación Fiscal "
                variant="outlined"
                sx={{ m: 2, width: "36%" }}
                defaultValue="Success"
                id="validation-outlined-input"
                name="num_reg_id_trib"
                value={values.num_reg_id_trib}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <br></br>
            <br></br>

            <Box>
             

              <Button
                variant="contained"
                type="submit"
                sx={{ m: 1.5, width: "30ch" }}
              >
                Crear socio de negocio
              </Button>
            </Box>
          </form>
        </div>
      )}
    </div>
  );
}

export default Set_bussines_pInvoice;
