import TableConfigureTaxes from '../../components_cfdi/invoice/componentsTaxes/Consult_Configure_Tax';
import { AuthState } from "@aws-amplify/ui-components";
import React, { Component } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";


export class SetConsulConfigureTaxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      minutes: 20,
      seconds: 0,
      x: 0,
      y: 0,
    };
  }
  

  render() {

    
   

      return (    
      
      <div className="tc" >
      <TableConfigureTaxes></TableConfigureTaxes>

      </div>
          );
       }     
}

export default SetConsulConfigureTaxes;
