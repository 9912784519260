import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const COLUMS_SHOW = (props) => {



    return (
        <div>
             <div
                    className="contanerFIltersTableSelect"
                    style={{ display: props.filtersColumsView === true ? "none" : "" }}
              >
                <br></br>
                <br></br>

                {
                  props?.datacolums?.map((item, index)=>(
                    <div className={`filter-${index+2} color-1`}>
                      <FormControlLabel
                        value="bottom"
                        control={
                          <Checkbox
                            checked={item.view}
                            onChange={(e) => {
                              item.setview(e.target.checked);
                            }}
                          />
                        }
                        label={item.label}
                        labelPlacement="bottom"
                      />
                    </div> 
                  )
                )
                }
            </div>
         </div>
    )
}
