import EXPENSES from "../consult_expenses";

const EXPENSES_NON_DEDUCTIBLE = () => {


  return (
    <>
    <EXPENSES foreing={0} expense={"NO DEDUCIBLES"} origin={1}></EXPENSES>
    </>
  );
};

export default EXPENSES_NON_DEDUCTIBLE;
