//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import LoadingScreen from "react-loading-screen";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal";
import { styled } from "@mui/material/styles";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";

//COMPONENTES
import JOIN_INVOICE from "../join_invoice/join_invoice";
import TableContainer from "./TableContainer";
import { SelectColumnFilter } from "../../filters";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token } from "../.../../../../main_components/tokens/tokenrefresh";
import { pagination } from "../../../main_components/pagination/pagination";
import { get_payment } from "../../../services/invoices/invoice";
import { get_series } from "../../../services/invoices/invoice";
import { generate_payment } from "../../../services/invoices/invoice";
import bank from "../../../dataComponets/bank.json";
import DELETE from "../../../imgComponents/borrar.png";
import op4 from "../../../dataComponets/metodo_pago.json"

//IMG
import Spinner from "../../../imgComponents/S.gif";

//CSS
import "./style.css";
import "../generate_invoice/factura.css";
import "./pago.css";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";


const op6 = [
  { value: "MXN", label: "PESO MÉXICANO" },
  { value: "USD", label: "DOLAR" },
  { value: "EU", label: "EURO" },
  { value: "YEN", label: "YEN" },
];

const rl0P = [
  { value: "", label: "Selecciona un tipo de relación" },
  { value: "04", label: "Sustitución de los CFDI previos" },
];

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

function Pago() {
  const [busnes, setBusnes] = useState([]);
  const [banco, setBanco] = useState([]);
  const [bancoB, setBancoB] = useState([]);
  const [bussines, setBussines] = useState([]);
  const [BorrarRFC, setBorrarRFC] = useState("");
  const [numSer, setNumSer] = useState();
  const [folioType, setFolioType] = useState("");
  const [selectMoneda, setSelectMoneda] = React.useState("MXN");
  const [opp5, setOP5] = React.useState("01");
  const [ser, setSer] = useState([]);
  const [uuid_rel, setuuid_rel] = useState(false);
  const [mostrarComponente3, setMostrarComponente3] = useState(false);
  const [isVisibility, setisVisibility] = useState(false);
  const [typeInputHoraPago, settypeInputHoraPago] = useState("text");
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");
  const [editTipoCambio, seteditTipoCambio] = React.useState(1);
  const [editTipo1, seteditTipo1] = React.useState("");
  const [editTipo3, seteditTipo3] = React.useState("");
  const [editTipo4, seteditTipo4] = React.useState("");
  const [editTipo6, seteditTipo6] = React.useState("");
  const [editTipo7, seteditTipo7] = React.useState("");
  const [editTipo8, seteditTipo8] = React.useState("");
  const [editTipo9, seteditTipo9] = React.useState("");
  const [editTipo10, seteditTipo10] = React.useState("");
  const [editMonto, seteditMonto] = React.useState("");
  const [editEmail, seteditEmail] = React.useState("");
  const [contadorSelect, setContadorSelect] = React.useState(0);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [mensajeErrorCuentOrdenante, setmensajeErrorCuentOrdenante] =
    useState("");
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const [restCantidad, setVRestCantidad] = React.useState([]);
  const [sumCantidadIMporte, setsumCantidadIMporte] = React.useState([]);
  const [cdp_env, setcdp_env] = useState([]);
  const [contadorpagos, setcontadorpagos] = useState(1);
  const [difT, setDifT] = React.useState([{ id: "elminarEsto" }]);
  const [refreshtable1, setRfreshtable] = React.useState([]);

  var date = new Date();
  var day = `${date.getDate()}`.padStart(2, "0");
  var month = `${date.getMonth() + 1}`.padStart(2, "0");
  var year = date.getFullYear();
  var hora = `${date.getHours()}`.padStart(2, "0");
  var minutos = `${date.getMinutes()}`.padStart(2, "0");
  const [fi2, setfi2] = useState(`${year}-${month}-${day}T${hora}:${minutos}`);

  const handle_get_payment = async () => {
    setIsLoading(true);
    try {
      const dt = await get_payment(fi, ff, busnes.rfc);
      setData(dt.data);
      setDifT([{ id: "elminarEsto" }]);
      setIsLoading(false);
    } catch (err) {
      console.log("error", err);
      setData([]);
      setIsLoading(false);
    }
  };

  const handleeditTipo1 = (event) => {
    seteditTipo1(event.target.value);
  };
  const handleeditTipo3 = (event) => {
    seteditTipo3(event.target.value);
  };
  const handleeditTipo4 = (event) => {
    seteditTipo4(event.target.value);
  };

  const handleChangeMoneda = (event) => {
    setSelectMoneda(event.target.value);
  };
  const handleChangee0 = (event) => {
    setNumSer(event.target.value);
  };

  function numType() {
    var indice2 = ser.findIndex((filio) => filio.serie === numSer);
    if (indice2 === -1) {
      try {
        setFolioType(ser[0].folio);
      } catch (err) {
        console.log(err);
      }
    } else {
      setFolioType(ser[indice2]?.folio);
    }
  }

  const handleChangee5 = (event) => {
    setOP5(event.target.value);
  };

  const handleOnSearch = (string, results) => {
    console.log(string, results);
    setSearchString(string);
  };

  const handleOnClear = () => {
    console.log("Cleared");
    setSearchString("");
  };

  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {
    setBusnes(item);
    sessionStorage.setItem(busnes.business_partner, "NameBusinespartner");
    setBorrarRFC("");
  };

  const handleOnSelectBanco = (item) => {
    setBanco(item);
  };
  const handleOnSelectBancoB = (item) => {
    setBancoB(item);
  };
  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handle_series = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_series("P");
            setSer(dt.data);
          } catch (err) {
            console.log("error", err);
            setSer([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_get_businesspartners = async () => {
    setIsLoading(true);
    setMensajeBusqueda("Ingresando...");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            var bp = await pagination(3, "bp", null);
            setBussines(bp);
            setDifT([{ id: "elminarEsto" }]);
            setIsLoading(false);
          } catch (err) {
            console.log("error", err);
            setIsLoading(false);
            setMensajeBusqueda("");
            setBussines({});
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const validCuentaOrdenate = (event) => {
    if (opp5 === "02") {
      if (editTipo4.length === 11) {
        if (/^[0-9]{11}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
        setmensajeErrorCuentOrdenante("");
      } else if (editTipo4.length === 18) {
        if (/^[0-9]{18}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
        setmensajeErrorCuentOrdenante("");
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta ordénente, con forma de pago 02 (Cheque nominativo), debe contener un patrón de números del [0-9] y una longitud de 11 dígitos o 18 dígitos."
        );
      }
    }

    if (opp5 === "03") {
      if (editTipo4.length === 10) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{10}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 16) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{16}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 18) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{18}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta ordénente, con forma de pago 03 (Transferencia electrónica de fondos), debe contener un patrón de números del [0-9] y una longitud de 10, 16 o 18 dígitos."
        );
      }
    }
    if (opp5 === "04") {
      if (editTipo4.length === 16) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{16}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {16} dígitos"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta ordénente, con forma de pago 04 (Tarjeta de crédito), debe contener un patrón de números del [0-9] y una longitud 16 dígitos."
        );
      }
    }

    if (opp5 === "05") {
      if (editTipo4.length === 10) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{10,11}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else if (/^[A-Z0-9_]{10,50}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 11) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{10,11}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 15) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{15,16}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 16) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{15,15}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 18) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{10,11}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta ordénente, con forma de pago 05 (Monedero electrónico), debe contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
        );
      }
    }

    if (opp5 === "06") {
      if (editTipo4.length === 10) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{10}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
        );
      }
    }

    if (opp5 === "28") {
      if (editTipo4.length === 16) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{16}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{16}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{16}"
        );
      }
    }

    if (opp5 === "29") {
      if (editTipo4.length === 15) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{15,16}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{15,16}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else if (editTipo4.length === 16) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{15,16}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{15,16}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{15,16}"
        );
      }
    }

    if (opp5 === "28") {
      if (editTipo4.length === 10) {
        setmensajeErrorCuentOrdenante("");
        if (/^[0-9]{10}$/g.test(editTipo4) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
          );
        } else {
          setmensajeErrorCuentOrdenante("");
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
        );
      }
    }
  };

  const validCuentaBeneficiario = (event) => {
    if (opp5 === "02") {
      if (editTipo6.length === 11) {
        if (/^[0-9]{11}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        }
      } else if (editTipo6.length === 18) {
        if (/^[0-9]{18}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta beneficiario debe contener un patrón de números del [0-9] y una longitud de 11 dígitos o 18 dígitos."
        );
      }
    }

    if (opp5 === "03") {
      if (editTipo6.length === 10) {
        if (/^[0-9]{10}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        }
      } else if (editTipo6.length === 16) {
        if (/^[0-9]{16}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        }
      } else if (editTipo6.length === 18) {
        if (/^[0-9]{18}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta beneficiario debe contener un patrón de números del [0-9] y una longitud de 10, 16 o 18 dígitos."
        );
      }
    }
    if (opp5 === "04") {
      if (editTipo6.length === 16) {
        if (/^[0-9]{16}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {16} dígitos"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta beneficiario debe contener un patrón de números del [0-9] y una longitud 16 dígitos."
        );
      }
    }

    if (opp5 === "05") {
      if (editTipo6.length === 10) {
        if (/^[0-9]{10,11}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else if (/^[A-Z0-9_]{10,50}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (editTipo6.length === 11) {
        if (/^[0-9]{10,11}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (editTipo6.length === 15) {
        if (/^[0-9]{15,16}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (editTipo6.length === 16) {
        if (/^[0-9]{15,15}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (editTipo6.length === 18) {
        if (/^[0-9]{10,11}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "La cuenta beneficiario debe contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
        );
      }
    }

    if (opp5 === "06") {
      if (editTipo6.length === 10) {
        console.log();
        if (/^[0-9]{10}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
        );
      }
    }

    if (opp5 === "28") {
      if (editTipo6.length === 16) {
        console.log();
        if (/^[0-9]{16}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{16}"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{16}"
        );
      }
    }

    if (opp5 === "29") {
      if (editTipo6.length === 15) {
        if (/^[0-9]{15,16}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{15,16}"
          );
        }
      } else if (editTipo6.length === 16) {
        if (/^[0-9]{15,16}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{15,16}"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{15,16}"
        );
      }
    }

    if (opp5 === "28") {
      if (editTipo6.length === 10) {
        console.log();
        if (/^[0-9]{10}$/g.test(editTipo6) === false) {
          setmensajeErrorCuentOrdenante(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
          );
        }
      } else {
        setmensajeErrorCuentOrdenante(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
        );
      }
    }
  };
  const handleSubmit2 = async () => {
    if (restCantidad === 0) {
      const result = difT.filter((d) => d.id !== "elminarEsto");
      var mostar1;
      if (mostrarComponente3 === false) {
        mostar1 = null;
      } else {
        mostar1 = "";
      }

      var dato = {
        pagos: [
          {
            fechapago: fi2 + ":00",

            formadepagop: opp5,

            monedap: selectMoneda,

            tipocambiop: editTipoCambio,

            monto: Number(editMonto),

            numoperacion: mostar1 || editTipo1 || null,

            rfcemisorctaord: mostar1 || banco.value || null,

            nombancoordext: mostar1 || banco?.name || null,

            ctaordenante: mostar1 || editTipo4 || null,

            rfcemisorctaben: mostar1 || bancoB.value || null,

            ctabeneficiario: mostar1 || editTipo6 || null,

            tipocadpago: mostar1 || editTipo7 || null,

            certpago: mostar1 || editTipo8 || null,

            cadpago: mostar1 || editTipo9 || null,

            sellopago: mostar1 || editTipo10 || null,

            doctorelacionado: result,
          },
        ],

        receptor: {
          id: busnes.id,

          nombre: busnes.business_partner,

          rfc: busnes.rfc,

          usocfdi: busnes.cfdi_use,

          residenciafiscal: busnes.tax_residence || null,

          numregidtrib: busnes.num_reg_id_trib || null,

          regimenfiscalreceptor: busnes.tax_regime,

          domiciliofiscalreceptor: busnes.zip_code,
        },

        cfdirelacionados:
          uuid_rel === undefined
            ? null
            : uuid_rel === false
            ? null
            : [
                {
                  tiporelacion: uuid_rel?.motivo,
                  uuid: uuid_rel?.uuid_sat,
                },
              ],
        email: editEmail,
      };

      setIsLoading(true);
      setMensajeBusqueda("Generando complemento de pago");

      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try {
              await generate_payment(dato);
              alert("Se genero correctamente el complemento de pago");
              setMensajeBusqueda("");
              setIsLoading(false);
              window.location = "/pago";
            } catch (err) {
              setMensajeBusqueda("");
              catchErrorModul(
                "Generar Pago",
                JSON.stringify(dato),
                JSON.stringify(
                  err?.response
                )
              );
              setIsLoading(false);
              alert("Erro:" + JSON.stringify(err?.response?.data));
              window.location = "/pago";
            }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }
    } else {
      alert("La diferencia debe ser 0, revise su monto de pago");
    }
  };

  // console.log(neweditPago);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const itemStyle = {
    padding: "2px",
    border: "1px solid silver",
    color: "Black",
    width: "70px",
    height: "20px",
  };

  const textStyle = {
    ...itemStyle,
    padding: "3px 2px",
  };

  const textStyle2 = {
    ...itemStyle,
    padding: "3px 2px",
  };

  const handleeditMonto = (event) => {
    seteditMonto(event.target.value);
  };

  const handleeditEmail = (event) => {
    seteditEmail(event.target.value);
  };

  console.log(difT);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const remove = (rowIndex) => {
    setData((old) => old.filter((_, index) => index !== rowIndex));
  };

  const handleRemoveItem = (e) => {
    const id = e.target.getAttribute("name");
    console.log(id);
    console.log(cdp_env);
    // eslint-disable-next-line eqeqeq
    setcdp_env(cdp_env.filter((item) => item.id_pay != id));
    console.log(cdp_env);
  };

  const handlesenddata = async () => {
    
    var nddd=cdp_env?.map((d)=>d.doctorelacionado);
    console.log(dataOriginal)
    var nddd2=dataOriginal.map((d)=>{
     
     var json={
      iddocumento: d.uuid_sat,
      amount_of_debt: d.amount_of_debt,
      userimppagado:0,
     }
     return json
    });


    console.log(nddd2);

    const arregloUnido = nddd.flatMap((arregloInterno) => arregloInterno);

    console.log(arregloUnido);

    var arrPay= nddd2.concat(arregloUnido);
    console.log(arrPay);

    var isValid=sumUserImpPagado(arrPay);

    if (isValid === true) {
      setIsLoading(true);
      setMensajeBusqueda("Generando complemento de pago");
      var dataPay = {
        pagos: cdp_env,
        receptor: {
          id: busnes.id,
          nombre: busnes.business_partner,
          rfc: busnes.rfc,
          usocfdi: busnes.cfdi_use,
          residenciafiscal: busnes.tax_residence || null,
          numregidtrib: busnes.num_reg_id_trib || null,
          regimenfiscalreceptor: busnes.tax_regime,
          domiciliofiscalreceptor: busnes.zip_code,
        },

        cfdirelacionados:
          uuid_rel === undefined
            ? null
            : uuid_rel === false
            ? null
            : [
                {
                  tiporelacion: uuid_rel?.motivo,
                  uuid: uuid_rel?.uuid_sat,
                },
              ],
        email: editEmail,
      };
      console.log(dataPay);

      try{
        var rf_token= await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try {
              await generate_payment(dataPay);
              alert("Se genero correctamente el complemento de pago");
              setMensajeBusqueda("");
              setIsLoading(false);
              window.location = "/pago";
            } catch (err) {
              setMensajeBusqueda("");
              catchErrorModul(
                "Generar Pago",
                JSON.stringify(dataPay),
                JSON.stringify(
                   err?.response
                )
              );
              setIsLoading(false);
              alert("Erro:" + JSON.stringify(err?.response?.data));
              window.location = "/pago";
            }
          }, 1000);
        }else{}
      }catch(err){
        console.log(err);
      }      
    } else {
      console.log("no pasa la consulta");
    }
  };
  function sumUserImpPagado(obj) {
    const idDocSum = {};
    const idDocSumdebit = {};
    let isValid = true; 

    for (let i = 0; i < obj.length; i++) {
      const iddoc = obj[i].iddocumento;
      if (idDocSum[iddoc] === undefined) {
        idDocSum[iddoc] = obj[i]?.userimppagado===undefined?0:obj[i]?.userimppagado;
      } else {
        idDocSum[iddoc] += obj[i].userimppagado===undefined?0:obj[i].userimppagado;

      }
    }

    for (let i = 0; i < obj.length; i++) {
      const iddoc = obj[i].iddocumento;
      console.log(iddoc);
      if (idDocSumdebit[iddoc] === undefined) {
        idDocSumdebit[iddoc] = obj[i]?.amount_of_debt===undefined?0:obj[i]?.amount_of_debt;
      } else {
        idDocSumdebit[iddoc] += obj[i]?.amount_of_debt===undefined?0:obj[i]?.amount_of_debt;
      }
    }


    
    console.log(idDocSumdebit);
    console.log(idDocSum);
    for (const iddoc in idDocSum) {
      console.log(idDocSumdebit);
      console.log(idDocSum[iddoc] );

      if (idDocSumdebit !== undefined && idDocSumdebit[iddoc] >= idDocSum[iddoc]  ) {
        console.log(idDocSumdebit[iddoc]+">="+idDocSum[iddoc])
      }
      else{
        console.log("no pasa");
        alert(`La suma de los pagos para iddocumento ${iddoc}: ${idDocSum[iddoc]} es mayor que la deuda (${idDocSumdebit[iddoc]})`);
        isValid=false;

      }
    }
    return isValid; // Al final, se devuelve el resultado de las validaciones

  }

  const handleSaveData = (event) => {
    setisVisibility(true);
    setContadorSelect(0);
    if (restCantidad === 0) {
      const result = difT.filter((d) => d.id !== "elminarEsto");
      var mostar1;
      if (mostrarComponente3 === false) {
        mostar1 = null;
      } else {
        mostar1 = "";
      }

      var dta = {
        fechapago: fi2 + ":00",
        formadepagop: opp5,
        monedap: selectMoneda,
        tipocambiop: editTipoCambio,
        monto: Number(editMonto),
        numoperacion: mostar1 || editTipo1 || null,
        rfcemisorctaord: mostar1 || banco.value || null,
        nombancoordext: mostar1 || banco?.name || null,
        ctaordenante: mostar1 || editTipo4 || null,
        rfcemisorctaben: mostar1 || bancoB.value || null,
        ctabeneficiario: mostar1 || editTipo6 || null,
        tipocadpago: mostar1 || editTipo7 || null,
        certpago: mostar1 || editTipo8 || null,
        cadpago: mostar1 || editTipo9 || null,
        sellopago: mostar1 || editTipo10 || null,
        doctorelacionado: result,
      };

      setcdp_env([...cdp_env, { id_pay: contadorpagos, ...dta }]);

      setcontadorpagos(contadorpagos + 1);
      seteditMonto(0);
      setVRestCantidad(0);
    } else {
      alert("La diferencia debe ser 0, revise sus montos de pago");
    }
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  useEffect(() => {
    handle_get_businesspartners();
    handle_series();
  }, []);

  useEffect(() => {
    setData([]);
    setDifT([]);
    setcdp_env(cdp_env);
  }, [cdp_env]);

  console.log(difT);

  const columns = useMemo(
    () => [
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        Filter: SelectColumnFilter,
        filter: "equals",
        id: "edipa",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Folio",
        accessor: "folio",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Fecha",
        accessor: "invoice_date",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        Header: "Cliente",
        accessor: "name_business_partner",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Importe",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Deudas",
        accessor: (d) => {
          return d.amount_of_debt
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_of_debt)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_of_debt);
        },
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Moneda",
        accessor: "currency",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Parcialidad",
        accessor: "installment",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        id: "selection2",

        Header: ({ getToggleAllRowsSelectedProps }) => {
          function refreshtable() {
            setRfreshtable(!refreshtable1);
          }

          return (
            <div className="TCDPT">
              <table className="TCDPT ">
                <td className="td1">
                  <p className="pagoColor">Pago</p>
                </td>
                <td className="td1">
                  <p className="pagoColor">Diferencia</p>
                </td>
                <td className="td1">
                  <p className="pagoColor">Equivalencia</p>
                </td>
                <td>
                  <div>
                    <input
                      type="checkbox"
                      {...getToggleAllRowsSelectedProps()}
                      onClick={() => refreshtable(refreshtable1)}
                    />
                  </div>
                </td>
              </table>
            </div>
          );
        },

        Cell: ({ row, value, onChange }) => {
          const [mode, setMode] = React.useState("read");
          const [text, setText] = React.useState(value ?? "");
          const [textdr, setTextdr] = React.useState(value ?? "");
          const [nImporte, setnImporte] = useState(0);
          var rowDate = row.getToggleRowSelectedProps();
          const inputElement =
            document?.getElementById("input-element-id")?.value;
          const inputElement2 = document?.getElementById(
            "input-element-id-cambioT"
          )?.value;

          function folio2() {
            if (rowDate.checked === false) {
              setText(row.original.amount_of_debt);
              setTextdr(1);
              if (difT[row.index]?.length !== 0) {
                difT[row.index] = {
                  id: row.index,
                  iddocumento: row.original.uuid_sat,
                  serie: row.original.serie,
                  folio: row.original.folio,
                  monedadr: row.original.currency,
                  equivalenciadr: 1,
                  numparcialidad:
                    row.original.installment === 0
                      ? 1
                      : row.original.installment + 1,
                  impsaldoant: row.original.amount_of_debt,
                  imppagado: row.original.amount_of_debt,
                  impsaldoinsoluto: 0,
                };
              } else {
                setDifT([
                  ...difT,
                  {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: 1,
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: row.original.amount_of_debt,
                    impsaldoinsoluto: 0,
                  },
                ]);
              }
            } else {
              setText("");
              setTextdr("");

              difT[row.index] = {
                id: "elminarEsto",
              };
              setDifT(difT);
            }
          }

          function folio() {
            if (row.isSelected === true) {
              setText(row.original.amount_of_debt);
              setTextdr(1);

              if (difT[row.index]?.length !== 0) {
                difT[row.index] = {
                  id: row.index,
                  iddocumento: row.original.uuid_sat,
                  serie: row.original.serie,
                  folio: row.original.folio,
                  monedadr: row.original.currency,
                  equivalenciadr: 1,
                  numparcialidad:
                    row.original.installment === 0
                      ? 1
                      : row.original.installment + 1,
                  impsaldoant: row.original.amount_of_debt,
                  imppagado: row.original.amount_of_debt,
                  impsaldoinsoluto: 0,
                };
              } else {
                setDifT([
                  ...difT,
                  {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: 1,
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: row.original.amount_of_debt,
                    impsaldoinsoluto: 0,
                  },
                ]);
              }
            } else {
              setText("");
              setTextdr("");
              difT[row.index] = {
                id: "elminarEsto",
              };
              setDifT(difT);
            }
          }

          useEffect(() => {
            const handlerestar = () => {
              setDifT(difT);
            };
            handlerestar();
          });
          useEffect(() => {
            folio();
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [refreshtable1]);
          useEffect(() => {
            const handlerestar = () => {
              if (Number(inputElement2) === 1) {
                const sumar = difT
                  ?.map((saldo) =>
                    parseFloat(
                      isNaN(saldo?.imppagado) === true ? 0 : saldo.imppagado
                    )
                  )
                  .reduce((previous, current) => {
                    return previous + current;
                  }, 0);
                setVRestCantidad(Number(inputElement) - sumar.toFixed(2));
              } else {
                const montoP = Number(inputElement);

                setVRestCantidad(montoP - nImporte);
              }
            };
            handlerestar();
          });

          useEffect(() => {
            const handlerestar = () => {
              const sumar = difT
                ?.map((saldo) =>
                  parseFloat(
                    isNaN(saldo?.imppagado) === true ? 0 : saldo.imppagado
                  )
                )
                .reduce((previous, current) => {
                  return previous + current;
                }, 0);
              setsumCantidadIMporte(sumar.toFixed(2));
              setnImporte(sumar.toFixed(2));
            };
            handlerestar();
          });
          useEffect(() => {
            const handlerestar = () => {
              const resultFilter = difT?.filter(
                (d) => d.id !== "elminarEsto"
              );
              setContadorSelect(resultFilter.length);
            };
            handlerestar();
          });
          useEffect(() => {
            const resultFilter = difT?.filter(
              (d) => d.id !== "elminarEsto"
            );
            setDifT(resultFilter);
          }, [difT]);

          if (mode === "edit") {
            const handleInputChange = (e) => {
              setText(e.target.value);
            };
            const handleInputChange2 = (e) => {
              setText(" " + row.original.amount_of_debt);
            };

            const handleInputChangedr = (e) => {
              setTextdr(e.target.value);
            };
            const handleInputChange2dr = (e) => {
              setTextdr(" " + 1);
            };

            const handleSaveClick = () => {
              setMode("read");

              setDifT(
                Number(text) < row.original.amount_of_debt
                  ? Number(row.original.amount_of_debt - text).toFixed(2)
                  : 0
              );

              if (Number(text) > row.original.amount_of_debt) {
                setText(0);
                if (difT[row.index]?.length !== 0) {
                  difT[row.index] = {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: 1,
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: Number(0),
                    impsaldoinsoluto: Number(
                      Number(row.original.amount_of_debt - 0).toFixed(2)
                    ),
                  };
                } else {
                  setDifT([
                    ...difT,
                    {
                      id: row.index,
                      iddocumento: row.original.uuid_sat,
                      serie: row.original.serie,
                      folio: row.original.folio,
                      monedadr: row.original.currency,
                      equivalenciadr: 1,
                      numparcialidad:
                        row.original.installment === 0
                          ? 1
                          : row.original.installment + 1,
                      impsaldoant: row.original.amount_of_debt,
                      imppagado: Number(0),
                      impsaldoinsoluto: Number(
                        Number(row.original.amount_of_debt - 0).toFixed(2)
                      ),
                    },
                  ]);
                }
              } else {
                if (difT[row.index]?.length !== 0) {
                  difT[row.index] = {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: Number(textdr),
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: Number(text) * Number(textdr),
                    impsaldoinsoluto: Number(
                      Number(row.original.amount_of_debt - text).toFixed(2)
                    ),
                  };
                } else {
                  setDifT([
                    ...difT,
                    {
                      id: row.index,
                      iddocumento: row.original.uuid_sat,
                      serie: row.original.serie,
                      folio: row.original.folio,
                      monedadr: row.original.currency,
                      equivalenciadr: Number(textdr),
                      numparcialidad:
                        row.original.installment === 0
                          ? 1
                          : row.original.installment + 1,
                      impsaldoant: row.original.amount_of_debt,
                      imppagado: Number(text) * Number(textdr),
                      impsaldoinsoluto: Number(
                        Number(row.original.amount_of_debt - text).toFixed(2)
                      ),
                    },
                  ]);
                }

                if (onChange) {
                  onChange(text);
                }
              }
            };
            return (
              <>
                <div className="contenedorPagosSelect">
                  <div className="campoPago1" style={itemStyle}>
                    <input
                      type="number"
                      value={text || row.original.amount_of_debt}
                      style={textStyle}
                      onChange={handleInputChange}
                      onClick={handleInputChange2}
                      onBlur={handleSaveClick}
                    />
                  </div>

                  <div className="campoPago2" style={itemStyle}>
                    <input
                      type="number"
                      value={textdr || 1}
                      style={textStyle}
                      onChange={handleInputChangedr}
                      onClick={handleInputChange2dr}
                      onBlur={handleSaveClick}
                    />
                  </div>
                </div>
              </>
            );
            // );(Number(text-row.original.total))
          }
          if (mode === "read") {
            const handleEditClick = () => {
              if (row.isSelected === true) {
                setMode("edit");
              }
            };
            return (
              <div >
                <table>
                  <tr>
                    <td>
                      <div
                        
                        style={textStyle}
                        onClick={handleEditClick}
                      >
                        {text}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <h10>
                        {isNaN(Number(text)) === true
                          ? "0.00"
                          : Number(text) <= row.original.amount_of_debt
                          ? Number(row.original.amount_of_debt - text).toFixed(
                              2
                            ) === row.original.amount_of_debt
                            ? "0.00"
                            : Number(
                                row.original.amount_of_debt - text
                              ).toFixed(2)
                          : "0.00"}
                      </h10>
                    </td>
                    <td>
                      <div
                        
                        style={textStyle2}
                        onClick={handleEditClick}
                      >
                        {textdr}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <IndeterminateCheckbox
                        className="NoInput"
                        onChange={folio2}
                        onClick={folio2}
                        {...row.getToggleRowSelectedProps()}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            );
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshtable1]
  );

  return (
    <div>

    <BREADCRUMBS niveles={
        [
          {label: "REALIZAR COMPLEMENTO DE PAGO", path: null},
        ]
        }
      ></BREADCRUMBS>


      <div className="containerpayment">
     
        <div className="fact-20 rPago">
          <Button
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            onClick={async () => {
              const result = await CustomDialog(
                <JOIN_INVOICE relacionar={true} type={rl0P} rfc={busnes.rfc}></JOIN_INVOICE>,
                {
                  className: "modalCancelR",
                  title: "Relacionar Factura",
                  showCloseIcon: true,
                }
              );
              setuuid_rel(result);
            }}
          >
            {uuid_rel === undefined
              ? "Relacionar"
              : uuid_rel === false
              ? "Relacionar"
              : "UUID_SAT:" +
                uuid_rel?.uuid_sat +
                " Motivo:" +
                uuid_rel?.motivo}
          </Button>
        </div>
      </div>

      <div className="ContenerConponents">
        <div >
          <div
            style={{
              display: sessionStorage.getItem(
                "ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO"
              ),
            }}
          >
            <div className="containerPagoResponse">
              <div className="pag-1 color-1">
                <ValidationTextField
                  label="Tipo de comprobante"
                  variant="outlined"
                  placeholder="Folio"
                  sx={{ m: 2, width: "100%" }}
                  value={"COMPLEMENTO DE PAGO"}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="pag-2 rseriep">
                <TextField
                  id="Serie"
                  select
                  label="Tipo de serie"
                  value={numSer}
                  sx={{ m: 2, width: "100%" }}
                  onChange={handleChangee0}
                  size="small"
                  name="CFDIUse"
                  onClick={numType}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {ser.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.serie}
                    </option>
                  ))}
                </TextField>
              </div>

              <div className="pag-3 color-3">
                <ValidationTextField
                  label="Folio"
                  variant="outlined"
                  placeholder="Folio"
                  sx={{ m: 2, width: "100%" }}
                  value={folioType}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>

          <div className="containerPagoResponse-1">
            <div className="pag-4 color-1">
              <div className="contBusnesShear">
                <ReactSearchAutocomplete
                  items={bussines}
                  fuseOptions={{ keys: ["business_partner"] }}
                  resultStringKeyName="business_partner"
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  onClear={handleOnClear}
                  inputSearchString={searchString}
                  showIcon={true}
                  placeholder="Buscar socio de negocios..."
                  styling={{
                    height: "34px",
                    border: "1px solid darkgreen",
                    borderRadius: "4px",
                    backgroundColor: "white",
                    boxShadow: "none",
                    hoverBackgroundColor: "lightgreen",
                    color: "black",
                    iconColor: "black",
                    lineColor: "black",
                    placeholderColor: "black",
                    clearIconMargin: "3px 8px 0 0",
                    zIndex: 10,
                  }}
                />
              </div>
            </div>

            <div className="pag-5 color-2">
              <ValidationTextField
                label="RFC"
                variant="outlined"
                placeholder="RFC"
                sx={{ m: 2, width: "100%" }}
                value={BorrarRFC || busnes?.rfc}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="containerPagoResponse-2">
            <div className="pag-6 color-1">
              <TextField
                select
                label="Forma de pago"
                value={opp5}
                sx={{ m: 1.5, width: "100%" }}
                onChange={handleChangee5}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {op4.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="pag-7 color-2">
              <TextField
                select
                label="Tipo de moneda"
                value={selectMoneda}
                sx={{ m: 1.5, width: "100%" }}
                onChange={handleChangeMoneda}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {op6.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="pag-8 color-1">
              <ValidationTextField
                label="Tipo decambio"
                variant="outlined"
                id="input-element-id-cambioT"
                placeholder="Tipo de cambio"
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => seteditTipoCambio(event.target.value)}
                value={editTipoCambio}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-9 color-2">
              <input
                type={typeInputHoraPago}
                className="fiPago"
                onFocus={(e) => {
                  settypeInputHoraPago("datetime-local");
                }}
                placeholder={fi2}
                onChange={(event) => setfi2(event.target.value)}
              ></input>
            </div>
          </div>

          <div className="containerPagoResponse-3">
            <div className="pag-10 color-1">
              <ValidationTextField
                label="Campos seleccionados"
                variant="outlined"
                placeholder="Campos seleccionados"
                sx={{ m: 2, width: "100%" }}
                onChange={handleeditMonto}
                value={contadorSelect}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-11 color-2">
              <ValidationTextField
                label="Monto"
                variant="outlined"
                id="input-element-id"
                placeholder="Monto"
                sx={{ m: 2, width: "100%" }}
                onChange={(event) => seteditMonto(event.target.value)}
                value={editMonto}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-12 color-1">
              <ValidationTextField
                label="Importe"
                variant="outlined"
                placeholder="Importe"
                id="input-restcantidad"
                sx={{ m: 2, width: "100%" }}
                value={difT?.length === 0 ? 0 : sumCantidadIMporte}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-13 color-2">
              <ValidationTextField
                label="Diferencia"
                variant="outlined"
                id="input-restcantidad"
                placeholder="Diferencia"
                sx={{ m: 2, width: "100%" }}
                value={restCantidad}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="containerPagoResponse-4">
            <div className="pag-14 color-1">
              <ValidationTextField
                label="Correo electrónico"
                variant="outlined"
                placeholder="exmple@example.com"
                sx={{ m: 1.5, width: "100%" }}
                onChange={handleeditEmail}
                value={editEmail}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="containerPagoResponse-5">
            <div className="pag-15 color-1">
              <Button
                onClick={() => setMostrarComponente3(!mostrarComponente3)}
                sx={{ m: 2, width: "100%" }}
                style={{ background: "orange" }}
                variant="contained"
              >
                {mostrarComponente3
                  ? `Datos extra del complemento de pago`
                  : `Datos extra del complemento de pago`}
              </Button>
            </div>

            <div className="pag-16 color-1">
               <Button
                style={{
                  display: isVisibility === true ? "none" : "",
                  background: "green",
                }}
                onClick={handleSubmit2}
                sx={{ m: 2, width: "100%" }}
                variant="contained"
              >
                Generar Recibo
              </Button> 
              <Button
                onClick={handlesenddata}
                style={{
                  display: isVisibility === false ? "none" : "",
                  background: "green",
                }}
                sx={{ m: 2, width: "100%" }}
                variant="contained"
              >
                Generar Recibos
              </Button>
            </div>
            <div className="pag-17 color-1">
              <Button
                onClick={() => handleSaveData()}
                sx={{ m: 2, width: "100%" }}
                variant="contained"
              >
                Guardar pago
              </Button>
            </div>
          </div>

          <div className={mostrarComponente3 ? "show-element" : null}>
            {mostrarComponente3 && (
              <div>
                <div className="containerPagoResponse-6">
                  <div className="pag-17 color-1">
                    <ValidationTextField
                      label="Numero de operación"
                      variant="outlined"
                      placeholder="Numero de operación"
                      sx={{ m: 2, width: "100%" }}
                      onChange={handleeditTipo1}
                      value={editTipo1}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="containerPagoResponse-7">
                  <div className="pag-18 color-1">
                    <ReactSearchAutocomplete
                      items={bank}
                      fuseOptions={{ keys: ["label"] }}
                      resultStringKeyName="label"
                      onSelect={handleOnSelectBanco}
                      maxResults={4}
                      showIcon={true}
                      placeholder="Buscar banco emisor..."
                      styling={{
                        height: "34px",
                        border: "1px solid darkgreen",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        boxShadow: "none",
                        hoverBackgroundColor: "lightgreen",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        clearIconMargin: "3px 8px 0 0",
                        zIndex: 9,
                      }}
                    />
                  </div>
                  <div className="pag-19 color-1">
                    <ValidationTextField
                      label="RFC de la entidad emisora"
                      variant="outlined"
                      placeholder="RFC"
                      sx={{ m: 2, width: "100%" }}
                      value={banco.value}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className="pag-20 color-1">
                    <ValidationTextField
                      label="Nombre del banco extranjero"
                      variant="outlined"
                      placeholder="Nombre del banco extranjero"
                      sx={{ m: 2, width: "100%" }}
                      onChange={handleeditTipo3}
                      value={editTipo3 || banco.name}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="containerPagoResponse-8">
                  <div className="pag-21 color-1">
                    <ReactSearchAutocomplete
                      items={bank}
                      fuseOptions={{ keys: ["label"] }}
                      resultStringKeyName="label"
                      onSelect={handleOnSelectBancoB}
                      maxResults={4}
                      showIcon={true}
                      placeholder="Buscar banco beneficiado..."
                      styling={{
                        height: "34px",
                        border: "1px solid darkgreen",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        boxShadow: "none",
                        hoverBackgroundColor: "lightgreen",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        clearIconMargin: "3px 8px 0 0",
                        zIndex: 8,
                      }}
                    />
                  </div>

                  <div className="pag-22 color-1">
                    <ValidationTextField
                      label="RFC de la entidad emisora"
                      variant="outlined"
                      placeholder="RFC"
                      sx={{ m: 2, width: "100%" }}
                      value={bancoB.value}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="containerPagoResponse-9">
                  <div className="pag-23 color-1">
                    <ValidationTextField
                      label="Cuenta ordenante"
                      variant="outlined"
                      placeholder="Cuenta ordenante"
                      sx={{ m: 1.5, width: "100%" }}
                      onChange={handleeditTipo4}
                      onBlur={validCuentaOrdenate}
                      value={editTipo4}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>

                  <div className="pag-24 color-1">
                    <ValidationTextField
                      label="Cuenta beneficiario"
                      variant="outlined"
                      placeholder="Cuenta beneficiario"
                      sx={{ m: 1.5, width: "100%" }}
                      onBlur={validCuentaBeneficiario}
                      value={editTipo6}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <h2 className="mensajeErroCuOrd">
                  {mensajeErrorCuentOrdenante}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="contenedorConcept"
        style={{ display: isVisibility === false ? "none" : "" }}
      >
        <table>
          <thead>
            <tr className="cabezeracolor">
              <th className="unidadConcept">#</th>
              <th className="unidadConcept">Fecha</th>
              <th className="descripcionConcept">Forma de pago</th>
              <th className="cantidadConcept">Moneda</th>
              <th className="precioConcept">Monto</th>
              <th className="accionesConcept">*</th>
            </tr>
          </thead>
          <tbody>
            {cdp_env.map((row, index) => (
              <>
                <tr>
                  <td className="tdunidad">{index}</td>
                  <td className="tdunidad">{row.fechapago}</td>
                  <td className="tddescripcion">{row.formadepagop}</td>
                  <td>{row.monedap}</td>
                  <td className="tdimpuestos">{row.monto}</td>
                  <td className="borrarcolum">
                    <img
                      alt=""
                      src={DELETE}
                      name={row.id_pay}
                      onClick={handleRemoveItem}
                      className="borrarcl"
                    ></img>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table_payment">
        {isLoading ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={MensajeBusqueda || "Buscando..."}
          />
        ) : (
          <div>
            <StickyTable>
              <div className="div2cM01">
                <input
                  type="text"
                  className="fi"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>

                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>

                <button
                  className="button"
                  onClick={handle_get_payment}
                  type="button"
                >
                  Buscar
                </button>
              </div>
              {/* <button onClick={resetData}>Reset Data</button> */}

              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                remove={remove}
              />
            </StickyTable>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pago;
